/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-lone-blocks */
/* eslint-disable no-unreachable */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import {
  asyncGetAllProperties,
  asyncGetIndividualProperty,
  asyncGetIntermediaryProperty,
  asyncGetProperties,
  asyncGetUserProperty,
} from "../../store/user/user-async";
import { CheckBox, Dropdown, Loader, Table } from "../../components";
import styles from "./property.module.scss";
// import customerPropertiesData from "../../mocks/prop.properties.json";
// import allPropertiesData from "../../mocks/props.all_properties.json";
// import propIntermediaryData from "../../mocks/prop.prop_intermediary.json";

import Chart from "react-apexcharts";
import regression from "regression";
import { ApexOptions } from "apexcharts";
import AppDropdown from "../../components/app-dropdown";

const initOptions: ApexCharts.ApexOptions = {
  chart: {
    height: 800,
    type: "line",
  },
  fill: {
    type: "solid",
  },
  markers: {
    size: [6, 0],
    discrete: [],
  },
  tooltip: {
    shared: false,
    intersect: true,
  },
  legend: {
    show: true,
    fontSize: "18px",
  },
  xaxis: {
    type: "numeric",
  },
  colors: [
    // this array contains different color code for each data
    "#0A0",
    "#546E7A",
    "#d4526e",
    "#13d8aa",
    "#A5978B",
    "#2b908f",
    "#f9a3a4",
    "#90ee7e",
    "#f48024",
    "#69d2e7",
    "#FFA07A",
    "#E9967A",
    "#FA8072",
    "#CD5C5C",
    "#800080",
    "#CD5C5C",
    "#E9967A",
    "#DC143C",
    "#FF6347",
    "#FFD700",
    "#FF8C00",
  ],
};

const initSeries = [
  {
    name: "Points",
    type: "scatter",
    data: [
      {
        x: 1,
        y: 6.14,
      },
      {
        x: 1.2,
        y: 2.19,
      },
      {
        x: 1.8,
        y: 2.43,
      },
      {
        x: 2.3,
        y: 3.8,
      },
      {
        x: 2.6,
        y: 4.14,
      },
      {
        x: 2.9,
        y: 5.4,
      },
      {
        x: 3.2,
        y: 5.8,
      },
      {
        x: 3.8,
        y: 6.04,
      },
      {
        x: 4.55,
        y: 6.77,
      },
      {
        x: 4.9,
        y: 8.1,
      },
      {
        x: 5.1,
        y: 9.4,
      },
      {
        x: 7.1,
        y: 7.14,
      },
      {
        x: 9.18,
        y: 8.4,
      },
    ],
  },
  {
    name: "Regression Line",
    type: "line",

    color: "#33b2df",
    data: [
      {
        x: 1,
        y: 2,
      },
      {
        x: 2,
        y: 3,
      },
      {
        x: 3,
        y: 4,
      },
      {
        x: 4,
        y: 5,
      },
      {
        x: 5,
        y: 6,
      },
      {
        x: 6,
        y: 7,
      },
      {
        x: 7,
        y: 8,
      },
      {
        x: 8,
        y: 9,
      },
      {
        x: 9,
        y: 10,
      },
      {
        x: 10,
        y: 11,
      },
    ],
  },
];
const Property = () => {
  const { activeTab } = useAppSelector((s) => s.property);
  const [options, setOptions] = useState<ApexOptions>(initOptions);
  const [series, setSeries] = useState<any[]>(initSeries);
  const [showCheckBox, seShowCheckBox] = useState(true);
  const [competitorProperties, setCompetitorProperties] = useState<any[]>([]);
  const [selectedCustomerPropertyName, setSelectedCustomerPropertyName] =
    useState<string>("");
  const [selectedCustomerPropertyId, setSelectedCustomerPropertyId] =
    useState<string>("");

  const data1: Array<{ property_id: string; property_name: string }> = [
    { property_id: "1", property_name: "Community" },
    { property_id: "2", property_name: "Community2" },
    { property_id: "3", property_name: "Community3" },
  ];
  const data2: Array<{ property_id: string; property_name: string }> = [
    { property_id: "1", property_name: "Bedrooms" },
    { property_id: "2", property_name: "Bedrooms" },
    { property_id: "3", property_name: "Bedrooms3" },
  ];
  const handleChange = () => {
    return "";
  };
  const dispatch = useAppDispatch();
  const {
    token,
    customerPropertiesData,
    allPropertiesData,
    propIntermediaryData,
    userProperty,
  } = useAppSelector((state) => state.user);

  const [isLoading, setIsLoading] = useState(true);
  const [loading, setLoading] = useState(true);
  const [checkBoxes, setCheckBoxes] = useState<any>([]);
  const [isEmpty, setIsEmpty] = useState(false);
  function getSelectedCustomerPropertyObj(
    selectedCustomerName: string,
    customerPropertiesData: any
  ) {
    return customerPropertiesData.find(
      (selectedCustomerPropertyObj: any) =>
        selectedCustomerPropertyObj.property_name === selectedCustomerName
    );
  }

  function getAllUnitsForProperty(
    propertyId: string
    // propIntermediaryData: any
    // allPropertiesData: any
  ) {
    const propertyIntermediaryRelations = propIntermediaryData.filter(
      (intermediaryRelationObj: any) =>
        intermediaryRelationObj.client_property_id === propertyId
    );

    const propertyUnitsObjects = propertyIntermediaryRelations.map(
      (intermediaryRelationObj: any) =>
        allPropertiesData.find(
          (propFromAllProperties: any) =>
            propFromAllProperties.final_property_id ===
            intermediaryRelationObj.final_property_id
        )
    );

    return propertyUnitsObjects;
  }

  function getAllCompetitorsForPropertyUnits(
    finalMarketSurveyData: Array<any>,
    propertyUnits: Array<any>
  ) {
    return propertyUnits.map((propertyUnit: any) =>
      finalMarketSurveyData.filter(
        (finalMarketSurveyObj: any) =>
          finalMarketSurveyObj.property_id === propertyUnit.final_property_id &&
          finalMarketSurveyObj.current_data === true
      )
    );
  }

  function generateScatarPointsForUnitsFromSingleEntity(
    unitsOfOneEntity: Array<any>
  ) {
    return unitsOfOneEntity.map((oneUnit: any) => ({
      x: oneUnit.square_feet,
      y: oneUnit.market_rent,
    }));
  }

  function getAllCompetitorsForPropertyPoints(
    competitorPropertiesForCustomerProperty: Array<any>
  ) {
    const allCompetitorPropertiesPointsData: any[] = [];
    competitorPropertiesForCustomerProperty.forEach(
      (oneCompetitor: Array<any>) => {
        const pointsDataForOneCompetitor =
          generateScatarPointsForUnitsFromSingleEntity(oneCompetitor);

        allCompetitorPropertiesPointsData.push(...pointsDataForOneCompetitor);
      }
    );

    return allCompetitorPropertiesPointsData.sort(
      (val: any, val2: any) => val.x - val2.x
    );
  }
  function generateScatarPointsForCompetitorProperties(
    competitorPropertiesForCustomerProperty: Array<any>
  ) {
    return competitorPropertiesForCustomerProperty.map(
      (oneCompetitorUnits: Array<any>) => {
        const pointsDataForOneCompetitor =
          generateScatarPointsForUnitsFromSingleEntity(oneCompetitorUnits);

        const competitorName = oneCompetitorUnits[0]?.property_name ?? "Points";
        return {
          name: competitorName,
          type: "scatter",
          data: pointsDataForOneCompetitor.sort(
            (val: any, val2: any) => val.x - val2.x
          ),
        };
      }
    );
  }

  function bestFitLine(data: [number, number][]) {
    const result = regression.linear(data);
    const gradient = result.equation[0];
    const yIntercept = result.equation[1];
    return (x: number) => gradient * x + yIntercept;
  }

  function calculateRegressionLine(
    data: { x: number; y: number }[],
    x: number
  ) {
    let lineData: [number, number][] = data.map((val) => [val.x, val.y]);

    const bestFitLineFunction = bestFitLine(lineData);
    const y = bestFitLineFunction(x);
    return y;
  }

  function applyRegressionLine(allScatterData: any, lineData: any) {
    // the min x
    let minX = allScatterData[0].x;
    let minY = calculateRegressionLine(allScatterData, minX);
    // the max x
    let maxX = allScatterData[allScatterData.length - 1].x;
    let maxY = calculateRegressionLine(allScatterData, maxX);
    // calculate line with min and max
    let lineSeriesData = [
      {
        x: Math.round(minX * 100) / 100,
        y: Math.round(minY * 100) / 100,
      },
      {
        x: Math.round(maxX * 100) / 100,
        y: Math.round(maxY * 100) / 100,
      },
    ];

    let lineSeries = Object.assign({}, lineData);
    lineSeries.data = lineSeriesData;

    return lineSeries;
  }

  function highlightSelectedCustomerPropertyUnits(
    selectedPropertySeriesLength: number
  ) {
    return Array(selectedPropertySeriesLength)
      .fill(0)
      .map((_: number, unitIndex: number) => ({
        seriesIndex: 0,
        dataPointIndex: unitIndex,
        fillColor: "#0A0",
        strokeColor: "#000000",
        size: 11,
        shape: "circle" as ApexMarkerShape,
      }));
  }

  useEffect(() => {
    getAllProperty();
    getProperties();
  }, []);

  const getProperties = async (): Promise<void> => {
    if (token) {
      try {
        await dispatch(asyncGetUserProperty()).unwrap();
        setLoading(false);
      } catch (err) {
        console.log(err);
        setLoading(false);
      }
    }
  };
  const getIndividualProperty = async (
    indiviualProperty: string,
    name: string
  ): Promise<void> => {
    if (token) {
      setIsLoading(true);
      try {
        const {
          // customerPropertiesData,
          // propIntermediaryData,
          finalMarketSurveyData,
        } = await dispatch(
          asyncGetIndividualProperty(indiviualProperty)
        ).unwrap();
        setIsLoading(false);
        calculateData(
          // customerPropertiesData,
          // propIntermediaryData,
          name,
          // indiviualProperty,
          finalMarketSurveyData
        );
      } catch (err) {
        console.log(err);
        setIsLoading(false);
      }
    }
  };
  const getAllProperty = async (): Promise<void> => {
    if (token) {
      try {
        await dispatch(asyncGetAllProperties()).unwrap();
        await dispatch(asyncGetProperties()).unwrap();
        await dispatch(asyncGetIntermediaryProperty()).unwrap();
      } catch (err) {
        console.log(err);
      }
    }
  };
  const calculateData = (
    // customerPropertiesData: any,
    // propIntermediaryData: any,
    individualCustomerId: any,
    finalMarketSurveyData: any
  ): any => {
    const selectedCustomerObj: any = getSelectedCustomerPropertyObj(
      individualCustomerId,
      customerPropertiesData
    );

    const allUnitsForSelectedCustomerProperty = getAllUnitsForProperty(
      selectedCustomerObj?.ms_property_id ?? ""
      // propIntermediaryData
      // allPropertiesData
    );

    const competitorPropertiesUnitsListForCustomerProperty =
      getAllCompetitorsForPropertyUnits(
        finalMarketSurveyData,
        allUnitsForSelectedCustomerProperty
      );

    setCompetitorProperties(competitorPropertiesUnitsListForCustomerProperty);

    const selectedCustomerPropertyUnitsList =
      competitorPropertiesUnitsListForCustomerProperty[0]?.length ?? 0;

    const highlightedPoints = highlightSelectedCustomerPropertyUnits(
      selectedCustomerPropertyUnitsList
    ); // the total number of properties of the selected customer property is obtained from the market survey file that stored on the first index of the  competitorPropertiesForCustomerProperty array

    const newMarkers: ApexMarkers = {
      ...options.markers,
      discrete: highlightedPoints,
    };
    setOptions({ ...options, markers: newMarkers });

    const allCompetitorPropertiesPointsData =
      getAllCompetitorsForPropertyPoints(
        competitorPropertiesUnitsListForCustomerProperty
      );

    let fittingLine;
    if (!allCompetitorPropertiesPointsData.length) {
      setIsEmpty(true);
      fittingLine = initSeries[1]; //Default Line Obj
    } else {
      setIsEmpty(false);
      fittingLine = applyRegressionLine(
        allCompetitorPropertiesPointsData,
        initSeries[1]
      );
    }

    const ScatarPointsForAllCompetitorProperties =
      generateScatarPointsForCompetitorProperties(
        competitorPropertiesUnitsListForCustomerProperty
      );

    setSeries([...ScatarPointsForAllCompetitorProperties, fittingLine]);
  };
  useEffect(() => {
    if (!!checkBoxes.length) {
      const checkBoxId = checkBoxes.find((item: boolean) => item === true);

      // getIndividualProperty(checkBoxId);

      // setIsLoading(false);
    }
  }, [checkBoxes]);

  const handleCheckBox = (id: string, name: string) => {
    const newArr = checkBoxes.reduce((acc: any, curr: any) => {
      if (Object.keys(curr).includes(id)) {
        curr[id] = { ...curr[id], status: true };
        acc.push(curr);
        return acc;
      } else {
        const newId = Object.keys(curr)[0];
        curr[newId] = { ...curr[newId], status: false };
        acc.push(curr);
        return acc;
      }
    }, []);

    getIndividualProperty(id, name);
    setCheckBoxes(newArr);
  };
  const handleCheckBoxChange = () => {
    const newArr = checkBoxes.reduce((acc: any, curr: any) => {
      if (curr) {
        const newId = Object.keys(curr)[0];
        curr[newId] = { ...curr[newId], status: false };
        acc.push(curr);
        return acc;
      }
    }, []);

    setCheckBoxes(newArr);
  };

  useEffect(() => {
    if (userProperty) {
      const prop = userProperty.map((item: any, ind: number) => {
        return {
          [item.property_id]: {
            name: item.property_name,
            status: ind === 0 ? true : false,
          },
        };
      });
      if (prop?.length) {
        const id: any = Object?.keys(prop[0])[0];
        const name = prop[0][id].name;
        getIndividualProperty(id, name);
        setCheckBoxes(prop);
      }
    }
  }, [
    userProperty,
    customerPropertiesData,
    allPropertiesData,
    propIntermediaryData,
  ]);

  if (loading) {
    return <Loader />;
  }
  return (
    <>
      {activeTab === 0 && (
        <div className={styles.container}>
          <div className={styles.firstSection}>
            <div className={styles.inputs}>
              <div>
                <AppDropdown options={data1} handleChange={handleChange} />
                <AppDropdown options={data2} handleChange={handleChange} />
              </div>
              <div>
                {/* {["Include CoStar Data", "Include unavailable units"].map(
                    (item, index) => {
                      return <CheckBox value={item} key={index} />; 
                    }
                  )} */}
              </div>
            </div>
            {isLoading ? (
              <Loader />
            ) : (
              <div className={styles.chart}>
                <div className={styles.chart_content}>
                  <div className="mixed-chart">
                    <Chart
                      options={options}
                      series={series}
                      type="line"
                      height="500"
                    />
                  </div>
                </div>
              </div>
            )}
          </div>

          <div className={styles.secondSection}>
            <div className={styles.properties}>
              <div>
                {userProperty.map(
                  (item: IObject, index: number, self: IObject[]) => {
                    return (
                      <CheckBox
                        value={item.property_name}
                        property_id={item.property_id}
                        prop_state={checkBoxes}
                        index={index}
                        arr={self}
                        handleClick={handleCheckBox}
                        handleChange={handleCheckBoxChange}
                      />
                    );
                  }
                )}
              </div>
            </div>
          </div>
        </div>
      )}
      {/* {activeTab === 1 && (
        <div style={{ paddingTop: "50px" }}>
          <Table />
        </div>
      )} */}
    </>
  );
};

export default Property;
