import { useCallback, useEffect, useState } from 'react';

import DisplayRow from './DisplayRow';

import { useAppSelector } from '../../store/hooks';
import { initialMonthKeyValuePairs } from '../../store/stats/stats-utils';
import { getFormattedRoundedNearestK } from '../../utils/format';
import { monthKeys } from '../../utils/months';

const VacancyLossRow = () => {
  const { vacancyLoss }: any = useAppSelector(
    (state) => state.goals.propertyData
  );
  const vacancyLossForecast: any = useAppSelector(
    (state) => state.goals.forecastData.vacancyLoss
  );

  const [data, setData] = useState(initialMonthKeyValuePairs);
  // console.log('VacancyLossRow => propertyData.vacancyLoss:', vacancyLoss);
  // console.log('getDisplayData => data:', data);

  const getDisplayData = useCallback(() => {
    const displayData = monthKeys.reduce((acc: any, curr: any) => {
      const forecastVal = vacancyLossForecast[curr];

      if (!vacancyLoss) {
        acc[curr] = forecastVal;
        return acc;
      }

      const actual = vacancyLoss[curr];
      if (actual) {
        acc[curr] = actual;
      } else if (forecastVal) {
        acc[curr] = forecastVal;
      } else {
        acc[curr] = 0;
      }
      return acc;
    }, {});

    // console.log('VacancyLossRow => displayData:', displayData);

    setData(displayData);
  }, [vacancyLoss, vacancyLossForecast]);

  useEffect(() => {
    getDisplayData();
  }, [getDisplayData, vacancyLoss, vacancyLossForecast]);

  return (
    <DisplayRow
      evenOrOdd={'even'}
      label='Vacancy Loss'
      data={data}
      formatter={getFormattedRoundedNearestK}
    />
  );
};

export default VacancyLossRow;
