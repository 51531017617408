import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  getIndividualProperty,
  getReqUser,
  getUserProperty,
  getUserAllProperty,
  getUserPropertyById,
  getUserIntermediaryProperty,
  getProducts,
} from "../../services/auth.service";
import { setUser } from "./user-slice";
import axios from "axios";
import { createUserService, deleteUserByIdService, getAllClientsService, getAllProducts, getAllRoles, getAllRolesAndProductsService, getAllUsers, getClientRolesProductsService, getClientRolesService, updateUserService,getClientRolesPropertiesService, getUserPropertiesService,getMyRolesService } from "../../services/user.service";

export const asyncLogin = createAsyncThunk(
  "user/login",
  async (body: any, { dispatch }): Promise<{ success?: boolean }> => {
    try {
      dispatch(setUser({ user: body.user, token: body.token }));
      axios.defaults.headers.common.Authorization = `Bearer ${body.token}`;
      return { success: true };
    } catch (error) {
      console.log(error);
      return { success: false };
    }
  }
);

export const asyncGetUserProducts = createAsyncThunk(
  "user/product",
  async (newToken: any, { dispatch, getState }: any) => {
    try {
      const result: any = await getReqUser(getState().user.token || newToken);
      dispatch(setUser({ userProducts: result.data }));
      return result.data;
    } catch (error) {
      console.log(error);
      return { success: false };
    }
  }
);

export const asyncGetUserProperty = createAsyncThunk(
  "user/properties",
  async (_, { dispatch, getState }: any) => {
    try {
      const result: any = await getUserProperty(getState().user.token);
      dispatch(setUser({ userProperty: result.data }));
      return result.data;
    } catch (error) {
      console.log(error);
      return { success: false };
    }
  }
);
export const asyncGetIntermediaryProperty = createAsyncThunk(
  `user/properties-intermediary`,
  async (_, { dispatch, getState }: any) => {
    try {
      const result: any = await getUserIntermediaryProperty(
        getState().user.token
      );
      const propIntermediaryData = result?.data.map((item: any) => {
        const obj = {
          pkid: item.intermed_pkid,
          client_property_id: item.client_property_id,
          final_property_id: item.comp_final_property_id,
        };
        return obj;
      });
      dispatch(
        setUser({
          propIntermediaryData: propIntermediaryData,
        })
      );
      return result.data;
    } catch (error) {
      console.log(error);
      return { success: false };
    }
  }
);

export const asyncGetAllProducts = createAsyncThunk(
  `products/all`,
  async (_, { dispatch, getState }: any) => {
    try {
      const result: any = await getProducts(
        getState().user.token
      );
      return result.data;
    } catch (error) {
      console.log(error);
      return { success: false };
    }
  }
);

export const asyncGetProperties = createAsyncThunk(
  `user/properties-id`,
  async (_, { dispatch, getState }: any) => {
    try {
      const result: any = await getUserPropertyById(getState().user.token);
      dispatch(
        setUser({
          customerPropertiesData: result.data,
        })
      );
      return result.data;
    } catch (error) {
      console.log(error);
      return { success: false };
    }
  }
);
export const asyncGetAllProperties = createAsyncThunk(
  `user/properties-all`,
  async (_, { dispatch, getState }: any) => {
    try {
      const result: any = await getUserAllProperty(getState().user.token);
      // const customerPropertiesData = result?.data.map((item: any) => {
      //   const obj = {
      //     dv_property_id: item.dv_property_id,
      //     ms_property_id: item.ms_property_id,
      //     property_name: item.property_name,
      //     costar_id: item.costar_id,
      //     aptscom_id: item.aptscom_id,
      //     website_url: item.website_url,
      //     dv_client_id: item.dv_client_id,
      //     abbreviation: item.abbreviation,
      //   };
      //   return obj;
      // });
      // const allPropertiesData = result?.data.map((item: any) => {
      //   const obj = {
      //     dv_property_id: item.dv_property_id,
      //     ms_property_id: item.ms_property_id,
      //     property_name: item.property_name,
      //     costar_id: item.costar_id,
      //     aptscom_id: item.aptscom_id,
      //     website_url: item.website_url,
      //     client: item.client,
      //     final_property_id: item.final_property_id,
      //     unit_count: item.unit_count,
      //     costar_rating: item.costar_rating,
      //     city: item.city,
      //     year_built: item.year_built,
      //   };
      //   return obj;
      // });
      dispatch(
        setUser({
          allPropertiesData: result.data,
        })
      );
      return result.data;
    } catch (error) {
      console.log(error);
      return { success: false };
    }
  }
);
export const asyncGetIndividualProperty = createAsyncThunk(
  `user/properties`,
  async (body: string, { dispatch, getState }: any) => {
    try {
      const result: any = await getIndividualProperty(
        getState().user.token,
        body
      );
      // const customerPropertiesData = result?.data.map((item: any) => {
      //   const obj = {
      //     dv_property_id: item.cust_dv_property_id,
      //     ms_property_id: item.cust_ms_property_id,
      //     property_name: item.cust_property_name,
      //     costar_id: item.cust_costar_id,
      //     aptscom_id: item.cust_aptscom_id,
      //     website_url: item.cust_website_url,
      //     dv_client_id: item.cust_dv_client_id,
      //     abbreviation: item.cust_abbreviation,
      //   };
      //   return obj;
      // });
      // const propIntermediaryData = result?.data.map((item: any) => {
      //   const obj = {
      //     pkid: item.intermed_pkid,
      //     client_property_id: item.intermed_client_property_id,
      //     final_property_id: item.intermed_final_property_id,
      //   };
      //   return obj;
      // });
      // const allPropertiesData = result?.data.map((item: any) => {
      //   const obj = {
      //     dv_property_id: item.all_dv_property_id,
      //     ms_property_id: item.all_ms_property_id,
      //     property_name: item.all_property_name,
      //     costar_id: item.all_costar_id,
      //     aptscom_id: item.all_aptscom_id,
      //     website_url: item.all_website_url,
      //     client: item.all_client,
      //     final_property_id: item.all_final_property_id,
      //     unit_count: item.all_unit_count,
      //     costar_rating: item.all_costar_rating,
      //     city: item.all_city,
      //     year_built: item.all_year_built,
      //   };
      //   return obj;
      // });
      const finalMarketSurveyData = result?.data.map((item: any) => {
        const obj = {
          property_id: item.property_id,
          property_name: item.property_name,
          unit_number: item.unit_number,
          floorplan_name: item.floorplan_name,
          floor: item.floor,
          bedrooms: item.bedrooms,
          bathrooms: item.bathrooms,
          square_feet: item.square_feet,
          market_rent: item.market_rent,
          report_date: item.report_date,
          current_data: item.current_data,
          unit_count: item.unit_count,
          available_units: item.available_units,
          source: item.source,
        };
        return obj;
      });
      // dispatch(
      //   setUser({
      //     individualProperty: result.data,
      //     customerPropertiesData,
      //     propIntermediaryData,
      //     allPropertiesData,
      //     finalMarketSurveyData,
      //   })
      // );
      return {
        // customerPropertiesData,
        // propIntermediaryData,
        finalMarketSurveyData,
      };
    } catch (error) {
      console.log(error);
      return { success: false };
    }
  }
);

export const asyncGetAllUsers = createAsyncThunk(
  `user/all`,
  async (query: { page: string, pageSize: string}, { dispatch, getState }: any) => {
    try {
      const result: any = await getAllUsers(
        getState().user.token,
        query.page, 
        query.pageSize,
      );

      return {
        result: result.data,
      };
    } catch (error) {
      console.log(error);
      throw error
    }
  }
);

export const getAllRolesOfUser = createAsyncThunk(
  `user/all/role`,
  async (id: string, { dispatch, getState }: any) => {
    try {
      const result: any = await getAllRoles(
        id,
        getState().user.token,
      );
      return {
        result: result.data,
      };
    } catch (error) {
      throw error
    }
  }
);
export const getAllProductsOfUser = createAsyncThunk(
  `user/all/products`,
  async (id: string, { dispatch, getState }: any) => {
    try {
      const result: any = await getAllProducts(
        id,
        getState().user.token,
      );

      return {
        result: result.data,
      };
    } catch (error) {
      throw error
    }
  }
);
export const createUser = createAsyncThunk(
  `user/create`,
  async (body: any, { dispatch, getState }: any) => {
    try {
      const result: any = await createUserService(
        body,
        getState().user.token,
      );

      return {
        result: result.data,
      };
    } catch (error) {
      throw error
    }
  }
);
export const asyncGetAllClients = createAsyncThunk(
  `user/clients`,
  async (_, { getState }: any) => {
    try {
      const result: any = await getAllClientsService(getState().user.token)
      return {
        result: result.data
      };
    } catch (error) {
      throw error
    }
  }
);

export const asyncGetClientRoles = createAsyncThunk(
  `user/clients`,
  async (id: number, { getState }: any) => {
    try {
      const result: any = await getClientRolesService(id, getState().user.token)
      return {
        result: result.data
      };
    } catch (error) {
      throw error
    }
  }
);
export const asyncGetClientRolesProducts = createAsyncThunk(
  `user/clients`,
  async (id: string, { getState }: any) => {
    try {
      const result: any = await getClientRolesProductsService(id, getState().user.token)
      return {
        result: result.data
      };
    } catch (error) {
      throw error
    }
  }
);
export const asyncGetClientRolesProperties = createAsyncThunk(
  `user/clients`,
  async (id: string, { getState }: any) => {
    try {
      const result: any = await getClientRolesPropertiesService(id, getState().user.token)
      return {
        result: result.data
      };
    } catch (error) {
      throw error
    }
  }
);
export const asyncGetUserProperties = createAsyncThunk(
  `user/clients`,
  async (obj:any, { getState }: any) => {
    try {
      const result: any = await getUserPropertiesService(obj.clientId,obj.userId, getState().user.token)
      return {
        result: result.data
      };
    } catch (error) {
      throw error
    }
  }
);

export const asyncDeleteUserById = createAsyncThunk(
  `user/id`,
  async (params: { userId: number, objId: string }, { getState }: any) => {
    try {
      const result: any = await deleteUserByIdService(params, getState().user.token)
      return {
        result: result.data
      };
    } catch (error) {
      throw error
    }
  }
);

export const asyncGetAllRolesAndProducts = createAsyncThunk(
  `user/all/roles/products/:id`,
  async (ids: { clientId: number, userId: number }, { getState }: any) => {
    try {
      const result: any = await getAllRolesAndProductsService(ids, getState().user.token)
      return {
        result: result.data
      };
    } catch (error) {
      throw error
    }
  }
);
export const asyncUpdateUser = createAsyncThunk(
  `user/update`,
  async (body: any, { getState }: any) => {
    try {
      const result: any = await updateUserService(body, getState().user.token)
      return {
        result: result.data
      };
    } catch (error:any) {
      throw new Error(error.message)
    }
  }
);

export const asyncGetMyRoles=createAsyncThunk(
  `user/roles/my`,
  async (token:string, { dispatch }:any) => {
    try {
      const result: any = await getMyRolesService(token);
      dispatch(setUser({ roles: result?.data?.data}));
      return {success:true};
    } catch (error:any) {
      throw new Error(error.message)
    }
  }
);


