import { createAsyncThunk } from '@reduxjs/toolkit';

import {
  getGPRDataService,
  getGPRGraphDataService,
  getLostLeasesDataService,
  getOccupancyDataService,
  getRenewalDataService,
  getTourConversionDataService,
  getTourDataService,
  getGoalsDisplayDataService,
  getGoalsDataService,
  updateGoalsDataService,
  get3mosAveDataService,
  getForecastDataService
} from '../../services/stats.service';

import {
  updateMoveInsForecast,
  updateMoveOutsForecast
} from '../stats/stats-slice';

import {
  parseGPRGraphData,
  parseGPRData,
  parseLostLeasesData,
  parseOccupancyData,
  parseRenewalsData,
  parseTourConversionData,
  parseTourData,
  parseGoalsDisplayData,
  parseGoalsData,
  parseUpdateGoalsData,
  createForecastReqData,
  getRenewals3monthAvg
} from './stats-utils';

import { parseForecastData } from '../goals/goals-utils';

export const getGPRStats = createAsyncThunk(
  'stats/gpr-data',
  async (propertyID: string, { getState }: any) => {
    try {
      const result: any = await getGPRDataService(
        propertyID,
        getState().user.token
      );
      // console.log('getGPRStats/result', result);

      const parsedData = parseGPRData(result.data);
      // console.log('getGPRStats/parsedData', parsedData);

      return { gpr: parsedData };
    } catch (error) {
      console.log(error);
      throw error;
    }
  }
);
export const getGPRGraphData = createAsyncThunk(
  'stats/gpr-graph',
  async (propertyID: string, { getState }: any) => {
    try {
      const result: any = await getGPRGraphDataService(
        propertyID,
        getState().user.token
      );
      // console.log('getGPRGraphData/result', result);

      const parsedData = parseGPRGraphData(result.data);
      // console.log('getGPRGraphData/parsedData', parsedData);

      return parsedData as any;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }
);

export const getLostLeasesStats = createAsyncThunk(
  'stats/lost-leases',
  async (properyID: string, { getState }: any) => {
    try {
      const result: any = await getLostLeasesDataService(
        properyID,
        getState().user.token
      );
      // console.log('getLostLeasesStats/result: ', result);

      const parsedData = parseLostLeasesData(result.data);
      // console.log('getLostLeasesStats/parsedData', parsedData);

      return { lostLeases: parsedData };
    } catch (error) {
      console.log(error);
      throw error;
    }
  }
);

export const getOccupancyStats = createAsyncThunk(
  'stats/occupancy',
  async (propertyID: string, { getState }: any) => {
    try {
      const result: any = await getOccupancyDataService(
        propertyID,
        getState().user.token
      );
      // console.log('getOccupancyStats: ', result);

      const parsedData = parseOccupancyData(result.data);
      // console.log('getOccupancyStats/parsedData', parsedData);

      return parsedData as any;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }
);

export const getRenewalStats = createAsyncThunk(
  'stats/renewals',
  async (propertyID: string, { getState }: any) => {
    try {
      const result: any = await getRenewalDataService(
        propertyID,
        getState().user.token
      );

      const parsedData = parseRenewalsData(result.data);
      // console.log('getRenewalStats/parsedData', parsedData);

      return { renewals: parsedData };
    } catch (error) {
      console.log(error);
      throw error;
    }
  }
);

export const getTourConversionStats = createAsyncThunk(
  'stats/tourconversion',
  async (propertyID: string, { getState }: any) => {
    try {
      const result: any = await getTourConversionDataService(
        propertyID,
        getState().user.token
      );
      // console.log('getTourConversionStats: ', result);

      const parsedData = parseTourConversionData(result.data);
      // console.log('getTourConversionStats/parsedData', parsedData);
      return { tourConversion: parsedData };
    } catch (error) {
      console.log(error);
      throw error;
    }
  }
);

export const getTourStats = createAsyncThunk(
  'stats/tours',
  async (propertyID: string, { getState }: any) => {
    try {
      const result: any = await getTourDataService(
        propertyID,
        getState().user.token
      );
      // console.log('getTourStats: ', result);

      const parsedData = parseTourData(result.data);
      // console.log('getTourStats/parsedData', parsedData);

      return { tours: parsedData };
    } catch (error) {
      console.log(error);
      throw error;
    }
  }
);

export const getGoalsData = createAsyncThunk(
  'stats/goals/get',
  async (_, { getState }: any) => {
    try {
      const result: any = await getGoalsDataService(getState().user.token);
      // console.log('getGoalsData: ', result);

      const parsedData = parseGoalsData(result.data);
      // console.log('getGoalsData/parsedData', parsedData);

      return parsedData as any;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }
);

export const getGoalsDisplayData = createAsyncThunk(
  'stats/goals/display/get',
  async (_, { getState }: any) => {
    try {
      const result: any = await getGoalsDisplayDataService(
        getState().user.token
      );
      // console.log('getGoalsDisplayData: ', result);

      const parsedData = parseGoalsDisplayData(result.data);
      // console.log('getGoalsDisplayData/parsedData', parsedData);

      return parsedData as any;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }
);

export const updateGoalsData = createAsyncThunk(
  'stats/goals/update',
  async (data: any, { dispatch, getState }: any) => {
    try {
      const body = parseUpdateGoalsData(data);
      // console.log('updateGoalsData/body', body);

      const result: any = await updateGoalsDataService(
        body,
        getState().user.token
      );
      console.log('updateGoalsData/result: ', result);

      dispatch(getGoalsData());

      return { success: true };
    } catch (error) {
      console.log(error);
      throw error;
    }
  }
);

// getForecastData 'stats/forecast/get'
// 2 requests:
// 1. GET /api/stats/3mos-ave/:propertyID
// 2. GET /api/stats/forecast/:propertyID
// need results from 1. for 2.

export const get3mosAveData = createAsyncThunk(
  'stats/3mos-ave/get',
  async (propertyID: string, { getState }: any) => {
    try {
      const result: any = await get3mosAveDataService(
        propertyID,
        getState().user.token
      );
      // console.log('get3mosAveData: ', result);

      const parsedData = result.data;
      // console.log('get3mosAveData/parsedData', parsedData);

      return parsedData as any;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }
);

// gets forecast data from ShortfallEndpoint
export const getForecastData = createAsyncThunk(
  'stats/forecast/get',
  async (reqData: any, { getState }: any) => {
    try {
      // console.log('getForecastData/reqData: ', reqData);
      const body = createForecastReqData(reqData);
      // console.log('getForecastData/body: ', body);

      const result: any = await getForecastDataService(
        body,
        getState().user.token
      );
      // console.log('getForecastData/result: ', result);

      const parsedData = parseForecastData(result.data);
      // console.log('getForecastData/parsedData', parsedData);

      return parsedData as any;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }
);

export const getForecastDataWith3mosAve = createAsyncThunk(
  'stats/forecast/dashboard/get',
  async (propertyID: string, { dispatch, getState }: any) => {
    try {
      // console.log('getForecastDataWith3mosAve/propertyID: ', propertyID);

      // console.log('Dispatching get3mosAveData');
      const { payload }: any = await dispatch(get3mosAveData(propertyID));
      // console.log('getForecastDataWith3mosAve/payload: ', payload);

      const renewals = getState().goals.propertyData.renewalPct;
      // console.log('getForecastDataWith3mosAve/renewals: ', renewals);

      const tours = getState().goals.propertyData.tours;
      // console.log('getForecastDataWith3mosAve/tours: ', tours);

      dispatch(
        updateMoveInsForecast({
          netLeasesPct: payload.data.three_mo_avg_netLeasesPct * 100,
          tours: payload.data.three_mo_avg_Tours,
          tourConversionPct: payload.data.ConversionPercentage
        })
      );

      const expiringLeases = getState().goals.propertyData.expiringLeases;
      // console.log(
      //   'getForecastDataWith3mosAve/expiringLeases: ',
      //   expiringLeases
      // );

      dispatch(
        updateMoveOutsForecast({
          expiringLeases,
          renewalPct: getRenewals3monthAvg(renewals)
        })
      );

      dispatch(
        getForecastData({
          ...payload.data,
          renewals,
          tours
        })
      );

      return null;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }
);
