/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { setProperty } from "../../../store/properties/property-slice";
import InputForm from "./inputForm";
import Summary from "./summary";
import MoveInFees from "./move-in-fees";
import MonthlyFees from "./monthly-fees";
import { asyncGetUserProperties } from "../../../store/renewal/renewal-async";

const IncomePlus = () => {
  const [selectedProperty, setSelectedProperty] = useState("");
  const [propertyOptions, setPropertyOptions] = useState<Item[] | []>([]);
  const [activeLabel, setActiveLabel] = useState<string>("");
  const dispatch = useAppDispatch();
  const {
    property: { activeTab },
  } = useAppSelector((s) => ({ property: s.property, user: s.user }));
  const { user }: { [key: string]: any } = useAppSelector((s) => s.user);

  const getUserProperties = async () => {
    try {
      const userProperies = await dispatch(
        asyncGetUserProperties(`${user?.idTokenClaims?.oid}`)
      ).unwrap();
      setPropertyOptions(
        userProperies.data.map((obj: any) => ({
          label: obj.property_name,
          value: obj.ms_property_id,
        }))
      );
      setSelectedProperty(userProperies.data[0].ms_property_id);
      setActiveLabel(userProperies.data[0].property_name);
    } catch (error) {}
  };

  useEffect(() => {
    if (user) {
      getUserProperties();
    }
  }, [user]);

  useEffect(() => {
    dispatch(
      setProperty({
        tabs: ["Input Form", "Pricing Summary", "Move-In Fees", "Monthly Fees"],
      })
    );
    return () => {
      dispatch(setProperty({ tabs: [], activeTab: 0 }));
    };
  }, []);

  return (
    <>
      {activeTab === 0 && <InputForm />}
      {activeTab === 1 && (
        <Summary
          selectedProperty={selectedProperty}
          setSelectedProperty={setSelectedProperty}
          propertyOptions={propertyOptions}
          activeLabel={activeLabel}
          setActiveLabel={setActiveLabel}
        />
      )}
      {activeTab === 2 && (
        <MoveInFees
          selectedProperty={selectedProperty}
          setSelectedProperty={setSelectedProperty}
          propertyOptions={propertyOptions}
          activeLabel={activeLabel}
          setActiveLabel={setActiveLabel}
        />
      )}
      {activeTab === 3 && (
        <MonthlyFees
          selectedProperty={selectedProperty}
          setSelectedProperty={setSelectedProperty}
          propertyOptions={propertyOptions}
          activeLabel={activeLabel}
          setActiveLabel={setActiveLabel}
        />
      )}
    </>
  );
};

export default IncomePlus;
