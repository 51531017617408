export const getFormattedDollarAmount = (amount) => {
  if (!amount || amount === 0) {
    return '-';
  }

  if (amount > 1000000) {
    return `$${(amount / 1000000).toFixed(1)}M`;
  }

  if (amount < -1000000) {
    return `-$${(Math.abs(amount) / 1000000).toFixed(1)}M`;
  }

  if (amount > 1000) {
    return `$${(amount / 1000).toFixed(1)}K`;
  }

  if (amount < -1000) {
    return `-$${(Math.abs(amount) / 1000).toFixed(1)}K`;
  }

  if (amount < 0) {
    return `-$${Math.abs(amount)}`;
  }

  return `$${amount}`;
};

export const getFormattedRoundedNearestK = (amount) => {
  if (amount > 1000000) {
    return `${(amount / 1000000).toFixed(1)}M`;
  }
  if (amount < -1000000) {
    return `${(Math.abs(amount) / 1000000).toFixed(1)}M`;
  }
  if (amount > 1000) {
    return `${(amount / 1000).toFixed(1)}K`;
  }
  if (amount < -1000) {
    return `-${(Math.abs(amount) / 1000).toFixed(1)}K`;
  }
  if (amount < 0) {
    return `-${Math.abs(amount)}`;
  }

  return `${amount}`;
};

export const getFormattedKAmount = (amount) => {
  if (!amount || amount === 0) {
    return '-';
  }
  if (amount > 1) {
    return `${Math.round(amount)}K`;
  }
  if (amount < -1) {
    return `-${Math.round(Math.abs(amount))}K`;
  }
  if (amount < 0) {
    return `-${Math.round(Math.abs(amount))}`;
  }

  return `${amount}`;
};

export const defaultFormatter = (value) => (value ? value : '-');

export const getFormattedPercentage = (value) => {
  if (!value) {
    return '-';
  }

  return `${value}%`;
};
