import { useCallback, useEffect, useState } from 'react';

import DisplayRow from './DisplayRow';

import { useAppSelector } from '../../store/hooks';
import { initialMonthKeyValuePairs } from '../../store/stats/stats-utils';
import { getFormattedRoundedNearestK } from '../../utils/format';
import { monthKeys } from '../../utils/months';

const LossToLeaseRow = () => {
  const { lossToLease }: any = useAppSelector(
    (state) => state.goals.propertyData
  );
  const lossToLeaseForecast: any = useAppSelector(
    (state) => state.goals.forecastData.lossToLease
  );

  const [data, setData] = useState(initialMonthKeyValuePairs);
  // console.log('LossToLeaseRow => propertyData.lossToLease:', lossToLease);
  // console.log('getDisplayData => data:', data);

  const getDisplayData = useCallback(() => {
    const displayData = monthKeys.reduce((acc: any, curr: any) => {
      const forecastVal = lossToLeaseForecast[curr];

      if (!lossToLease) {
        acc[curr] = forecastVal;
        return acc;
      }

      const actual = lossToLease[curr];
      if (actual) {
        acc[curr] = actual;
      } else if (forecastVal) {
        acc[curr] = forecastVal;
      } else {
        acc[curr] = 0;
      }
      return acc;
    }, {});

    setData(displayData);
  }, [lossToLease, lossToLeaseForecast]);

  useEffect(() => {
    getDisplayData();
  }, [getDisplayData, lossToLease]);

  return (
    <DisplayRow
      evenOrOdd={'even'}
      label='Loss to Lease'
      data={data}
      formatter={getFormattedRoundedNearestK}
    />
  );
};

export default LossToLeaseRow;
