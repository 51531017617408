import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    activeTab: 0,
    tabs:[]
};

export const property = createSlice({
    name: "Auth",
    initialState,
    reducers: {
        setProperty: (state, { payload }) => ({ ...state, ...payload }),
    },
});

export const { setProperty } = property.actions;
export default property.reducer;
