import React from "react";

import StatBarChart from "./StatBarChart";

import styles from "../../components/stats/stats.module.scss";

const StatCard = ({ data }) => {
  const { title, barData, topData, bottomData } = data;

  const topDataValue = topData.value;
  // Use this if tours projected variance is decimal value, e.g. 0.25
  // const topDataValue =
  //   topData.label === 'Projected Variance'
  //     ? `${topData.value * 100}%`
  //     : topData.value;

  return (
    <div className={styles.statCard}>
      <div className={styles.statCardTitle}>{title}</div>

      <StatBarChart data={barData} name={title} />

      <div className={styles.statCardTopSection}>
        <div className={styles.statCardSection}>
          <label>{topData.label}</label>
          <div className={styles.statCardValue}>{topDataValue}</div>
        </div>
      </div>
      <div className={styles.statCardBottomSection}>
        <div className={styles.statCardSection}>
          <label>{bottomData.label}</label>
          <div className={styles.statCardValue}>{bottomData.value}</div>
        </div>
      </div>
    </div>
  );
};

export default StatCard;
