import { useCallback, useEffect, useState } from 'react';

import DisplayRow from './DisplayRow';

import { useAppSelector } from '../../store/hooks';
import { initialMonthKeyValuePairs } from '../../store/stats/stats-utils';
import { defaultFormatter } from '../../utils/format';

const ExpiringLeasesRow = () => {
  const { expiringLeases }: any = useAppSelector(
    (state) => state.goals.propertyData
  );
  // Note: no forecast data necessary because all months s/b in propertyData

  const [data, setData] = useState(initialMonthKeyValuePairs);
  // console.log(
  //   'ExpiringLeasesRow => propertyData.expiringLeases:',
  //   expiringLeases
  // );
  // console.log('getDisplayData => data:', data);

  const getDisplayData = useCallback(() => {
    setData(expiringLeases);
  }, [expiringLeases]);

  useEffect(() => {
    getDisplayData();
  }, [getDisplayData, expiringLeases]);

  return (
    <DisplayRow
      evenOrOdd={'odd'}
      label='Expiring Leases'
      data={data}
      formatter={defaultFormatter}
    />
  );
};

export default ExpiringLeasesRow;
