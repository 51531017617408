import { Spin } from 'antd';

const Loader = () => (
  <div
    style={{ position: 'absolute', top: '1rem', left: '50%', zIndex: '2000' }}
  >
    <Spin />
  </div>
);

export default Loader;
