import { AxiosError, AxiosResponse } from "axios";
import { httpRequest } from "./request";

function getCompetitorOptions(
  { propertyId }: { propertyId: string },
  token: string
): Promise<AxiosResponse | AxiosError> {
  return new Promise((resolve, reject) => {
    httpRequest("properties/competitors", "get", token, null, true, {
      propertyId,
    })
      .then((res: AxiosResponse) => {
        resolve(res);
      })
      .catch((err: AxiosError) => {
        reject(err);
      });
  });
}
function getPricingSummary(
  { propertyId, bedrooms }: { propertyId: string; bedrooms: number },
  token: string
): Promise<AxiosResponse | AxiosError> {
  return new Promise((resolve, reject) => {
    httpRequest("properties/pricing-summary", "get", token, null, true, {
      propertyId,
      bedrooms,
    })
      .then((res: AxiosResponse) => {
        resolve(res);
      })
      .catch((err: AxiosError) => {
        reject(err);
      });
  });
}

function populateFormData(
  { propertyId, competitorId }: { propertyId: string; competitorId: string },
  token: string
): Promise<AxiosResponse | AxiosError> {
  return new Promise((resolve, reject) => {
    httpRequest("properties/populate", "get", token, null, true, {
      propertyId,
      competitorId,
    })
      .then((res: AxiosResponse) => {
        resolve(res);
      })
      .catch((err: AxiosError) => {
        reject(err);
      });
  });
}

function addIncomePlus(
  body: { body: any },
  token: string
): Promise<AxiosResponse | AxiosError> {
  return new Promise((resolve, reject) => {
    httpRequest("properties/incomPlus", "post", token, body)
      .then((res: AxiosResponse) => {
        resolve(res);
      })
      .catch((err: AxiosError) => {
        reject(err);
      });
  });
}

function getMovInFees(
  { propertyId }: { propertyId: string },
  token: string
): Promise<AxiosResponse | AxiosError> {
  return new Promise((resolve, reject) => {
    httpRequest("properties/moveInFee", "get", token, null, true, {
      propertyId,
    })
      .then((res: AxiosResponse) => {
        resolve(res);
      })
      .catch((err: AxiosError) => {
        reject(err);
      });
  });
}

function getMonthlyFees(
  { propertyId }: { propertyId: string },
  token: string
): Promise<AxiosResponse | AxiosError> {
  return new Promise((resolve, reject) => {
    httpRequest("properties/monthlyFee", "get", token, null, true, {
      propertyId,
    })
      .then((res: AxiosResponse) => {
        resolve(res);
      })
      .catch((err: AxiosError) => {
        reject(err);
      });
  });
}

export {
  getCompetitorOptions,
  populateFormData,
  addIncomePlus,
  getMovInFees,
  getMonthlyFees,
  getPricingSummary,
};
