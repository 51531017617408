import { useCallback, useEffect, useState } from 'react';

import DisplayRow from './DisplayRow';

import { useAppSelector } from '../../store/hooks';
import { initialMonthKeyValuePairs } from '../../store/stats/stats-utils';
import { getFormattedPercentage } from '../../utils/format';
import { monthKeys } from '../../utils/months';

const ConversionPctRow = ({ tourConversionPctGoal }: any) => {
  const { conversionPct }: any = useAppSelector(
    (state) => state.goals.propertyData
  );

  const [data, setData] = useState(initialMonthKeyValuePairs);
  // console.log('ConversionPctRow => tourConversionPctGoal:', tourConversionPctGoal);
  // console.log('ConversionPctRow => propertyData.conversionPct:', conversionPct);
  // console.log('getDisplayData => data:', data);

  const getDisplayData = useCallback(() => {
    const displayData = monthKeys.reduce((acc: any, curr: any) => {
      const goal = tourConversionPctGoal;
      if (!conversionPct) {
        acc[curr] = goal;
        return acc;
      }

      const actual = conversionPct[curr];
      if (actual) {
        acc[curr] = actual;
      } else if (goal) {
        acc[curr] = goal;
      } else {
        acc[curr] = 0;
      }
      return acc;
    }, {});

    setData(displayData);
  }, [tourConversionPctGoal, conversionPct]);

  useEffect(() => {
    getDisplayData();
  }, [getDisplayData, conversionPct]);

  return (
    <DisplayRow
      evenOrOdd={'odd'}
      label='Conversion %'
      data={data}
      formatter={getFormattedPercentage}
    />
  );
};

export default ConversionPctRow;
