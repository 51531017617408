import React from "react";
interface VProps {
  value: string;
  property_id: string;
  prop_state: any;
  index: number;
  arr: any;
  handleClick: any;
  handleChange: any;
}
const CheckBox = ({
  value,
  property_id,
  prop_state,
  index,
  arr,
  handleClick,
  handleChange,
}: VProps) => {
  return (
    <div>
      {!prop_state.find((item: any) => Object.keys(item).includes(property_id))[
        property_id
      ].status ? (
        <div
          onClick={() => {
            handleClick(property_id, value);
          }}
        ></div>
      ) : (
        <input
          type="checkbox"
          checked
          onChange={() => {
            handleChange();
          }}
        />
      )}
      <label>{value}</label>
    </div>
  );
};

export default CheckBox;
