import { AxiosError, AxiosResponse } from "axios";
import { httpRequest } from "./request";

function getAllUsers(token: string, page: string, pageSize: string): Promise<AxiosResponse | AxiosError> {
    return new Promise((resolve, reject) => {
        httpRequest("user/all", "get", token, null, true, { page, pageSize })
            .then((res: AxiosResponse) => {
                resolve(res);
            })
            .catch((err: AxiosError) => {
                reject(err);
            });
    });
}
function getAllRoles(userId: string, token: string): Promise<AxiosResponse | AxiosError> {
    return new Promise((resolve, reject) => {
        httpRequest(`user/roles/${userId}`, "get", token, null, true, { page: 1, pageSize: 30 })
            .then((res: AxiosResponse) => {
                resolve(res);
            })
            .catch((err: AxiosError) => {
                reject(err);
            });
    });
}
function getAllProducts(userId: string, token: string): Promise<AxiosResponse | AxiosError> {
    return new Promise((resolve, reject) => {
        httpRequest(`user/products/${userId}`, "get", token, null, true, { page: 1, pageSize: 30 })
            .then((res: AxiosResponse) => {
                resolve(res);
            })
            .catch((err: AxiosError) => {
                reject(err);
            });
    });
}
function createUserService(body: any, token: string): Promise<AxiosResponse | AxiosError> {
    return new Promise((resolve, reject) => {
        httpRequest(`user/`, "post", token, body)
            .then((res: AxiosResponse) => {
                resolve(res);
            })
            .catch((err: AxiosError) => {
                reject(err);
            });
    });
}
function getAllClientsService(token: string): Promise<AxiosResponse | AxiosError> {
    return new Promise((resolve, reject) => {
        httpRequest(`user/clients`, "get", token)
            .then((res: AxiosResponse) => {
                resolve(res);
            })
            .catch((err: AxiosError) => {
                reject(err);
            });
    });
}
function getClientRolesService(id: number, token: string): Promise<AxiosResponse | AxiosError> {
    return new Promise((resolve, reject) => {
        httpRequest(`user/client/roles/${id}`, "get", token)
            .then((res: AxiosResponse) => {
                resolve(res);
            })
            .catch((err: AxiosError) => {
                reject(err);
            });
    });
}
function getClientRolesProductsService(id: string, token: string): Promise<AxiosResponse | AxiosError> {
    return new Promise((resolve, reject) => {
        httpRequest(`user/roles/products/${id}`, "get", token)
            .then((res: AxiosResponse) => {
                resolve(res);
            })
            .catch((err: AxiosError) => {
                reject(err);
            });
    });
}
function getClientRolesPropertiesService(id: string, token: string): Promise<AxiosResponse | AxiosError> {
    return new Promise((resolve, reject) => {
        httpRequest(`user/client/properties/${id}`, "get", token)
            .then((res: AxiosResponse) => {
                resolve(res);
            })
            .catch((err: AxiosError) => {
                reject(err);
            });
    });
}
function getUserPropertiesService(clientId: string,userId:string, token: string): Promise<AxiosResponse | AxiosError> {
    return new Promise((resolve, reject) => {
        httpRequest(`user/client/properties/${clientId}/${userId}`, "get", token)
            .then((res: AxiosResponse) => {
                resolve(res);
            })
            .catch((err: AxiosError) => {
                reject(err);
            });
    });
}
function deleteUserByIdService(params: { userId: number, objId: string }, token: string): Promise<AxiosResponse | AxiosError> {
    return new Promise((resolve, reject) => {
        httpRequest(`user/${params.objId}/${params.userId}`, "delete", token)
            .then((res: AxiosResponse) => {
                resolve(res);
            })
            .catch((err: AxiosError) => {
                reject(err);
            });
    });
}

function getAllRolesAndProductsService(ids: { clientId: number, userId: number }, token: string): Promise<AxiosResponse | AxiosError> {
    return new Promise((resolve, reject) => {
        httpRequest(`user/all/roles-products/${ids.clientId}/${ids.userId}`, "get", token)
            .then((res: AxiosResponse) => {
                resolve(res);
            })
            .catch((err: AxiosError) => {
                reject(err);
            });
    });
}
function updateUserService(body: any, token: string): Promise<AxiosResponse | AxiosError> {
    return new Promise((resolve, reject) => {
        httpRequest(`user/`, "patch", token, body)
            .then((res: AxiosResponse) => {
                resolve(res);
            })
            .catch((err: AxiosError) => {
                reject(err);
            });
    });
}

function getMyRolesService(token:string): Promise<AxiosResponse | AxiosError> {
    return new Promise((resolve, reject) => {
        httpRequest(`user/roles/my`, "get",token)
            .then((res: AxiosResponse) => {
                resolve(res);
            })
            .catch((err: AxiosError) => {
                reject(err);
            });
    });
}

export { getAllUsers, getAllProducts, getAllRoles, createUserService, getAllClientsService, getClientRolesService, getClientRolesProductsService, deleteUserByIdService, getAllRolesAndProductsService, updateUserService,getClientRolesPropertiesService,getUserPropertiesService,getMyRolesService }
