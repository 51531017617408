import ReactApexChart from "react-apexcharts";
import { ApexOptions } from "apexcharts";
import { useEffect, useState } from "react";
import { Typography } from "antd";
import classes from "./style.module.scss";

interface SeriesData {
  data: number[];
}

interface ChartData {
  series: SeriesData[];
  options: ApexOptions;
}

const BarChart = ({
  data,
  labels,
  title,
  height = 270,
  notes = [],
  activeLabel,
  xAxisLabels = true,
  yAxisLabels = true,
  tooltip = true,
  XTitle,
  YTitle,
  group,
}: {
  data: any;
  labels: any;
  title?: string;
  height?: number;
  notes?: string[];
  activeLabel?: string;
  xAxisLabels?: boolean;
  yAxisLabels?: boolean;
  tooltip?: boolean;
  XTitle?: string;
  YTitle?: string;
  group?: boolean;
}) => {
  const colors = labels.map((label: string) =>
    label === activeLabel ? "#4361F9" : "#ECEFFE"
  );

  const dataLabelcolors = labels.map((label: string) =>
    label === activeLabel ? "#000" : "#8390A2"
  );

  const [chartData, setChartData] = useState<ChartData>({
    series: [
      {
        data: data,
      },
    ],
    options: {
      states: {
        hover: {
          filter: {
            type: "dark",
            value: 0,
          },
        },
      },
      chart: {
        type: "bar",
        toolbar: {
          show: false,
        },
      },

      plotOptions: {
        bar: {
          borderRadius: 0,
          horizontal: true,
          // barHeight: 43,
          distributed: true,
          dataLabels: {
            total: {
              enabled: false,
            },
            position: "top",
            hideOverflowingLabels: true,
          },
        },
      },

      colors: colors,
      dataLabels: {
        enabled: true,
        offsetX: 32,
        formatter: function (value: number) {
          // @ts-ignore
          return `$${new Intl.NumberFormat("en-US").format(value.toFixed(2))}`;
        },
        style: {
          fontSize: "11px",
          colors: dataLabelcolors,
          fontWeight: "Regular",
          fontFamily: "Poppins",
        },
      },

      legend: {
        show: false,
      },
      tooltip: {
        enabled: tooltip,
        custom({ series, seriesIndex, dataPointIndex, w }) {
          // const value = w.config.series[seriesIndex].data[dataPointIndex];
          return `
            <div class=${classes.customTooltip}>
            <div class=${classes.para}>${notes[dataPointIndex] ?? ""}</div>
            </div>
          `;
        },
      },
      title: {
        text: title || "Title",
        style: {
          fontWeight: 500,
          fontSize: "15px",
          fontFamily: "Poppins",
        },
        offsetX: 18,
      },
      xaxis: {
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        labels: {
          show: xAxisLabels,
          style: {
            fontSize: "11px",
            colors: [
              "#8390A2",
              "#8390A2",
              "#8390A2",
              "#8390A2",
              "#8390A2",
              "#8390A2",
              "#8390A2",
              "#8390A2",
              "#8390A2",
              "#8390A2",
              "#8390A2",
              "#8390A2",
            ],
            fontWeight: "Regular",
            fontFamily: "Poppins",
          },
          formatter: (value) => {
            // @ts-ignore
            const newNum = value.toFixed(2);
            return `$${new Intl.NumberFormat("en-US").format(newNum)}`;
          },
        },
        categories: labels,
        title: {
          text: XTitle || undefined,
          offsetX: 0,
          offsetY: 15,
          style: {
            color: "#8390A2",
            fontSize: "18px",
            fontFamily: "Poppins",
            fontWeight: "normal",
          },
        },
      },
      yaxis: {
        labels: {
          show: yAxisLabels,
          style: {
            fontSize: "11px",
            colors: [
              "#8390A2",
              "#8390A2",
              "#8390A2",
              "#8390A2",
              "#8390A2",
              "#8390A2",
              "#8390A2",
              "#8390A2",
              "#8390A2",
              "#8390A2",
              "#8390A2",
              "#8390A2",
            ],
            fontWeight: "Regular",
            fontFamily: "Poppins",
          },
        },
        title: {
          text: YTitle || undefined,
          offsetX: 15,
          offsetY: 0,
          style: {
            color: "#8390A2",
            fontSize: "18px",
            fontFamily: "Poppins",
            fontWeight: "normal",
          },
        },
      },
      grid: {
        show: false,
      },
    },
  });

  const updateOptions = () => {
    const newWidth = window.innerWidth;
    if (newWidth < 700) {
      setChartData((prev) => ({
        ...prev,
        options: {
          plotOptions: {
            bar: {
              horizontal: false,
            },
          },
          yaxis: {
            title: {
              text: undefined,
            },
            labels: {
              formatter: (value: any) => {
                // @ts-ignore
                console.log("value===>", value);
                const newNum = value.toFixed(2);
                return `$${new Intl.NumberFormat("en-US").format(newNum)}`;
              },
            },
          },
          xaxis: {
            title: {
              text: undefined,
            },
            labels: {
              rotate: -90,
              formatter: (value) => {
                return value;
              },
            },
          },
        },
      }));
    } else {
      setChartData((prev) => ({
        ...prev,
        options: {
          plotOptions: {
            bar: {
              horizontal: true,
            },
          },
          yaxis: {
            title: {
              text: YTitle || undefined,
              offsetX: 15,
              offsetY: 0,
              style: {
                color: "#8390A2",
                fontSize: "18px",
                fontFamily: "Poppins",
                fontWeight: "normal",
              },
            },
          },
          xaxis: {
            title: {
              text: XTitle || undefined,
              offsetX: 0,
              offsetY: 15,
              style: {
                color: "#8390A2",
                fontSize: "18px",
                fontFamily: "Poppins",
                fontWeight: "normal",
              },
            },
          },
        },
      }));
    }
  };

  useEffect(() => {
    updateOptions();
    window.addEventListener("resize", updateOptions);
    // Cleanup the event listener on component unmount
    return () => window.removeEventListener("resize", updateOptions);
  }, []);

  useEffect(() => {
    setChartData((prev) => ({
      ...prev,
      options: {
        ...prev.options,
        yaxis: {
          ...prev.options.yaxis,
          labels: {
            show: yAxisLabels,
            style: {
              fontSize: "11px",
              colors: [
                "#8390A2",
                "#8390A2",
                "#8390A2",
                "#8390A2",
                "#8390A2",
                "#8390A2",
                "#8390A2",
                "#8390A2",
                "#8390A2",
                "#8390A2",
                "#8390A2",
                "#8390A2",
              ],
              fontWeight: "Regular",
              fontFamily: "Poppins",
            },
          },
        },
      },
    }));
  }, [yAxisLabels]);

  if (data.length === 0) {
    return (
      <div style={{ minHeight: "200px" }}>
        <Typography.Title level={5} style={{ marginTop: 0, marginLeft: 10 }}>
          {title}
        </Typography.Title>
        <Typography.Text style={{ color: "lightgray", marginLeft: 10 }}>
          No data
        </Typography.Text>
      </div>
    );
  }

  let dynamicHeight;
  if (height < 270) {
    dynamicHeight = 200;
  } else {
    dynamicHeight = height;
  }
  return (
    <>
      <div
        id="chart"
        className={classes.chartContainer}
        style={{
          height: `${dynamicHeight + 40}px`,
          overflowY: `${group ? "hidden" : "auto"}`,
        }}
      >
        <ReactApexChart
          options={chartData.options}
          series={chartData.series}
          type="bar"
          height={dynamicHeight + 20 || 400}
        />
      </div>
      <div id="html-dist"></div>
    </>
  );
};

export default BarChart;
