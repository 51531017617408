// import { useEffect } from "react";
// import { useAppDispatch, useAppSelector } from "../../store/hooks";
// import { setProperty } from "../../store/properties/property-slice";
import DailyPricing from "./daily-pricing";

const SubmarketSnapshot = () => {
  // const dispatch = useAppDispatch();
  // const {
  //   property: { activeTab },
  // } = useAppSelector((s) => ({ property: s.property, user: s.user }));

  // useEffect(() => {
  //   dispatch(setProperty({ tabs: ["Daily Pricing"] }));
  //   return () => {
  //     dispatch(setProperty({ tabs: [], activeTab: 0 }));
  //   };
  // }, []);

  // return <>{activeTab === 0 && <DailyPricing />}</>;
  return <DailyPricing />;
};

export default SubmarketSnapshot;
