import Chart from 'react-apexcharts';

import {
  purple300,
  grey700,
  indigo500,
  orange500,
  green500,
  textColor
} from './chartColors';
import { monthLabels } from '../../utils/months';

import styles from './forecastChart.module.scss';

const defaultGraphData = new Array(12).fill(0);

const ForecastChart = ({ data }) => {
  // console.log('ForecastChart/data: ', data);

  const actualData = data?.actual ? data.actual : defaultGraphData;
  const budgetData = data?.budget ? data.budget : defaultGraphData;
  const forecastData = data?.forecast ? data.forecast : defaultGraphData;
  const occupancyData = data?.occupancyPct
    ? data.occupancyPct
    : defaultGraphData;

  const exposureData = data?.exposurePct ? data.exposurePct : defaultGraphData;

  const maxLevel = Math.round(
    Math.max(...actualData, ...budgetData, ...forecastData) * 1.1
  );

  const minData = [...actualData, ...budgetData].filter((value) => value > 0);

  const minValue = minData.length > 0 ? Math.min(...minData) : 0;
  const minLevel = Math.round(minValue * 0.975);

  const chartHeight = 420;

  const chartSeries = [
    {
      name: 'Actual',
      type: 'column',
      data: actualData
    },
    {
      name: 'Budget',
      type: 'column',
      data: budgetData
    },
    {
      name: 'Forecast',
      type: 'column',
      data: forecastData
    },
    {
      name: 'Occupancy',
      type: 'line',
      data: occupancyData
    },
    {
      name: 'Exposure',
      type: 'line',
      data: exposureData
    }
  ];

  const chartOptions = {
    chart: {
      background: '#fff',
      height: chartHeight,
      width: '100%',
      type: 'line',
      toolbar: {
        show: false
      }
    },
    plotOptions: {
      bar: {
        borderRadius: 3,
        borderRadiusApplication: 'end',
        columnWidth: '66%'
      }
    },
    dataLabels: {
      enabled: false
    },
    stroke: {
      show: true,
      width: [8, 8, 8, 6, 6],
      colors: ['transparent', 'transparent', 'transparent', purple300, green500]
    },
    xaxis: {
      type: 'category',
      categories: monthLabels,
      labels: {
        show: true,
        style: {
          colors: grey700,
          fontFamily: 'Poppins, sans-serif',
          fontSize: '14px',
          fontWeight: 400
        },
        offsetX: -2,
        offsetY: 4
      },
      tooltip: {
        enabled: false
      }
    },

    yaxis: [
      {
        show: true,
        seriesName: 'Budget',
        title: {
          text: 'ERR',
          style: {
            color: grey700,
            fontFamily: 'Poppins, sans-serif',
            fontSize: '18px',
            fontWeight: '400'
          }
        },
        labels: {
          formatter: (val) => (val ? `${val.toFixed(1)}K` : ''),
          style: {
            colors: grey700,
            fontFamily: 'Poppins, sans-serif',
            fontSize: '11px',
            fontWeight: 400
          }
        },
        max: maxLevel || 100,
        min: minLevel || 0
      },
      {
        show: false,
        seriesName: 'Actual',
        max: maxLevel || 100,
        min: minLevel || 0
      },
      {
        show: false,
        seriesName: 'Forecast',
        max: maxLevel || 100,
        min: minLevel || 0
      },
      {
        show: true,
        seriesName: 'Occupancy',
        title: {
          text: 'Occupancy',
          style: {
            color: grey700,
            fontFamily: 'Poppins, sans-serif',
            fontSize: '18px',
            fontWeight: '400'
          }
        },
        labels: {
          formatter: (val) => (val ? `${Math.round(val)}%` : ''),
          style: {
            colors: grey700,
            fontFamily: 'Poppins, sans-serif',
            fontSize: '11px',
            fontWeight: 400
          }
        },
        opposite: true,
        min: 0,
        max: 105
      },
      {
        show: false,
        seriesName: 'Exposure',
        min: 0,
        max: 105
      }
    ],
    markers: {
      size: 6,
      strokeWidth: 0,
      fillOpacity: 0,
      hover: {
        size: 8
      }
    },
    tooltip: {
      enabled: true,
      shared: false,
      intersect: true,
      marker: { show: true },
      custom: function ({ series, seriesIndex, dataPointIndex, w }) {
        const seriesData = series[seriesIndex];
        const dataPoint = seriesData[dataPointIndex];

        if (dataPoint === 0) {
          return null;
        }
        const dataType =
          seriesIndex === 1
            ? 'budget'
            : seriesIndex === 0
            ? 'actual'
            : seriesIndex === 2
            ? 'forecast'
            : seriesIndex === 3
            ? 'occupancy'
            : 'exposure';

        const formattedDataPoint =
          seriesIndex === 3 || seriesIndex === 4
            ? `${dataPoint}%`
            : `${dataPoint}K`;

        return `
           <div class='${styles.chartTooltip}' data-type='${dataType}'>
            <div class='${styles.toolTipLabel}'>${monthLabels[dataPointIndex]}</div>
            <div>${dataType}: ${formattedDataPoint}</div> 
          </div>
          `;
      }
    },
    legend: {
      show: true,
      labels: {
        colors: [textColor, textColor, textColor, textColor, textColor]
      }
    },
    colors: [orange500, indigo500, grey700, purple300, green500]
  };

  return (
    <div id='chart-wrapper-forecast' style={{ width: '100%' }}>
      <Chart
        options={chartOptions}
        series={chartSeries}
        type={'line'}
        height={chartHeight}
      />
    </div>
  );
};
export default ForecastChart;
