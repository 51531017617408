import { useCallback, useEffect, useMemo } from 'react';

import Forecast from './Forecast';

import { useAppDispatch, useAppSelector } from '../../store/hooks';

import {
  getForecastData2,
  // getGoals,
  getPropertyData1,
  getPropertyData2,
  getPropertyData3,
  getPropertyData4,
  getPropertyData5,
  setPrptyDataLoading
} from '../../store/goals/goals-slice';

import { getForecastDataWith3mosAve } from '../../store/stats/stats-slice';

interface componentProps {
  propertyID: string;
}

const ForecastContainer = ({ propertyID }: componentProps): ReactNode => {
  const dispatch = useAppDispatch();

  const goals: any = useAppSelector((state) => state.goals.goals);

  const { renewalPct, tours }: any = useAppSelector(
    (state) => state.goals.propertyData
  );

  const hasDataForForecastReq = useMemo(() => {
    return !!propertyID && !!renewalPct && !!tours;
  }, [propertyID, renewalPct, tours]);
  // console.log(
  //   'ForecastContainer => hasDataForForecastReq:',
  //   hasDataForForecastReq
  // );

  const fetchPropertyData = useCallback(async () => {
    try {
      if (!propertyID) {
        return;
      }
      dispatch(setPrptyDataLoading(true));
      await dispatch(getPropertyData1(propertyID));
      await dispatch(getPropertyData2(propertyID));
      await dispatch(getPropertyData3(propertyID));
      await dispatch(getPropertyData4(propertyID));
      await dispatch(getPropertyData5(propertyID));
    } catch (error) {
      console.log('fetchPropertyData/error:', error);
    } finally {
      dispatch(setPrptyDataLoading(false));
    }
  }, [dispatch, propertyID]);

  // const fetchGoals = useCallback(async () => {
  //   try {
  //     await dispatch(getGoals(propertyID));
  //   } catch (error) {
  //     console.log('fetchGoals/error:', error);
  //   }
  // }, [dispatch, propertyID]);

  const fetchForecast2Data = useCallback(async () => {
    try {
      if (!propertyID) {
        return;
      }
      const data = {
        propertyID,
        ...goals
      };
      await dispatch(getForecastData2(data));
    } catch (error) {
      console.log('fetchForecast2Data/error:', error);
    }
  }, [dispatch, propertyID, goals]);

  const fetchForecastData = useCallback(async () => {
    try {
      if (!propertyID || !hasDataForForecastReq) {
        return;
      }

      await dispatch(getForecastDataWith3mosAve(propertyID));
    } catch (error) {
      console.log('fetchForecastData/error:', error);
    }
  }, [dispatch, hasDataForForecastReq, propertyID]);

  useEffect(() => {
    fetchPropertyData();
    // fetchForecast2Data();
    fetchForecastData();
  }, [fetchForecast2Data, fetchForecastData, fetchPropertyData]);

  return <Forecast propertyID={propertyID} />;
};

export default ForecastContainer;
