import { useLocation } from "react-router-dom";

import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { setProperty } from "../../store/properties/property-slice";

import styles from "./header.module.scss";

const Header = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const property = useAppSelector((state) => state.property);

  const handleClick = (value: number) => {
    dispatch(setProperty({ activeTab: value }));
  };

  const allTabs =
    property.tabs && property.tabs.length
      ? property.tabs
      : ["Pricing Tool", "Submark Detail", "Sample Page 3"];

  return (
    <div className={styles.header}>
      <div className={styles.tabs}>
        {location.pathname !== "/home" &&
          allTabs.map((item, index) => (
            <div
              key={index}
              className={`${styles.tabList} ${
                property.activeTab === index ? styles.active : ""
              }`}
              onClick={() => handleClick(index)}
            >
              <p>{item}</p>
            </div>
          ))}
      </div>
    </div>
  );
};

export default Header;
