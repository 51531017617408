import classes from "./submarket-card.module.scss";
import { useEffect, useState } from "react";

const SubMCard = ({ index, color, icon, size, data, name }: ISubMCard) => {
  const [horizontal, setHorizontal] = useState(false);

  const handleHorizontal = () => {
    if (window.innerWidth < 1300) {
      setHorizontal(true);
    } else {
      setHorizontal(false);
    }
  };

  useEffect(() => {
    window.addEventListener("resize", handleHorizontal);
    return () => window.removeEventListener("resize", handleHorizontal);
  });

  return (
    <div key={index} className={classes.card}>
      {icon && (
        <div
          className={classes.imgContainer}
          style={{
            background: `${color}39`,
          }}
        >
          <img src={icon} alt="icon" />
        </div>
      )}
      <div
        className={classes.contentContainer}
        style={{ textTransform: "capitalize" }}
      >
        {data.map((item: { Category: string; Value: any }, index) => {
          return (
            <>
              <div key={index} className={classes.content}>
                <p>{item.Category}</p>
                <span
                  className={classes.value}
                  style={{ color: color, fontSize: size === 33 ? 20 : 28 }}
                >
                  {/* {item.Value} */}
                  {name === "average rate" && <span>$</span>}
                  {isNaN(item.Value)
                    ? item.Value
                    : Math.round(item.Value).toLocaleString()}
                </span>
                {name === "average size" && (
                  <span className={classes.unit}>Sqft</span>
                )}
              </div>
              {index !== data.length - 1 &&
                (horizontal ? (
                  <div
                    style={{
                      height: "1px",
                      width: "100%",
                      background: "#636e72",
                    }}
                  ></div>
                ) : (
                  <div
                    style={{ height: 40, width: "1px", background: "#636e72" }}
                  ></div>
                ))}
            </>
          );
        })}
      </div>
    </div>
  );
};

export default SubMCard;
