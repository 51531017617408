import { useCallback, useEffect, useState } from 'react';

import DisplayRow from './DisplayRow';

import { useAppSelector } from '../../store/hooks';
import { initialMonthKeyValuePairs } from '../../store/stats/stats-utils';
import { getFormattedRoundedNearestK } from '../../utils/format';
import { monthKeys } from '../../utils/months';

const ERRRow = () => {
  const { effRentRev }: any = useAppSelector(
    (state) => state.goals.propertyData
  );
  const errForecast: any = useAppSelector(
    (state) => state.goals.forecastData.effRentRev
  );

  const [data, setData] = useState(initialMonthKeyValuePairs);
  // console.log('ERRRow => propertyData.effRentRev:', effRentRev);
  // console.log('getDisplayData => data:', data);

  const getDisplayData = useCallback(() => {
    const displayData = monthKeys.reduce((acc: any, curr: any) => {
      const forecastVal = errForecast[curr];

      if (!effRentRev) {
        acc[curr] = forecastVal;
        return acc;
      }

      const actual = effRentRev[curr];
      if (actual) {
        acc[curr] = actual;
      } else if (forecastVal) {
        acc[curr] = forecastVal;
      } else {
        acc[curr] = 0;
      }
      return acc;
    }, {});

    // console.log('ERRRow => displayData:', displayData);

    setData(displayData);
  }, [effRentRev, errForecast]);

  useEffect(() => {
    getDisplayData();
  }, [getDisplayData, effRentRev, errForecast]);

  return (
    <DisplayRow
      evenOrOdd={'even'}
      label='Effective Rent Revenue'
      data={data}
      formatter={getFormattedRoundedNearestK}
    />
  );
};

export default ERRRow;
