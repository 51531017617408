import { monthLabels } from '../../utils/months';

import styles from './goalsForm.module.scss';

const GoalsFormHeader = () => {
  return (
    <div className={styles.row} data-row={'header'}>
      <div className={styles.field} data-field={'wide'}>
        <div className={styles.rowLabel}>Name</div>
      </div>

      {monthLabels.map((month) => (
        <div key={month} className={styles.field}>
          <div className={styles.headerLabel}>{month}</div>
        </div>
      ))}
    </div>
  );
};

export default GoalsFormHeader;
