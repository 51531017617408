import { Modal, Table } from "antd";
import { useAppSelector } from "../../../store/hooks";

const TableModal = ({ open, setOpen, type, uid, data, setData }: ITableModal) => {
    const { loading } = useAppSelector(s => s.common)


    const renderRoleCols = [
        {
            title: "Role Id",
            dataIndex: "role_id",
            key: "role_id",
        },
        {
            title: "Role Name",
            dataIndex: "role_name",
            key: "role_name",
        },
        {
            title: "Role Description",
            dataIndex: "description",
            key: "description",
        },

    ];
    const renderProdCols = [
        {
            title: "Product Id",
            dataIndex: "product_id",
            key: "product_id",
        },
        {
            title: "Product",
            dataIndex: "product",
            key: "product",
        },
        {
            title: "Product Icon",
            dataIndex: "IconURL",
            key: "IconURL",
            render: (_: any, record: { IconURL: string }) => {
                return (
                    <div style={{ width: 23, height: 23 }} > <img width="100%" src={record.IconURL} alt="" /></div>
                )
            }
        },

    ];

    const columns = type === "role" ? renderRoleCols : renderProdCols


    return (
        <Modal
            open={open}
            title={type === "role" ? "User Roles" : "User Products"}
            footer={null}

            onCancel={() => {
                setOpen(false);
            }}
            onOk={() => { setOpen(false) }}
        >
            <Table
                columns={columns}
                rowKey={(recordd: any) => recordd.user_id}
                loading={loading}
                dataSource={data} />

        </Modal>
    )
}

export default TableModal