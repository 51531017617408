import { Spin } from "antd";
import "./style.scss";

export default function Loader(): ReactNode {
  return (
    <div className="loaderMain">
      <Spin size="large" />
    </div>
  );
}
