import { useCallback, useEffect, useState } from 'react';

import DisplayRow from './DisplayRow';

import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { updateMoveOutsForecast } from '../../store/goals/goals-slice';
import { initialMonthKeyValuePairs } from '../../store/stats/stats-utils';
import { defaultFormatter } from '../../utils/format';
import { monthKeys } from '../../utils/months';

interface componentProps {
  renewalPctGoal: number;
}

const MoveOutsRow = ({ renewalPctGoal }: componentProps): ReactNode => {
  const dispatch = useAppDispatch();

  const { moveOuts }: any = useAppSelector((state) => state.goals.propertyData);
  const moveOutsForecast: any = useAppSelector(
    (state) => state.goals.forecastData.moveOuts
  );
  const expiringLeases: any = useAppSelector(
    (state) => state.goals.propertyData.expiringLeases
  );

  const [data, setData] = useState(initialMonthKeyValuePairs);

  // console.log('MoveOutsRow => moveOutsForecast:', moveOutsForecast);
  // console.log('MoveOutsRow => propertyData.moveOuts:', moveOuts);
  // console.log('getDisplayData => data:', data);

  useEffect(() => {
    if (renewalPctGoal === 0) {
      return;
    }
    dispatch(updateMoveOutsForecast({ expiringLeases, renewalPctGoal }));
  }, [dispatch, expiringLeases, renewalPctGoal]);

  const getDisplayData = useCallback(() => {
    const displayData = monthKeys.reduce((acc: any, curr: any) => {
      const forecastVal = moveOutsForecast[curr];

      if (!moveOuts) {
        acc[curr] = forecastVal;
        return acc;
      }

      const actual = moveOuts[curr];
      if (actual) {
        acc[curr] = actual;
      } else if (forecastVal) {
        acc[curr] = forecastVal;
      } else {
        acc[curr] = 0;
      }
      return acc;
    }, {});

    setData(displayData);
  }, [moveOuts, moveOutsForecast]);

  useEffect(() => {
    getDisplayData();
  }, [getDisplayData, moveOuts, moveOutsForecast]);

  return (
    <DisplayRow
      evenOrOdd={'odd'}
      label='Move Outs'
      data={data}
      formatter={defaultFormatter}
    />
  );
};

export default MoveOutsRow;
