import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: [],
};

export const pricingSlice = createSlice({
  name: "Pricing",
  initialState,
  reducers: {
    setPricingData: (state, { payload }) => {
      state.data = payload;
    },
  },
});

export const { setPricingData } = pricingSlice.actions;
export default pricingSlice.reducer;
