import { useState, useEffect } from "react";
import { Select, Typography } from "antd";
import Styles from "./month-range.module.scss";

const monthOptions = [
  { label: "6", value: "6" },
  { label: "7", value: "7" },
  { label: "8", value: "8" },
  { label: "9", value: "9" },
  { label: "10", value: "10" },
  { label: "11", value: "11" },
  { label: "12", value: "12" },
  { label: "13", value: "13" },
  { label: "14", value: "14" },
  { label: "15", value: "15" },
  { label: "16", value: "16" },
  { label: "17", value: "17" },
  { label: "18", value: "18" },
];

const getFilteredOptions = (selectedFromMonth: string | null) => {
  if (!selectedFromMonth) return monthOptions;

  const fromMonthNumber = parseInt(selectedFromMonth, 10);

  return monthOptions.map((option) => ({
    ...option,
    disabled: parseInt(option.value, 10) < fromMonthNumber,
  }));
};

interface MonthRangeSelectorProps {
  loading: boolean;
  onFromMonthChange: (value: string | null) => void;
  onToMonthChange: (value: string | null) => void;
  defaultFromMonth?: string;
  defaultToMonth?: string;
}

const MonthRangeSelector = ({
  loading,
  onFromMonthChange,
  onToMonthChange,
  defaultFromMonth = "6",
  defaultToMonth = "18",
}: MonthRangeSelectorProps) => {
  const [fromMonth, setFromMonth] = useState(defaultFromMonth);
  const [toMonth, setToMonth] = useState(defaultToMonth);
  const [filteredToOptions, setFilteredToOptions] = useState(
    getFilteredOptions(defaultFromMonth)
  );

  useEffect(() => {
    setFilteredToOptions(getFilteredOptions(fromMonth));
    if (toMonth && parseInt(toMonth, 10) < parseInt(fromMonth, 10)) {
      setToMonth(fromMonth);
      onToMonthChange(fromMonth);
    }
  }, [fromMonth]);

  useEffect(() => {
    // Initial call to parent's onChange handlers with default values
    onFromMonthChange(defaultFromMonth);
    onToMonthChange(defaultToMonth);
  }, []);

  const handleFromMonthChange = (value: string | null) => {
    setFromMonth(value ?? defaultFromMonth);
    onFromMonthChange(value);
  };

  const handleToMonthChange = (value: string | null) => {
    setToMonth(value ?? defaultToMonth);
    onToMonthChange(value);
  };

  return (
    <>
      <div className={Styles.monthSelect}>
        <Typography.Text>From</Typography.Text>
        <Select
          placeholder="Month"
          onChange={handleFromMonthChange}
          disabled={loading}
          options={monthOptions}
          value={fromMonth}
          className={Styles.select}
        />
      </div>

      <div className={Styles.monthSelect}>
        <Typography.Text>To</Typography.Text>
        <Select
          placeholder="Month"
          onChange={handleToMonthChange}
          disabled={loading}
          options={filteredToOptions}
          value={toMonth}
          className={Styles.select}
        />
      </div>
    </>
  );
};

export default MonthRangeSelector;
