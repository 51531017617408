/* eslint-disable react-hooks/exhaustive-deps */
import { Form, Input, Modal } from "antd";
import { useEffect } from "react";
import { asyncUpdateFeeses } from "../../../../store/renewal/renewal-async";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import { setCommon } from "../../../../store/common/common-slice";

interface IStandardModal {
  open: boolean;
  setOpen: (value: boolean) => void;
  record: IObject | null;
  fetchData: () => void;
}

const StandardModal = ({
  open,
  setOpen,
  record,
  fetchData,
}: IStandardModal) => {
  const [form] = Form.useForm();
  const { loading } = useAppSelector((s) => s.common);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (record) {
      form.setFieldsValue({ ...record });
    }
  }, [record]);

  const onCreate = async (values: IObject) => {
    try {
      dispatch(setCommon({ loading: true }));

      const obj = { ...values, pk_id: record!.pk_id, type: "standard" };
      await dispatch(asyncUpdateFeeses(obj));
      await fetchData();
      dispatch(setCommon({ loading: false }));
      form.resetFields();
      setOpen(false);
    } catch (err: any) {
      console.log(err, " error");
      dispatch(setCommon({ loading: false }));
    }
  };

  return (
    <Modal
      open={open}
      // destroyOnClose
      okText={"Update"}
      okButtonProps={{ disabled: loading }}
      cancelText="Cancel"
      cancelButtonProps={{ disabled: loading }}
      onCancel={() => {
        // form.resetFields()
        setOpen(false);
      }}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            onCreate(values);
          })
          .catch((info) => console.log(info));
      }}
    >
      <Form
        form={form}
        layout="vertical"
        name="form_in_modal"
        preserve={false}
        disabled={loading}
      >
        <Form.Item
          name="standard_media_package"
          label="Standard Media Package"
          rules={[
            {
              required: true,
              message: "Please input the Standard Media Package",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="standard_pet_fee"
          label="Standard Pet Fee"
          rules={[
            {
              required: true,
              message: "Please input the Standard Pet Fee",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="standard_rubs_fee"
          label="Standard Rubs Fee"
          rules={[
            {
              required: true,
              message: "Please input the Standard Rubs Fee",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="standard_garage_income"
          label="Standard Garage Income"
          rules={[
            {
              required: true,
              message: "Please input the Standard Garage Income",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="standard_other_fees"
          label="Standard Other Fees"
          rules={[
            {
              required: true,
              message: "Please input the Standard Other Fees",
            },
          ]}
        >
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default StandardModal;
