export const monthLabels = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec'
];

export const monthKeys = [
  'jan',
  'feb',
  'mar',
  'apr',
  'may',
  'jun',
  'jul',
  'aug',
  'sep',
  'oct',
  'nov',
  'dec'
];

export const monthNames = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
];

export const monthNumberStringKeys = {
  jan: '1',
  feb: '2',
  mar: '3',
  apr: '4',
  may: '5',
  jun: '6',
  jul: '7',
  aug: '8',
  sep: '9',
  oct: '10',
  nov: '11',
  dec: '12'
};

export const defaultYearArray = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

export const getFieldWithMonths = (value) =>
  monthKeys.reduce((acc, month) => {
    acc[month] = value;
    return acc;
  }, {});

export const getRemainingMonthsInYear = (monthNum) => {
  const remainingMonths = monthLabels.slice(monthNum);
  console.log('remainingMonths: ', remainingMonths);

  return remainingMonths;
};

export const getMonthIdWithName = (monthName) => {
  const monthIdx = monthNames.indexOf(monthName);
  console.log('monthIdx: ', monthIdx);

  return monthKeys[monthIdx];
};

export const getMonthIdWithMonthNum = (monthNum) => {
  return monthKeys[monthNum - 1];
};

export const convertMonthKeyValuePairs = (data) => {
  const convertedData = {};

  monthKeys.forEach((month) => {
    const monthNumber = monthNumberStringKeys[month];
    convertedData[monthNumber] = data[month];
  });

  return convertedData;
};

export const convertMonthKeyValuePairsToArray = (data) => {
  return monthKeys.map((month) => data[month] || '-');
};
