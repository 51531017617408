import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  getRenewalDataByLeaseTypeService,
  getRenewalDataService,
  getUserPropertiesService,
  getUserRolesService,
  updateFeesesService,
  updateRenewalDataService,
  sendRenewalDataService,
} from "../../services/renewal.service";

export const asyncGetRenewalData = createAsyncThunk(
  "renewal",
  async (query: IQuery | any, { _, getState }: any) => {
    try {
      const keys = Object.keys(query).slice(3);
      const arr = keys.reduce((acc: { [key: string]: any }, key: string) => {
        if (query[key]) {
          acc.push({ label: key, value: query[key] });
          return acc;
        }
        return acc;
      }, []);
      const finalQuery = {
        page: query.page,
        pageSize: query.pageSize,
        oid: query.oid,
        values: JSON.stringify(arr),
      };
      const result: any = await getRenewalDataService(
        finalQuery,
        getState().user.token
      );
      return result;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }
);

export const asyncGetRenewalDataByLeaseType = createAsyncThunk(
  "renewal",
  async (query: IRenewalPlusFilterQuery | any, { _, getState }: any) => {
    try {
      const keys = Object.keys(query).slice(3);
      const arr = keys.reduce((acc: { [key: string]: any }, key: string) => {
        if (query[key]) {
          acc.push({ label: key, value: query[key] });
          return acc;
        }
        return acc;
      }, []);
      const finalQuery = {
        page: query.page,
        pageSize: query.pageSize,
        values: JSON.stringify(arr),
      };
      const result: any = await getRenewalDataByLeaseTypeService(
        finalQuery,
        getState().user.token
      );
      return result;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }
);

export const asyncGetUserProperties = createAsyncThunk(
  "renewal",
  async (oid: any, { _, getState }: any) => {
    try {
      const result: any = await getUserPropertiesService(
        oid,
        getState().user.token
      );
      return result;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }
);

export const asyncUpdateFeeses = createAsyncThunk(
  "renewal",
  async (body: IObject, { _, getState }: any) => {
    try {
      const result: any = await updateFeesesService(
        body,
        getState().user.token
      );
      return result;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }
);
export const asyncUpdateRenewalData = createAsyncThunk(
  "renewal",
  async (body: IObject, { _, getState }: any) => {
    try {
      const result: any = await updateRenewalDataService(
        body,
        getState().user.token
      );
      return result;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }
);

export const asyncGetUserRoles = createAsyncThunk(
  "renewal",
  async (oid: string, { _, getState }: any) => {
    try {
      const result: any = await getUserRolesService(oid, getState().user.token);
      return result;
    } catch (error: any) {
      console.log(error);
      throw new Error(error.message);
    }
  }
);

export const asyncSendRenewalLetter = createAsyncThunk(
  "send_renewel",
  async (body: IObject, { _, getState }: any) => {
    try {
      const result: any = await sendRenewalDataService(
        body,
        getState().user.token
      );
      return result;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }
);
