/* eslint-disable react-hooks/exhaustive-deps */
import { Col, Grid, Row, Select, Typography } from "antd";
import classes from "./styles.module.scss";
import { BarChart, Loader } from "../../../../components";
import { useEffect, useState } from "react";
import { useAppSelector } from "../../../../store/hooks";
import { sumOfArrays } from "../../../../utils/common";
import toast from "react-hot-toast";
import { getMonthlyFees } from "../../../../services/income.services";

interface ISummary {
  selectedProperty: string;
  setSelectedProperty: (val: string) => void;
  propertyOptions: Item[];
  activeLabel: string;
  setActiveLabel: (val: string) => void;
}

interface IChartData {
  labels: string[];
  mediaAutomationFee: number[];
  mediaAutomationFeeNotes: string[];
  garageFee: number[];
  carportUnderground: number[];
  storageFee: number[];
  petMonthlyFee: number[];
  valetWasteFee: number[];
  insuranceWaiverFee: number[];
  bikeStorage: number[];
  sums: number[];
  sumsLabels: string[];
  garageFeeNotes: string[];
  carportUndergroundNotes: string[];
  petMonthlyFeeNotes: string[];
  valetWasteFeeNotes: string[];
}

const { useBreakpoint } = Grid;
const MonthlyFees = ({
  selectedProperty,
  setSelectedProperty,
  propertyOptions,
  activeLabel,
  setActiveLabel,
}: ISummary) => {
  const [chartData, setChartData] = useState<IChartData>({
    labels: [],
    mediaAutomationFee: [],
    garageFee: [],
    carportUnderground: [],
    storageFee: [],
    petMonthlyFee: [],
    valetWasteFee: [],
    insuranceWaiverFee: [],
    bikeStorage: [],
    sums: [],
    sumsLabels: [],
    garageFeeNotes: [],
    carportUndergroundNotes: [],
    petMonthlyFeeNotes: [],
    valetWasteFeeNotes: [],
    mediaAutomationFeeNotes: [],
  });
  // const [propertyOptions, setPropertyOptions] = useState<Item[] | []>([]);
  // const [activeLabel, setActiveLabel] = useState<string>("");
  const [loader, setLoader] = useState<boolean>(true);
  const { lg } = useBreakpoint();

  const { token }: { [key: string]: any } = useAppSelector((s) => s.user);
  const barHeight = 90;
  const barHeightGroup = 60;

  // const getUserProperties = async () => {
  //   try {
  //     const userProperies = await dispatch(
  //       asyncGetUserProperties(`${user?.idTokenClaims?.oid}`)
  //     ).unwrap();
  //     setPropertyOptions(
  //       userProperies.data.map((obj: any) => ({
  //         label: obj.property_name,
  //         value: obj.ms_property_id,
  //       }))
  //     );

  //     setFilter(userProperies.data[0].ms_property_id);
  //     setActiveLabel(userProperies.data[0].property_name);
  //   } catch (error) {}
  // };

  const fetchMonthlyFees = async (selectedPropertyId: string) => {
    setLoader(true);
    try {
      const res: any = await getMonthlyFees(
        {
          propertyId: selectedPropertyId ?? "",
        },
        token
      );
      setLoader(false);

      const sortedData = {
        labels: res.data.map((item: any) => item.property_name || ""),
        mediaAutomationFee: res.data.map(
          (item: any) => item.MediaAutomationFee || 0
        ),
        mediaAutomationFeeNotes: res.data.map(
          (item: any) => item.MediaAutomationNotes || ""
        ),
        garageFee: res.data.map((item: any) => item.GarageFee || 0),
        garageFeeNotes: res.data.map((item: any) => item.GarageNotes || ""),
        carportUnderground: res.data.map(
          (item: any) => item.CarportUnderground || 0
        ),
        carportUndergroundNotes: res.data.map(
          (item: any) => item.CarportUndergroundNotes || ""
        ),
        storageFee: res.data.map((item: any) => item.StorageFee || 0),
        petMonthlyFee: res.data.map((item: any) => item.PetMonthlyFee || 0),
        petMonthlyFeeNotes: res.data.map(
          (item: any) => item.PetMonthlyNotes || ""
        ),
        valetWasteFee: res.data.map((item: any) => item.ValetWasteFee || 0),
        valetWasteFeeNotes: res.data.map(
          (item: any) => item.ValetWasteNotes || ""
        ),
        insuranceWaiverFee: res.data.map(
          (item: any) => item.InsuranceWaiverFee || 0
        ),

        bikeStorage: res.data.map((item: any) => item.BikeStorage || 0),

        sums: res.data.map((item: any) => item.TotalMonthlyFees || 0),
      };

      setChartData({
        ...sortedData,
        sumsLabels: sortedData.labels,
      });
    } catch (error) {
      setLoader(false);
      toast.error("Something went wrong during fetching monthly fees data");
    }
  };

  const fetchdMonthlyFees = async (selectedPropertyId: string) => {
    setLoader(true);
    try {
      const res: any = await getMonthlyFees(
        {
          propertyId: selectedPropertyId ?? "",
        },
        token
      );
      setLoader(false);

      const labels = res.data.map((item: any) => item.property_name || "");
      const mediaAutomationFee = res.data.map(
        (item: any) => item.MediaAutomationFee || 0
      );
      const mediaAutomationFeeNotes = res.data.map(
        (item: any) => item.MediaAutomationNotes || ""
      );
      const garageFee = res.data.map((item: any) => item.GarageFee || 0);
      const garageFeeNotes = res.data.map(
        (item: any) => item.GarageNotes || ""
      );
      const carportUnderground = res.data.map(
        (item: any) => item.CarportUnderground || 0
      );
      const carportUndergroundNotes = res.data.map(
        (item: any) => item.CarportUndergroundNotes || ""
      );
      const storageFee = res.data.map((item: any) => item.StorageFee || 0);
      const petMonthlyFee = res.data.map(
        (item: any) => item.PetMonthlyFee || 0
      );
      const petMonthlyFeeNotes = res.data.map(
        (item: any) => item.PetMonthlyNotes || ""
      );
      const valetWasteFee = res.data.map(
        (item: any) => item.ValetWasteFee || 0
      );
      const valetWasteFeeNotes = res.data.map(
        (item: any) => item.ValetWasteNotes || ""
      );
      const insuranceWaiverFee = res.data.map(
        (item: any) => item.InsuranceWaiverFee || 0
      );
      const bikeStorage = res.data.map((item: any) => item.BikeStorage || 0);

      const arrays = [
        [...mediaAutomationFee],
        [...garageFee],
        [...carportUnderground],
        [...storageFee],
        [...petMonthlyFee],
        [...valetWasteFee],
        [...insuranceWaiverFee],
        [...bikeStorage],
      ];
      const sums = sumOfArrays(arrays);
      const sumsLabels: string[] = [];
      const data = labels.map((label: string, index: number) => ({
        label,
        sum: sums[index],
      }));
      data.sort((a: { sum: number }, b: { sum: number }) => b.sum - a.sum);
      data.forEach((item: { label: any; sum: any }, index: number) => {
        sumsLabels[index] = item.label;
        sums[index] = item.sum;
      });

      setChartData({
        labels,
        mediaAutomationFee,
        mediaAutomationFeeNotes,
        garageFee,
        carportUnderground,
        storageFee,
        petMonthlyFee,
        valetWasteFee,
        insuranceWaiverFee,
        bikeStorage,
        sums: sums as any,
        sumsLabels: sumsLabels as any,
        garageFeeNotes,
        carportUndergroundNotes,
        petMonthlyFeeNotes,
        valetWasteFeeNotes,
      });
    } catch (error) {
      setLoader(false);
      toast.error("Something went wrong during fetching data");
    }
  };

  // useEffect(() => {
  //   if (user) {
  //     getUserProperties();
  //   }
  // }, [user]);

  useEffect(() => {
    if (selectedProperty) {
      fetchMonthlyFees(selectedProperty);
    }
  }, [selectedProperty]);

  if (loader) {
    return <Loader />;
  }

  return (
    <div style={{ maxWidth: "2000px" }}>
      {/* <Typography.Title level={2} style={{ margin: 0, marginBottom: 8 }}>
        Monthly Fees
      </Typography.Title> */}
      <Row gutter={[20, 20]}>
        <Col xs={24}>
          <div className={classes.container}>
            <div className={classes.flex}>
              <Typography.Text style={{ margin: 0 }}>Property</Typography.Text>
              <Select
                placeholder="Property"
                options={propertyOptions}
                value={selectedProperty}
                onChange={(val: string, option: any) => {
                  setSelectedProperty(val);
                  setActiveLabel(option.label);
                }}
                style={{ width: "200px" }}
              />
            </div>
          </div>
        </Col>
        <Col xs={24}>
          <div style={{ maxWidth: "2000px" }} className={classes.container}>
            <BarChart
              data={chartData.sums}
              labels={chartData.sumsLabels}
              title="Total Monthly Fees"
              height={barHeight * chartData.sums.length}
              tooltip={false}
              activeLabel={activeLabel}
              YTitle="Property"
              XTitle="Amount"
              group={false}
            />
          </div>
        </Col>
        <Col xs={24}>
          <div className={classes.container}>
            <span className={classes.yLabel}>Property</span>
            <span className={classes.xLabel}>Amount</span>
            <Typography.Title level={5} style={{ margin: 0, marginBottom: 4 }}>
              Monthly Fees by Fee Type
            </Typography.Title>

            <div className={classes.chart}>
              <div className={classes.chartRowContainer}>
                <div className={`${classes.chartColumn} `} style={{ flex: 2 }}>
                  <BarChart
                    group={true}
                    data={chartData.mediaAutomationFee}
                    notes={chartData.mediaAutomationFeeNotes}
                    labels={chartData.labels}
                    height={
                      barHeightGroup * chartData.mediaAutomationFee.length + 1
                    }
                    title="Media/Automation Fee"
                    xAxisLabels={false}
                    activeLabel={activeLabel}
                  />
                </div>

                <div className={`${classes.chartColumn} ${classes.borderLeft}`}>
                  <BarChart
                    group={true}
                    data={chartData.garageFee}
                    notes={chartData.garageFeeNotes}
                    labels={chartData.labels}
                    height={barHeightGroup * chartData.garageFee.length + 1}
                    title="Garage Fee"
                    xAxisLabels={false}
                    yAxisLabels={!lg}
                    activeLabel={activeLabel}
                  />
                </div>

                <div className={`${classes.chartColumn} ${classes.borderLeft}`}>
                  <BarChart
                    group={true}
                    data={chartData.carportUnderground}
                    notes={chartData.carportUndergroundNotes}
                    labels={chartData.labels}
                    height={
                      barHeightGroup * chartData.carportUnderground.length + 1
                    }
                    title="Carport/Underground"
                    xAxisLabels={false}
                    yAxisLabels={!lg}
                    activeLabel={activeLabel}
                  />
                </div>
                <div className={`${classes.chartColumn} ${classes.borderLeft}`}>
                  <BarChart
                    group={true}
                    data={chartData.storageFee}
                    labels={chartData.labels}
                    height={barHeightGroup * chartData.storageFee.length + 1}
                    title="Storage Fee"
                    xAxisLabels={false}
                    yAxisLabels={!lg}
                    tooltip={false}
                    activeLabel={activeLabel}
                  />
                </div>
              </div>
              <div className={classes.borderBottom} />
              <div className={classes.chartRowContainer}>
                <div
                  className={`${classes.chartColumn} ${classes.lastRow}`}
                  style={{ flex: 2 }}
                >
                  <BarChart
                    group={true}
                    data={chartData.petMonthlyFee}
                    notes={chartData.petMonthlyFeeNotes}
                    labels={chartData.labels}
                    height={barHeightGroup * chartData.petMonthlyFee.length + 1}
                    title="Pet Monthly Fee"
                    activeLabel={activeLabel}
                    xAxisLabels={false}
                  />
                </div>

                <div
                  className={`${classes.chartColumn} ${classes.lastRow} ${classes.borderLeft}`}
                >
                  <BarChart
                    group={true}
                    data={chartData.valetWasteFee}
                    notes={chartData.valetWasteFeeNotes}
                    labels={chartData.labels}
                    height={barHeightGroup * chartData.valetWasteFee.length + 1}
                    title="Valet Waste Fee"
                    yAxisLabels={!lg}
                    xAxisLabels={false}
                    activeLabel={activeLabel}
                  />
                </div>

                <div
                  className={`${classes.chartColumn} ${classes.lastRow} ${classes.borderLeft}`}
                >
                  <BarChart
                    group={true}
                    data={chartData.insuranceWaiverFee}
                    labels={chartData.labels}
                    height={
                      barHeightGroup * chartData.insuranceWaiverFee.length + 1
                    }
                    title="Insurance Waiver Fee"
                    yAxisLabels={!lg}
                    tooltip={false}
                    activeLabel={activeLabel}
                    xAxisLabels={false}
                  />
                </div>
                <div
                  className={`${classes.chartColumn} ${classes.lastRow} ${classes.borderLeft}`}
                >
                  <BarChart
                    group={true}
                    data={chartData.bikeStorage}
                    labels={chartData.labels}
                    height={barHeightGroup * chartData.bikeStorage.length + 1}
                    title="Bike Storage"
                    yAxisLabels={!lg}
                    tooltip={false}
                    activeLabel={activeLabel}
                    xAxisLabels={false}
                  />
                </div>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default MonthlyFees;
