import { AxiosError, AxiosResponse } from "axios";
import { httpRequest } from "./request";

function getReqUser(token: string): Promise<AxiosResponse | AxiosError> {
  return new Promise((resolve, reject) => {
    httpRequest("products", "get", token)
      .then((res: AxiosResponse) => {
        resolve(res);
      })
      .catch((err: AxiosError) => {
        reject(err);
      });
  });
}
function getUserProperty(token: string): Promise<AxiosResponse | AxiosError> {
  return new Promise((resolve, reject) => {
    httpRequest("properties", "get", token)
      .then((res: AxiosResponse) => {
        resolve(res);
      })
      .catch((err: AxiosError) => {
        reject(err);
      });
  });
}
function getUserAllProperty(
  token: string
): Promise<AxiosResponse | AxiosError> {
  return new Promise((resolve, reject) => {
    httpRequest(`properties/all`, "get", token)
      .then((res: AxiosResponse) => {
        resolve(res);
      })
      .catch((err: AxiosError) => {
        reject(err);
      });
  });
}

function getUserPropertyById(
  token: string
): Promise<AxiosResponse | AxiosError> {
  return new Promise((resolve, reject) => {
    httpRequest(`properties/prop`, "get", token)
      .then((res: AxiosResponse) => {
        resolve(res);
      })
      .catch((err: AxiosError) => {
        reject(err);
      });
  });
}
function getUserIntermediaryProperty(
  token: string
): Promise<AxiosResponse | AxiosError> {
  return new Promise((resolve, reject) => {
    httpRequest(`properties/intermediary`, "get", token)
      .then((res: AxiosResponse) => {
        resolve(res);
      })
      .catch((err: AxiosError) => {
        reject(err);
      });
  });
}
function getProducts(
  token: string
): Promise<AxiosResponse | AxiosError> {
  return new Promise((resolve, reject) => {
    httpRequest(`products/all`, "get", token)
      .then((res: AxiosResponse) => {
        resolve(res);
      })
      .catch((err: AxiosError) => {
        reject(err);
      });
  });
}
function getIndividualProperty(
  token: string,
  propertyId: string
): Promise<AxiosResponse | AxiosError> {
  return new Promise((resolve, reject) => {
    httpRequest(`properties/${propertyId}`, "get", token)
      .then((res: AxiosResponse) => {
        resolve(res);
      })
      .catch((err: AxiosError) => {
        reject(err);
      });
  });
}

export {
  getReqUser,
  getUserProperty,
  getIndividualProperty,
  getUserAllProperty,
  getUserPropertyById,
  getUserIntermediaryProperty,
  getProducts
};
