/* eslint-disable react-hooks/exhaustive-deps */
import { ChangeEvent, useEffect, useState } from "react";
import { Button, Input, Popconfirm, Table } from "antd";
import type { ColumnsType } from "antd/es/table";
import { MdDelete } from "react-icons/md";
import { AiFillEdit } from "react-icons/ai";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { asyncShowError, setCommon } from "../../store/common/common-slice";
import {
  asyncDeletePropProperties,
  asyncGetAllpropProperties,
  asyncGetRefrenceCompetitors,
  asyncGetRefrenceCompetitorsIds,
} from "../../store/properties/property-async";
import { GrAdd } from "react-icons/gr";
import PropertyModal from "../../screens/property/modals/PropertyModal";
import RefrenceModal from "../../screens/property/modals/RefrenceModal";
import classes from "./table.module.scss";

const AppTable = () => {
  const [open, setOpen] = useState(false);
  const [openRefComp, setOpenRefComp] = useState(false);
  const [data, setData] = useState<any>([]);
  const [newData, setNewData] = useState<any>([]);
  const [page, setPage] = useState<number | undefined>(1);
  const [pageSize, setPageSize] = useState<number | undefined>(10);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [refId, setRefId] = useState<string>("");
  const [refCompData, setRefCompData] = useState<string[]>([]);
  const dispatch = useAppDispatch();
  const { loading } = useAppSelector((s) => s.common);
  const [record, setRecord] = useState<IPropDataType | null>(null);
  const [ids, setIds] = useState<{ value: string }[]>([]);

  const { Search } = Input;

  useEffect(() => {
    window.addEventListener("error", (e) => {
      if (e.message === "ResizeObserver loop limit exceeded") {
        const resizeObserverErrDiv = document.getElementById(
          "webpack-dev-server-client-overlay-div"
        );
        const resizeObserverErr = document.getElementById(
          "webpack-dev-server-client-overlay"
        );
        if (resizeObserverErr) {
          resizeObserverErr.setAttribute("style", "display: none");
        }
        if (resizeObserverErrDiv) {
          resizeObserverErrDiv.setAttribute("style", "display: none");
        }
      }
    });
  }, []);

  useEffect(() => {
    callBackFunc();
  }, [page]);

  const callBackFunc = async () => {
    try {
      dispatch(setCommon({ loading: true }));
      const result = await dispatch(
        asyncGetAllpropProperties({ page, pageSize })
      ).unwrap();
      dispatch(setCommon({ loading: false }));
      setData(result);
      setNewData(result);
      setTotalCount(result[0].totalCount);
    } catch (err: any) {
      dispatch(asyncShowError(err.message));
      dispatch(setCommon({ loading: false }));
    }
  };

  const handleDelete = async (record: IPropDataType) => {
    try {
      await dispatch(asyncDeletePropProperties(record.dv_property_id));
      callBackFunc();
    } catch (err: any) {
      dispatch(asyncShowError(err.message));
      dispatch(setCommon({ loading: false }));
    }
  };

  const editHandler = (record: IPropDataType): void => {
    setRecord(record);
    setOpen(true);
  };
  const handleRefComp = async (rd: IPropDataType, id: string) => {
    try {
      setRefId(id);
      setRecord(rd);
      await refCompCallBackFunc(rd.ms_property_id);
      setOpenRefComp(true);
    } catch (err) {}
  };

  const refCompCallBackFunc = async (ms_property_id: string) => {
    try {
      if (ms_property_id !== "") {
        dispatch(setCommon({ loading: true }));
        const { payload } = await dispatch(
          asyncGetRefrenceCompetitors(ms_property_id)
        );
        const result = await dispatch(asyncGetRefrenceCompetitorsIds(""));
        dispatch(setCommon({ loading: false }));

        setRefCompData(payload);
        setIds(
          result.payload.map(
            (item: { final_property_id: string; property_name: string }) => ({
              value: item.final_property_id,
              label: item.property_name,
            })
          )
        );
      }
    } catch (err: any) {
      dispatch(asyncShowError(err.message));
      dispatch(setCommon({ loading: false }));
    }
  };

  const columns: ColumnsType<IPropDataType> = [
    {
      title: "Property Name",
      dataIndex: "property_name",
      key: "property_name",
    },
    {
      title: "Ms Property ID",
      dataIndex: "ms_property_id",
      key: "ms_property_id",
    },
    {
      title: "Reference Competitors",
      dataIndex: "reference_competitors",
      key: "reference_competitors",
      render: (_, record) => {
        return (
          <p
            onClick={() => handleRefComp(record, record.ms_property_id)}
            style={{
              color: "blue",
              textDecoration: "underline",
              cursor: "pointer",
            }}
          >
            View
          </p>
        );
      },
    },

    {
      title: "Unit Count",
      dataIndex: "unit_count",
      key: "unit_count",
    },
    {
      title: "Website URL",
      dataIndex: "website_url",
      key: "website_url",
    },
    {
      title: "Manager Email",
      dataIndex: "manager_email",
      key: "manager_email",
    },
    {
      title: "City",
      dataIndex: "city",
      key: "city",
    },
    {
      title: "State",
      dataIndex: "state",
      key: "state",
    },
    {
      title: "ZIP",
      dataIndex: "zip",
      key: "zip",
    },
    {
      title: "Apartments.com ID",
      dataIndex: "aptscom_id",
      key: "aptscom_id",
    },
    {
      title: "Client Name",
      dataIndex: "client_name",
      key: "client_name",
    },

    // {
    //   title: "Photo URL",
    //   dataIndex: "photo_url",
    //   key: "photo_url",
    //   render: (_, record) => {
    //     return (
    //       <div style={{ width: 100, height: 100 }} > <img width="100%" src={record.photo_url} alt="" /></div>
    //     )
    //   }
    // },

    {
      title: "Action",
      dataIndex: "Action",
      render: (_, record: IPropDataType) => (
        <>
          <AiFillEdit
            size={20}
            style={{ marginRight: "10px" }}
            onClick={() => editHandler(record)}
          />
          <Popconfirm
            title="Sure to delete?"
            onConfirm={() => handleDelete(record)}
          >
            <MdDelete size={20} />
          </Popconfirm>
        </>
      ),
    },
  ];

  const handleNameChange = (event: ChangeEvent<HTMLInputElement>): void => {
    if (event.target.value !== "") {
      const filterData = data.filter((elem: IPropDataType) =>
        elem.client_name
          ?.toLowerCase()
          .includes(event.target.value?.toLowerCase())
      );
      setNewData(filterData);
    } else {
      setNewData(data);
    }
  };

  return (
    <>
      <div className={classes.headContainer}>
        <Search
          style={{ width: "200px" }}
          placeholder="Search by name"
          onChange={handleNameChange}
        />
        <Button
          style={{
            display: "flex",
            alignItems: "center",
            gap: "1rem",
          }}
          type="dashed"
          onClick={() => {
            setRecord(null);
            setOpen(true);
          }}
        >
          <GrAdd color="#fff" fill="#fff" stroke="#fff" /> Add New Property
        </Button>
      </div>

      <div className={classes.container}>
        <Table
          loading={loading}
          columns={columns}
          // sticky={true}
          style={{ width: "100%" }}
          scroll={{ x: 1000 }}
          pagination={{
            total: totalCount,
            showSizeChanger: true,
            defaultCurrent: page,
            defaultPageSize: pageSize,
          }}
          rowClassName={(_: any, index: number) =>
            index % 2 === 0 ? classes.tableLight : classes.tableDark
          }
          dataSource={newData}
          onChange={(pagination) => {
            setPage(pagination.current);
            setPageSize(pagination.pageSize);
          }}
        />
      </div>

      <PropertyModal
        open={open}
        setOpen={setOpen}
        callBackFunc={callBackFunc}
        record={record}
        setRecord={setRecord}
      />
      <RefrenceModal
        open={openRefComp}
        setOpen={setOpenRefComp}
        record={record}
        setRecord={setRecord}
        ids={ids}
        setIds={setIds}
        callBackFunc={refCompCallBackFunc}
        refCompData={refCompData}
        setRefCompData={setRefCompData}
        ms_property_id={refId}
      />
    </>
  );
};

export default AppTable;
