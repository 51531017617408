import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    clients: [],
};

export const property = createSlice({
    name: "subscription",
    initialState,
    reducers: {
        setSubscription: (state, { payload }) => ({ ...state, ...payload }),
    },
});

export const { setSubscription } = property.actions;
export default property.reducer;
