import { Table, TableProps, Typography } from "antd";
import classes from "./styles.module.scss";
const { Text } = Typography;

interface IProps {
  data: any[];
}

export const TableArea = ({ data }: IProps) => {
  const columns: TableProps<any>["columns"] = [
    {
      title: "Property",
      dataIndex: "property_name",
      key: "property_name",
      width: "40%",
      sorter: (a, b) => a.property_name.localeCompare(b.property_name),
      ellipsis: true,
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Monthly Lease",
      dataIndex: "Mlv",
      key: "Mlv",
      align: "right",
      render: (value) => {
        return `$${new Intl.NumberFormat("en-US").format(value.toFixed(0))}`;
      },
      sorter: (a, b) => a.Mlv - b.Mlv,
    },
    {
      title: "MLV/SQFT",
      dataIndex: "MlvSqft",
      key: "MlvSqft",
      align: "right",
      render: (value) => {
        return `$${Number(value).toFixed(2)}`;
      },
      sorter: (a, b) => a.MlvSqft - b.MlvSqft,
    },
    {
      title: "sq.ft",
      dataIndex: "Sqft",
      key: "Sqft",
      align: "right",
      render: (value) => {
        return Math.round(value);
      },
      sorter: (a, b) => a.Sqft - b.Sqft,
    },
  ];

  return (
    <div style={{ minHeight: "100%" }}>
      <Table
        dataSource={data}
        size="large"
        columns={columns}
        scroll={{ x: 400, y: 570 }}
        rowClassName={(record, index) =>
          index % 3 === 0 ? classes.tableDark : classes.tableLight
        }
        className={classes.customTable}
        pagination={false}
        summary={(pageData) => {
          let totalMlv: number = 0;
          let totalMlvPerSq = 0;
          let totalSqft = 0;

          pageData.forEach(({ Mlv, MlvSqft, Sqft }) => {
            totalMlv += Number(Mlv) * 1;
            totalMlvPerSq += MlvSqft * 1;
            totalSqft += Sqft * 1;
          });

          return (
            <>
              {data?.length && (
                <Table.Summary.Row>
                  <Table.Summary.Cell index={0}>
                    <Text style={{ fontSize: 12 }} strong={true}>
                      Average
                    </Text>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell align="right" index={1}>
                    <Text style={{ fontSize: 12 }} strong={true}>
                      {/* @ts-ignore */}
                      {`$${new Intl.NumberFormat("en-US").format(
                        Number(
                          Number(totalMlv / Math.max(1, data.length)).toFixed(0)
                        )
                      )}`}
                    </Text>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell align="right" index={2}>
                    <Text style={{ fontSize: 12 }} strong={true}>
                      {/* @ts-ignore */}
                      {`$${Number(
                        Number(
                          totalMlvPerSq / Math.max(1, data.length)
                        ).toFixed(2)
                      )}`}
                    </Text>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell align="right" index={2}>
                    <Text style={{ fontSize: 12 }} strong={true}>
                      {/* @ts-ignore */}
                      {`${new Intl.NumberFormat("en-US").format(
                        Number(
                          Number(totalSqft / Math.max(1, data.length)).toFixed(
                            0
                          )
                        )
                      )}`}
                    </Text>
                  </Table.Summary.Cell>
                </Table.Summary.Row>
              )}
            </>
          );
        }}
      />
    </div>
  );
};
