import { useCallback, useEffect, useState } from 'react';

import DisplayRow from './DisplayRow';

import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { updateMoveInsForecast } from '../../store/goals/goals-slice';
import { initialMonthKeyValuePairs } from '../../store/stats/stats-utils';
import { defaultFormatter } from '../../utils/format';
import { monthKeys } from '../../utils/months';

interface componentProps {
  netLeasesPctGoal: number;
  tourGoals: object;
  tourConversionPctGoal: number;
}

const MoveInsRow = ({
  netLeasesPctGoal,
  tourGoals,
  tourConversionPctGoal
}: componentProps): ReactNode => {
  const dispatch = useAppDispatch();

  const { moveIns }: any = useAppSelector((state) => state.goals.propertyData);
  const moveInsForecast: any = useAppSelector(
    (state) => state.goals.forecastData.moveIns
  );
  // console.log('MoveInsRow => moveInsForecast:', moveInsForecast);

  const [data, setData] = useState(initialMonthKeyValuePairs);
  // console.log('MoveInsRow => propertyData.moveIns:', moveIns);
  // console.log('getDisplayData => data:', data);

  useEffect(() => {
    dispatch(
      updateMoveInsForecast({
        netLeasesPctGoal,
        tourGoals,
        tourConversionPctGoal
      })
    );
  }, [dispatch, netLeasesPctGoal, tourGoals, tourConversionPctGoal]);

  const getDisplayData = useCallback(() => {
    const displayData = monthKeys.reduce((acc: any, curr: any) => {
      const forecastVal = moveInsForecast[curr];

      if (!moveIns) {
        acc[curr] = forecastVal;
        return acc;
      }

      const actual = moveIns[curr];
      if (actual) {
        acc[curr] = actual;
      } else if (forecastVal) {
        acc[curr] = forecastVal;
      } else {
        acc[curr] = 0;
      }
      return acc;
    }, {});

    setData(displayData);
  }, [moveIns, moveInsForecast]);

  useEffect(() => {
    getDisplayData();
  }, [getDisplayData, moveIns, moveInsForecast]);

  return (
    <DisplayRow
      evenOrOdd={'even'}
      label='Net Leases'
      data={data}
      formatter={defaultFormatter}
    />
  );
};

export default MoveInsRow;
