import Chart from 'react-apexcharts';

import { indigo100, indigo500, purple300 } from './chartColors';
import styles from './stats.module.scss';

const StatBarChart = ({ data, name }) => {
  const goal = data.total;
  const actual = data.value;

  const maxValue = goal * 1.33;
  const xaxisMax = goal === 0 ? 1 : maxValue;
  const barValue = actual > xaxisMax ? xaxisMax : actual;

  const chartHeight = 66;

  const chartSeries = [
    {
      name: '',
      data: [
        {
          x: '',
          y: barValue,
          goals: [
            {
              name: 'Goal',
              value: goal,
              strokeHeight: 20,
              strokeWidth: 2,
              strokeDashArray: 2,
              strokeColor: purple300
            },
            { name: 'Actual', value: actual, strokeColor: 'transparent' }
          ]
        }
      ]
    }
  ];

  const chartOptions = {
    chart: {
      background: 'transparent',
      height: chartHeight,
      id: `-stat-bar-chart-${name}`,
      toolbar: {
        show: false
      },
      type: 'bar'
    },
    grid: {
      show: false,
      xaxis: {
        lines: {
          show: false
        }
      },
      yaxis: {
        lines: {
          show: false
        }
      },
      padding: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      }
    },
    plotOptions: {
      bar: {
        horizontal: true,
        colors: {
          backgroundBarColors: [indigo100],
          backgroundBarRadius: 5
        },
        barHeight: '100%',
        barWidth: '100%',
        borderRadius: 5,
        borderRadiusApplication: 'around'
      }
    },
    colors: [indigo500],
    dataLabels: {
      enabled: true,
      formatter: function (val, opts) {
        // returning actual value because data value is capped to xaxisMax
        return opts.w.config.series[0].data[0].goals[1].value;
      },
      style: {
        fontFamily: 'Poppins, sans-serif',
        fontSize: '14px',
        fontWeight: 300,
        colors: ['white']
      },
      textAnchor: 'start',
      background: {
        enabled: false,
        foreColor: '#fff',
        padding: 4,
        borderRadius: 2,
        borderWidth: 1,
        borderColor: '#ececec'
      }
    },
    markers: {
      size: 2,
      strokeWidth: 0,
      fillOpacity: 0
    },
    xaxis: {
      labels: {
        show: false
      },
      axisBorder: {
        show: false
      },
      axisTicks: {
        show: false
      },
      max: xaxisMax
    },
    yaxis: {
      labels: {
        show: false
      },
      axisBorder: {
        show: false
      }
    },
    tooltip: {
      enabled: false
    },
    states: {
      hover: {
        filter: {
          type: 'none',
          value: 0
        }
      }
    }
  };

  return (
    <div id={`stat-bar-chart-wrapper`} className={styles.statBarChart}>
      <div className={styles.statBarChartLabel}>
        <span>Goal:</span>
        <span>{goal}</span>
      </div>
      <Chart
        options={chartOptions}
        series={chartSeries}
        type={'bar'}
        height={chartHeight}
      />
    </div>
  );
};
export default StatBarChart;
