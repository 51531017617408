import { createAsyncThunk } from '@reduxjs/toolkit';

import {
  createGoalsService,
  getForecastDataService,
  getGoalsService,
  getGoalsDataService,
  getPropertyDataService,
  updateGoalsService
} from '../../services/goals.service';

import {
  parseForecastData,
  createForecastReqBody,
  createForecastReqData,
  parseGoals,
  parseGoalsCreate,
  parseGoalsData,
  parsePropertyData,
  parseGoalsUpdate
} from './goals-utils';

export const getGoals = createAsyncThunk(
  'goals/get',
  async (propertyID: string, { getState }: any) => {
    try {
      const result: any = await getGoalsService(
        propertyID,
        getState().user.token
      );
      // console.log('getGoalsData: ', result);

      const parsedData = parseGoals(result.data);
      // console.log('getGoalsData/parsedData', parsedData);

      return parsedData as any;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }
);

export const createGoals = createAsyncThunk(
  'goals/create',
  async (data: any, { dispatch, getState }: any) => {
    try {
      const body = parseGoalsCreate(data);

      const result: any = await createGoalsService(body, getState().user.token);
      console.log('createGoals/result: ', result);

      dispatch(getGoals(result.data?.PropertyID));

      return { success: result.status === 201 };
    } catch (error) {
      console.log('createGoals/error: ', error);
      return { success: false };
    }
  }
);

export const updateGoals = createAsyncThunk(
  'goals/update',
  async (data: any, { dispatch, getState }: any) => {
    try {
      const update = {
        pk_id: data.pk_id,
        manager_name: data.manager_name,
        goals: data.goals
      };
      const body = parseGoalsUpdate(update);

      const result: any = await updateGoalsService(body, getState().user.token);
      console.log('updateGoals/result: ', result);

      dispatch(getGoals(data.PropertyID));

      return { success: true };
    } catch (error) {
      console.log(error);
      return error;
    }
  }
);

export const getGoalsData = createAsyncThunk(
  'goals/data/get',
  async (propertyID: string, { getState }: any) => {
    try {
      const result: any = await getGoalsDataService(
        propertyID,
        getState().user.token
      );
      // console.log('getGoalsData: ', result);

      const parsedData = parseGoalsData(result.data);
      // console.log('getGoalsData/parsedData', parsedData);

      return parsedData as any;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }
);

export const getPropertyData1 = createAsyncThunk(
  'property/data1/get',
  async (propertyID: string, { getState }: any) => {
    try {
      const batch1: any = await getPropertyDataService(
        propertyID,
        '1',
        getState().user.token
      );
      // console.log('getPropertyData/batch1: ', batch1);

      const parsedData = parsePropertyData(batch1.data);
      // console.log('getPropertyData/parsedData', parsedData);

      return parsedData as any;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }
);

export const getPropertyData2 = createAsyncThunk(
  'property/data2/get',
  async (propertyID: string, { getState }: any) => {
    try {
      const batch2: any = await getPropertyDataService(
        propertyID,
        '2',
        getState().user.token
      );
      // console.log('getPropertyData/batch2: ', batch2);

      return batch2.data as any;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }
);

export const getPropertyData3 = createAsyncThunk(
  'property/data3/get',
  async (propertyID: string, { getState }: any) => {
    try {
      const batch3: any = await getPropertyDataService(
        propertyID,
        '3',
        getState().user.token
      );
      // console.log('getPropertyData/batch3: ', batch3);

      return batch3.data as any;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }
);

export const getPropertyData4 = createAsyncThunk(
  'property/data4/get',
  async (propertyID: string, { getState }: any) => {
    try {
      const batch4: any = await getPropertyDataService(
        propertyID,
        '4',
        getState().user.token
      );
      // console.log('getPropertyData/batch4: ', batch4);

      return batch4.data as any;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }
);

export const getPropertyData5 = createAsyncThunk(
  'property/data5/get',
  async (propertyID: string, { getState }: any) => {
    try {
      const batch5: any = await getPropertyDataService(
        propertyID,
        '5',
        getState().user.token
      );
      // console.log('getPropertyData/batch5: ', batch5);

      return batch5.data as any;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }
);

export const getForecastData = createAsyncThunk(
  'goals/forecast/get',
  async (data: any, { getState }: any) => {
    try {
      const body = createForecastReqBody(data);

      const result: any = await getForecastDataService(
        body,
        getState().user.token
      );
      // console.log('getForecastData/result: ', result);

      const parsedData = parseForecastData(result.data);
      // console.log('getForecastData/parsedData', parsedData);

      return parsedData as any;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }
);

export const getForecastData2 = createAsyncThunk(
  'goals/forecast2/get',
  async (data: any, { getState }: any) => {
    try {
      const body = createForecastReqData(data);
      // console.log('getForecastData2/body: ', body);

      const result: any = await getForecastDataService(
        body,
        getState().user.token
      );
      // console.log('getForecastData/result: ', result);

      const parsedData = parseForecastData(result.data);
      // console.log('getForecastData/parsedData', parsedData);

      return parsedData as any;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }
);
