import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isSidebarOpen: true
};

export const app = createSlice({
  name: 'app',
  initialState,
  reducers: {
    toggleSidebar: (state) => {
      state.isSidebarOpen = !state.isSidebarOpen;
    }
  }
});

export const { toggleSidebar } = app.actions;
export default app.reducer;
