import { DatePicker, Form, Input, Modal, Select, Typography } from 'antd'
import { useForm } from 'antd/es/form/Form'
import { useAppDispatch, useAppSelector } from '../../../store/hooks'
import { useState } from 'react'
import { asyncAddActiveSubscription, asyncGetClientProperties } from '../../../store/subscriptions/subscriptions-async'
import { setCommon } from '../../../store/common/common-slice'
import moment from 'moment'


const contractTypeArr = [
    {
        label: 'Fixed',
        value: 'fixed'
    },
    {
        label: 'MtM',
        value: 'mtm'
    }
]


const SubscriptionModal = ({ open, setOpen, products, setProducts, getSubscriptions }: { open: boolean, setOpen: (value: boolean) => void, products: { value: number, label: string }[], setProducts: (value: { value: number, label: string }[]) => void, getSubscriptions: () => Promise<void> }) => {
    const [properties, setProperties] = useState([])
    const [isContractFixed, setIsContractFixed] = useState(false)
    const [endDateError, setEndDateError] = useState(false)
    const [endDateErrorMessage, setEndDateErrorMessage] = useState('')
    const [endDateValue, setEndDateValue] = useState<any | undefined | null>('')
    const [form] = useForm()
    const { loading } = useAppSelector(s => s.common)
    const { clients }: any = useAppSelector(s => s.subscriptions)
    const dispatch = useAppDispatch()

    const onCreate = async (values: any) => {
        try {
            const start_date = form.getFieldValue('start_date');

            if ((!endDateValue || endDateValue === '' || !Object.keys(endDateValue).length) && isContractFixed) {
                setEndDateError(true)
                return
            }
            else setEndDateError(false)

            if (isContractFixed) {
                if (endDateValue?.isAfter(start_date, 'day')) {
                    setEndDateError(false)
                    setEndDateErrorMessage('')
                }
                else {
                    setEndDateError(true)
                    setEndDateErrorMessage('End date should be greater than the start date')
                    return
                }
            }

            const obj = {
                ...values,
                ContractType: values.ContractType.value,
                MonthlyRate: parseFloat(values.MonthlyRate),
                start_date: moment(values.start_date['$d']).format('YYYY-MM-DD'),
                end_date: isContractFixed ? moment(endDateValue['$d']).format('YYYY-MM-DD') : '',
            }
            dispatch(setCommon({ loading: true }))
            await dispatch(asyncAddActiveSubscription(obj))
            await getSubscriptions()
            await setEndDateValue('')
            await setEndDateError(false)
            await setIsContractFixed(false)
            await form.resetFields()
            setOpen(false)
        }
        catch (err) {
            dispatch(setCommon({ loading: false }))
        }
    }


    const handleClient = async (value: string) => {
        try {
            if (value) {
                dispatch(setCommon({ loading: true }))
                const get_client_name = clients?.find((cl: { client_id: string }) => cl.client_id === value).client_name
                const result = await dispatch(asyncGetClientProperties(get_client_name))
                if (result.payload.length) setProperties(result.payload.map((v: { ms_property_id: string, property_name: string }) => ({ value: v.ms_property_id, label: v.property_name })))
                else setProperties([])
                dispatch(setCommon({ loading: false }))

            }
        }
        catch (err) {
            dispatch(setCommon({ loading: false }))

        }
    }

    const handleContractType = (v: { value: string }) => {
        if (v?.value === 'fixed') setIsContractFixed(true)
        else setIsContractFixed(false)
    }

    const handleEndDate = (value: any) => {
        setEndDateValue(value)
        const start_date = form.getFieldValue('start_date');
        if (!value || !start_date || value.isAfter(start_date, 'day')) {
            setEndDateError(false)
            setEndDateErrorMessage('')
        }
        else {
            setEndDateError(true)
            setEndDateErrorMessage('End date should be greater than the start date')
        }
        // return Promise.reject(new Error('End date should be greater than the start date'));
    }


    return (
        <Modal
            open={open}
            destroyOnClose={true}
            title={"Create Subscription"}
            okText={"Create"}
            okButtonProps={{ disabled: loading }}
            cancelText="Cancel"
            cancelButtonProps={{ disabled: loading }}
            onCancel={async () => {
                dispatch(setCommon({ loading: true }))
                await getSubscriptions()
                await setEndDateValue('')
                await setEndDateError(false)
                await setIsContractFixed(false)
                await form.resetFields()
                dispatch(setCommon({ loading: false }))
                setOpen(false);
            }}
            onOk={() => {
                form
                    .validateFields()
                    .then((values) => {
                        onCreate(values);
                    })
                    .catch((info) => { });
            }}
        >
            <Form
                form={form}
                layout="vertical"
                preserve={false}
            >
                <Form.Item
                    name="client_name"
                    label="Client Name"
                    rules={[
                        {
                            required: true,
                            message: "Please input the client name",
                        },
                    ]}
                >
                    <Select
                        allowClear
                        options={clients?.map((item: { client_id: number, client_name: string }) => ({ value: item?.client_id, label: item?.client_name }))}
                        onChange={handleClient}
                    />
                </Form.Item>

                <Form.Item
                    name="property_id"
                    label="Property"
                    rules={[
                        {
                            required: true,
                            message: "Please select the property name",
                        },
                    ]}
                >
                    <Select
                        allowClear
                        disabled={form.getFieldValue('client_name') ? false : true}
                        options={properties}
                    />
                </Form.Item>

                <Form.Item
                    name="product_id"
                    label="Product"
                    rules={[
                        {
                            required: true,
                            message: "Please select the product name",
                        },
                    ]}
                >
                    <Select
                        allowClear
                        options={products}
                    />
                </Form.Item>

                <Form.Item
                    name="ContractType"
                    label="Contract"
                    rules={[
                        {
                            required: true,
                            message: "Please select the contract type",
                        },
                    ]}
                >
                    <Select
                        labelInValue
                        allowClear
                        onChange={handleContractType}
                        options={contractTypeArr.map(item => ({ value: item?.value, label: item?.label }))}
                    />

                </Form.Item>

                <Form.Item
                    name="MonthlyRate"
                    label="Monthly Rate"
                    rules={[
                        {
                            required: true,
                            message: "Please input the monthly rate",
                        },
                    ]}
                >
                    <Input
                        prefix="$"
                        style={{ width: '100%' }}
                        type='number'

                    // onChange={onChange}
                    />
                </Form.Item>


                <Form.Item
                    name="start_date"
                    label="Start Date"
                    rules={[
                        {
                            required: true,
                            message: "Please select the start date",
                        },
                    ]}
                >
                    <DatePicker style={{ width: '100%' }} disabledDate={(currentDate) => currentDate && currentDate < moment().startOf('day')}
                    />

                </Form.Item>

                <div style={{ marginBottom: "24px" }}>
                    <Typography style={{ marginBottom: '7px' }} >End Date</Typography>
                    <DatePicker
                        style={{ width: '100%', marginBottom: '7px' }}
                        value={endDateValue}
                        disabled={!isContractFixed}
                        disabledDate={(currentDate) => {
                            const start_date = moment(form.getFieldValue('start_date')).startOf('day');
                            return currentDate && (currentDate < start_date || currentDate === start_date);
                        }}
                        onChange={handleEndDate}
                    />
                    {endDateError && <p style={{ marginTop: "1px", color: '#ff4d4f' }}>{endDateErrorMessage.length ? endDateErrorMessage : 'Please select the End Date'}</p>}
                </div>

            </Form>
        </Modal >

    )
}

export default SubscriptionModal