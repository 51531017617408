import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useMsal } from '@azure/msal-react';

import { Button, Layout } from 'antd';

import Menu from './Menu';

import sidebarLogo from '../../assets/images/sidebarLogo.png';
import sidebarClosedLogo from '../../assets/images/sidebarClosedLogo.png';
import { IoMdArrowDropdown } from 'react-icons/io';
import { IoSettingsOutline, IoLogOutOutline } from 'react-icons/io5';
import { AiOutlineLeft } from 'react-icons/ai';

import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { toggleSidebar } from '../../store/app/app-slice';
import { logout } from '../../store/user/user-slice';

import styles from './sidebar.module.scss';

const { Sider } = Layout;

const Sidebar: React.FC = () => {
  const dispatch = useAppDispatch();
  const { pathname } = useLocation();
  const { instance } = useMsal();

  const [showUserMenu, setShowUserMenu] = useState(false);

  const { isSidebarOpen } = useAppSelector((state) => state.app);
  const { user }: any = useAppSelector((state) => state.user);

  const handleToggleSidebar = () => dispatch(toggleSidebar());

  const handleToggleUserMenu = () =>
    setShowUserMenu((showUserMenu) => !showUserMenu);
  const handleClick = async () => {
    dispatch(logout());
    await instance.logoutRedirect();
  };

  const logoStyles = isSidebarOpen ? styles.logo : styles.logoClosed;
  const logo = isSidebarOpen ? sidebarLogo : sidebarClosedLogo;
  // console.log('logo:', logo);

  return (
    <Sider
      collapsible
      collapsed={!isSidebarOpen}
      width={240}
      trigger={null}
      style={{ transition: 'all 0.3s ease-in-out' }}
    >
      <div className={`${styles.sidebar} ${isSidebarOpen ? styles.open : ''}`}>
        <div className={styles.sidebar_content}>
          <div className={logoStyles}>
            <img src={logo} alt='' />
          </div>

          <Menu isSidebarOpen={isSidebarOpen} pathname={pathname} />
        </div>
        <div className={styles.user}>
          <div className={styles.toggleContainer}>
            <Button
              id='toggle-sidebar'
              type='text'
              icon={
                <AiOutlineLeft
                  style={
                    isSidebarOpen
                      ? {
                          width: '1.25rem',
                          height: '1.25rem',
                          transform: 'rotate(0deg)',
                          transition: 'all 0.3s ease'
                        }
                      : {
                          width: '1.25rem',
                          height: '1.25rem',
                          transform: 'rotate(180deg)',
                          transition: 'all 0.3s ease'
                        }
                  }
                />
              }
              onClick={() => handleToggleSidebar()}
              style={{
                fontSize: '16px',
                width: 32,
                height: 32,
                color: '#fff'
              }}
            />
          </div>
          <div className={styles.divider}></div>
          <div className={styles.main} onClick={() => handleToggleUserMenu()}>
            <div>
              <div className={styles.avatarMain}>
                <div>
                  <div>{user?.name?.slice(0, 1)}</div>
                </div>
              </div>
              {/* {isSidebarOpen && ( */}
              <div className={styles.namesMain}>
                <h6 data-show={isSidebarOpen}>{user?.name}</h6>
              </div>
              {/* )} */}
            </div>
            {isSidebarOpen && (
              <div className={styles.dropArrowIcon}>
                <IoMdArrowDropdown />
              </div>
            )}

            {showUserMenu && (
              <div
                onMouseLeave={() => setShowUserMenu(false)}
                className={styles.popup_main}
                style={isSidebarOpen ? { left: '213px' } : { left: '5rem' }}
              >
                <p>
                  {' '}
                  <IoSettingsOutline /> <span>Profile</span>{' '}
                </p>
                <p onClick={handleClick}>
                  {' '}
                  <IoLogOutOutline />
                  <span>Logout</span>{' '}
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
    </Sider>
  );
};

export default Sidebar;
