/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";
import { Button, Select, Table } from "antd";
import { GrAdd } from "react-icons/gr";
import styles from "./style.module.scss";
import ClientModal from "./modals/ClientModal";
import SubscriptionModal from "./modals/SubscriptionModal";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { setCommon } from "../../store/common/common-slice";
import {
  asyncGetFilterSubscriptions,
  asyncGetProducts,
  asyncGetSubscriptions,
} from "../../store/subscriptions/subscriptions-async";
import { asyncGetAllClients } from "../../store/user/user-async";
import moment from "moment";
import { setSubscription } from "../../store/subscriptions/subscription-slice";

const columns = [
  {
    title: "Client",
    dataIndex: "client_name",
    key: "client_name",
  },
  {
    title: "Property",
    dataIndex: "property_name",
    key: "property_name",
  },
  {
    title: "Product",
    dataIndex: "product_name",
    key: "product_name",
  },
  {
    title: "Start Date",
    dataIndex: "start_date",
    key: "start_date",
    render: (value: any) => {
      return moment(value).format("DD/MM/YYYY");
    },
  },
  {
    title: "End Date",
    dataIndex: "end_date",
    key: "end_date",
    render: (value: any) => {
      return moment(value).format("DD/MM/YYYY");
    },
  },
  {
    title: "Price",
    dataIndex: "price",
    key: "price",
  },
  {
    title: "Contract Type",
    dataIndex: "contract_type",
    key: "contract_type",
  },
  {
    title: "Active",
    dataIndex: "active",
    key: "active",
  },
];

const activeInActiveArr = [
  {
    label: "Active",
    value: 1,
  },
  {
    label: "Inactive",
    value: 0,
  },
];

const Subscriptions = () => {
  const [openClient, setOpenClient] = useState<boolean>(false);
  const [openSubscription, setOpenSubscription] = useState<boolean>(false);
  const [data, setData] = useState([]);
  const [page, setPage] = useState<number | undefined>(1);
  const [pageSize, setPageSize] = useState<number | undefined>(10);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [clientsValue, setClientsValue] = useState<number[]>([]);
  const [propValue, setPropValue] = useState<number[]>([]);
  const [property, setProperty] = useState<{ value: string; label: string }[]>(
    []
  );
  const [products, setProducts] = useState<{ value: number; label: string }[]>(
    []
  );
  const { loading } = useAppSelector((s) => s.common);
  const { clients } = useAppSelector((s) => s.subscriptions);
  const dispatch = useAppDispatch();
  useEffect(() => {
    getSubscriptions();
  }, [page]);

  const getSubscriptions = async () => {
    try {
      dispatch(setCommon({ loading: true }));
      const getData: any = await dispatch(
        asyncGetSubscriptions({ page, pageSize })
      );
      setData(getData.payload);

      setTotalCount(getData.payload[0].totalCount);
      const { result } = await dispatch(asyncGetAllClients()).unwrap();
      dispatch(setSubscription({ clients: result }));
      dispatch(setCommon({ loading: false }));
      return getData.payload;
    } catch (err) {
      console.log(err, "my err");
      dispatch(setCommon({ loading: false }));
    }
  };

  const helperFilterFunc = async (
    obj: IHelperFilterSubscription["obj"],
    setState: IHelperFilterSubscription["setState"],
    ids: IHelperFilterSubscription["ids"],
    type: IHelperFilterSubscription["type"]
  ) => {
    try {
      if (type !== "active" && !ids.length) {
        if (type === "property") {
          if (!clientsValue.length) setProperty([]);
        } else setProperty([]);
        setState([]);
        if (type === "clients") {
          setPropValue([]);
          return getSubscriptions();
        }
      }
      if (setState) setState(ids);
      dispatch(setCommon({ loading: true }));
      const result = await dispatch(asyncGetFilterSubscriptions(obj));
      setData(result.payload);
      setTotalCount(result.payload[0].totalCount);
      dispatch(setCommon({ loading: false }));
      return result.payload;
    } catch (err) {
      dispatch(setCommon({ loading: false }));
    }
  };

  const handleClients = async (ids: number[]) => {
    try {
      const obj = {
        clientIds: JSON.stringify(ids),
        propIds: JSON.stringify([]),
        active: JSON.stringify(null),
        page,
        pageSize,
      };
      const result: any = await helperFilterFunc(
        obj,
        setClientsValue,
        ids,
        "clients"
      );
      if (ids.length) {
        const getProperties = result
          .filter((item: { client_name: string }) =>
            clients
              .map((cl: { client_name: string }) => cl.client_name)
              .includes(item.client_name)
          )
          .map((pr: { property_id: string; property_name: string }) => ({
            value: pr.property_id,
            label: pr.property_name,
          }));
        setProperty(getProperties);
      }
    } catch (err) {}
  };
  const handleProperty = async (ids: number[]) => {
    try {
      const obj = {
        clientIds: JSON.stringify(clientsValue),
        propIds: JSON.stringify(ids),
        active: JSON.stringify(null),
        page,
        pageSize,
      };
      await helperFilterFunc(obj, setPropValue, ids, "property");
    } catch (err) {}
  };

  const handleActInact = async (id: { value: number }) => {
    try {
      const obj = {
        clientIds: JSON.stringify(clientsValue),
        propIds: JSON.stringify(propValue),
        active: id?.value,
        page,
        pageSize,
      };
      await helperFilterFunc(obj, null, undefined, "active");
    } catch (err) {}
  };

  const handleAddNewSubscripton = async () => {
    try {
      dispatch(setCommon({ loading: true }));
      const result = await dispatch(asyncGetProducts());
      setProducts(
        result.payload.map((prd: { product_id: number; product: string }) => ({
          value: prd.product_id,
          label: prd.product,
        }))
      );
      dispatch(setCommon({ loading: false }));
      setOpenSubscription(true);
    } catch (err) {
      dispatch(setCommon({ loading: false }));
    }
  };

  return (
    <>
      <div className={styles.header}>
        <div className={styles.first_header_section}>
          <Select
            mode="multiple"
            placeholder="Client"
            value={clientsValue}
            allowClear
            className={styles.client_select}
            options={clients?.map(
              (item: { client_id: number; client_name: string }) => ({
                value: item?.client_id,
                label: item?.client_name,
              })
            )}
            onChange={handleClients}
          />
          <Button
            className={styles.client_btn}
            type="dashed"
            onClick={() => setOpenClient(true)}
          >
            <GrAdd color="#fff" fill="#fff" stroke="#fff" /> Add New Client
          </Button>
          <Select
            mode="multiple"
            placeholder="Property"
            allowClear
            className={styles.property_select}
            disabled={property.length ? false : true}
            options={property}
            value={propValue}
            onChange={handleProperty}
          />
          <Select
            labelInValue
            placeholder="Active"
            allowClear
            className={styles.active_select}
            onChange={handleActInact}
            options={activeInActiveArr.map((item) => ({
              value: item?.value,
              label: item?.label,
            }))}
          />
        </div>
        <div className={styles.second_header_section}>
          <Button
            // style={{ display: "flex", alignItems: "center", gap: "1rem" }}
            type="dashed"
            onClick={handleAddNewSubscripton}
          >
            <GrAdd color="#fff" fill="#fff" stroke="#fff" /> Add New
            Subscription
          </Button>
        </div>
      </div>

      <div className={styles.container}>
        <Table
          loading={loading}
          columns={columns}
          dataSource={data}
          scroll={{ x: 700 }}
          rowClassName={(_: any, index: number) =>
            index % 2 === 0 ? styles.tableLight : styles.tableDark
          }
          pagination={{
            total: totalCount,
            showSizeChanger: true,
            defaultCurrent: page,
            defaultPageSize: pageSize,
          }}
          onChange={(pagination) => {
            setPage(pagination.current);
            setPageSize(pagination.pageSize);
          }}
        />
      </div>
      <ClientModal open={openClient} setOpen={setOpenClient} />
      <SubscriptionModal
        open={openSubscription}
        setOpen={setOpenSubscription}
        products={products}
        setProducts={setProducts}
        getSubscriptions={getSubscriptions}
      />
    </>
  );
};

export default Subscriptions;
