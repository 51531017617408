import { Spin } from 'antd';

import styles from './stats.module.scss';

const StatLoader = ({ isLoading }) =>
  isLoading ? (
    <div className={styles.statLoader}>
      <Spin />
    </div>
  ) : null;

export default StatLoader;
