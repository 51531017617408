import { LogLevel } from "@azure/msal-browser";
import {config} from "../../utils/config"

const b2cPolicies = {
    names: {
        SignIn: config.REACT_APP_POLICY_NAME,
        // forgotPassword: "<Forgot Password Name - in the form of B2C_1_xxx>",
        // editProfile: "<Edit Profile Name - in the form of B2C_1_xxx>"
    },
    authorities: {
        SignIn: {
            authority: `https://${config.REACT_APP_DOMAIN_NAME}.b2clogin.com/${config.REACT_APP_DOMAIN_NAME}.onmicrosoft.com/${config.REACT_APP_POLICY_NAME}`,
        },
        // forgotPassword: {
        //     authority: "https://<AAD B2C Name>.b2clogin.com/<AAD B2C Name>.onmicrosoft.com/<Forgot Password Name - in the form of B2C_1_xxx>",
        // },
        // editProfile: {
        //     authority: "https://<AAD B2C Name>.b2clogin.com/<AAD B2C Name>.onmicrosoft.com/<Edit Profile Name - in the form of B2C_1_xxx>"
        // }
    },
    authorityDomain: `${config.REACT_APP_DOMAIN_NAME}.b2clogin.com`
}


export const msalConfig = {
    auth: {
        clientId: config.REACT_APP_CLIENT_ID || '', 
        authority: b2cPolicies.authorities.SignIn.authority, 
        // authority: `https://liveluxb2c.b2clogin.com/liveluxb2c.onmicrosoft.com/B2C_1_AzureSignUpSignIn`,
        knownAuthorities: [b2cPolicies.authorityDomain], 
        redirectUri: config.REACT_APP_REDIRECT_URL,
        postLogoutRedirectUri: config.REACT_APP_LOGOUT_REDIRECT_URL,
        navigateToLoginRequestUrl: false, 
    },
    cache: {
        cacheLocation: "sessionStorage",
        storeAuthStateInCookie: false,
    },
    system: {	
        loggerOptions: {	
            loggerCallback: (level: any, message: any, containsPii: any) => {	
                if (containsPii) {		
                    return;		
                }		
                switch (level) {		
                    case LogLevel.Error:		
                        console.error(message);		
                        return;		
                    case LogLevel.Info:		
                        console.info(message);		
                        return;		
                    case LogLevel.Verbose:		
                        console.debug(message);		
                        return;		
                    case LogLevel.Warning:		
                        console.warn(message);		
                        return;
                    default:
                        return;
                }	
            }	
        }	
    }
};

export const loginRequest = {
    scopes: []
};
