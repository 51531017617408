export const grey700 = '#8390a2';
export const indigo100 = '#eceffe';
export const indigo500 = '#4361f9';
export const orange100 = '#fff8ed';
export const orange500 = '#ffb84c';
export const purple300 = '#A872FC';
export const green500 = '#00E396';
export const textColor = '#616b79';
/**
 * Other colors used in charts via stats.module.scss:
 * GPR card "Variance" field
 * positiveVariance green: #70db70
 * negativeVariance red: #ff8080
 */
