import { createAsyncThunk } from "@reduxjs/toolkit";
import { PropertyApi, createOrUpdateIntermediaryService, deleteIntermediaryService, deletePropPropertiesService, getAllpropPropertiesService, getRefrenceCompetitorsIdsService, getRefrenceCompetitorsService, insertInPropPropertiesService, updateInPropPropertiesService } from "../../services/property.service";

export const Property = createAsyncThunk(
  "user/properties",
  async (
    data: { type: string; method: string; propertyId?: string; body: any },
    { dispatch, getState }: any
  ) => {
    try {
      const result: any = await PropertyApi(
        data.type,
        getState().user.token,
        data.method,
        data?.propertyId,
        data?.body,
      );
      //   dispatch(setUser({ userProperty: result.data }));
      return result.data;
    } catch (error) {
      console.log(error);
      return { success: false };
    }
  }
);
export const asyncGetAllpropProperties = createAsyncThunk(
  "user/all/prop/properties",
  async (query: { page: number | undefined, pageSize: number | undefined }, { _, getState }: any) => {
    try {
      const result: any = await getAllpropPropertiesService(query, getState().user.token)
      return result.data;
    } catch (error) {
      console.log(error);
      throw error
    }
  }
);
export const asyncInsertInPropProperties = createAsyncThunk(
  "user/prop/properties",
  async (body: { [key: string]: number | string; }, { _, getState }: any) => {
    try {
      const result: any = await insertInPropPropertiesService(body, getState().user.token)
      return result.data;
    } catch (error:any) {
      return {error: error?.response?.data?.message}
    }
  }
)
export const asyncUpdateInPropProperties = createAsyncThunk(
  "user/prop/properties",
  async (body: { [key: string]: number | string; }, { _, getState }: any) => {
    try {
      const result: any = await updateInPropPropertiesService(body, getState().user.token)
      return result.data;
    } catch (error) {
      console.log(error);
      throw error
    }
  }
);
export const asyncDeletePropProperties = createAsyncThunk(
  "user/prop/properties",
  async (id: string, { _, getState }: any) => {
    try {
      const result: any = await deletePropPropertiesService(id, getState().user.token)
      return result.data;
    } catch (error) {
      console.log(error);
      throw error
    }
  }
);
export const asyncGetRefrenceCompetitors = createAsyncThunk(
  "user/prop/properties",
  async (id: string, { _, getState }: any) => {
    try {
      const result: any = await getRefrenceCompetitorsService(id, getState().user.token)
      return result.data;
    } catch (error) {
      console.log(error);
      throw error
    }
  }
);
export const asyncGetRefrenceCompetitorsIds = createAsyncThunk(
  "user/prop/properties",
  async (keyword: string, { _, getState }: any) => {
    try {
      const result: any = await getRefrenceCompetitorsIdsService(keyword, getState().user.token)
      return result.data;
    } catch (error) {
      console.log(error);
      throw error
    }
  }
);
export const asyncCreateOrUpdateIntermediary = createAsyncThunk(
  "user/prop/properties",
  async (body: any, { _, getState }: any) => {
    try {
      const result: any = await createOrUpdateIntermediaryService(body, getState().user.token)
      return result;
    } catch (error) {
      console.log(error);
      throw error
    }
  }
);

export const asyncDeleteIntermediary = createAsyncThunk(
  "user/prop/properties",
  async (id: string, { _, getState }: any) => {
    try {
      const result: any = await deleteIntermediaryService(id, getState().user.token)
      return result;
    } catch (error) {
      console.log(error);
      throw error
    }
  }
);
