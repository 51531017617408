import { Table } from "antd";
import classes from "./styles.module.scss";

interface IProps {
  data: any[];
  columns: any[];
}

export const TableArea = ({ data, columns }: IProps) => {
  return (
    <div>
      <Table
        dataSource={data}
        size="large"
        columns={columns}
        scroll={{ x: 600, y: 400 }}
        rowClassName={(record, index) =>
          index % 3 === 0 ? classes.tableDark : classes.tableLight
        }
        className={classes.customTable}
        pagination={false}
      />
    </div>
  );
};
