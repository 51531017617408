import axios, { AxiosError, AxiosResponse } from "axios";
import { httpRequest } from "./request";

// Define the function to make the API call
// export const getPricingData = async (id?: string): Promise<IPricingData[]> => {
//   try {
//     // Make a GET request to the provided endpoint
//     const response: AxiosResponse<IPricingData[]> = await axios.get<
//       IPricingData[]
//     >(
//       `https://optipricer-flaskapp.azurewebsites.net/pricing/${id}?APIKey=12345`
//     );

//     // Return the data from the response
//     return response.data;
//   } catch (error) {
//     // Handle errors here
//     throw new Error("Failed to fetch pricing data");
//   }
// };

function getPricingData(
  { propertyId, bedrooms }: { propertyId: string; bedrooms?: string },
  token: string
): Promise<AxiosResponse | AxiosError> {
  return new Promise((resolve, reject) => {
    httpRequest("properties/optipricer", "get", token, null, true, {
      propertyId,
      bedrooms,
    })
      .then((res: AxiosResponse) => {
        resolve(res);
      })
      .catch((err: AxiosError) => {
        reject(err);
      });
  });
}

export const postPricingData = async (body: any): Promise<IPricingData[]> => {
  try {
    // Make a GET request to the provided endpoint
    const response: AxiosResponse<IPricingData[]> = await axios.post<
      IPricingData[]
    >(
      "https://optipricer-flaskapp.azurewebsites.net/post_pricing?APIKey=12345",
      body
    );

    // Return the data from the response
    return response.data;
  } catch (error) {
    // Handle errors here
    throw new Error("Failed to fetch pricing data");
  }
};

export { getPricingData };
