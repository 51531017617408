import ConcessionLossRow from './ConcessionLossRow';
import ERRRow from './ERRRow';
import GMRRow from './GMRRow';
import GoalsFormHeader from './GoalsFormHeader';
import GPRRow from './GPRRow';
import LossToLeaseRow from './LossToLeaseRow';
import VacancyLossRow from './VacancyLossRow';

import styles from './goalsForm.module.scss';
import './goalsForm.css';

const MetricsFin = (): ReactNode => {
  return (
    <>
      <div className={styles.goalsForm}>
        <GoalsFormHeader />

        <GMRRow />

        <LossToLeaseRow />

        <GPRRow />

        <VacancyLossRow />

        <ConcessionLossRow />

        <ERRRow />

        <div className={styles.row} data-row={'bottom'}>
          <div style={{ height: '2rem', width: '100%' }} />
        </div>
      </div>
    </>
  );
};

export default MetricsFin;
