import React, { useEffect, useState } from "react";
import Protection from "../../protection";

function Product5(): ReactNode {
  const [user, setUser] = useState<string>("");
  useEffect(() => {
    if (localStorage.getItem("user") !== null) {
      setUser(JSON.parse(localStorage.getItem("user") as string));
    }
  }, []);
  return (
    <Protection
      protection={["rw_property_manager"]}
      userRole={user === "test@uzair.com" && ["rw_property_manager"]}
    >
      <h3>Product5</h3>
    </Protection>
  );
}

export default Product5;
