import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  user: {},
  token: null,
  userProducts: [],
  userProperty: [],
  individualProperty: [],
  customerPropertiesData: [],
  propIntermediaryData: [],
  allPropertiesData: [],
  finalMarketSurveyData: [],
  roles:[]
};

export const user = createSlice({
  name: "Auth",
  initialState,
  reducers: {
    logout: (state) => {
      state = initialState
    },
    setUser: (state, { payload }) => ({ ...state, ...payload })
  },
});

export const { logout, setUser } = user.actions;
export default user.reducer;
