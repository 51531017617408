import React from 'react';
import {  Modal } from 'antd';



const ConfirmModal: React.FC<any> = (props:any) => {

  return (
       <>
      <Modal
        title="Confirmation"
        open={props.open}
        onOk={()=>props.send()}
        onCancel={()=>props.cancel(false)}
        okText="Send"
        cancelText="Cancel"
      >
        <b>Are you sure you want to send renewals to the selected residents? This cannot be undone.</b>
      </Modal>
    </>
  );
};

export default ConfirmModal;