import { Button, Popconfirm, Table } from "antd";
import { ColumnsType } from "antd/es/table";
import { useState } from "react";
import { AiFillEdit } from "react-icons/ai";
import { GrAdd } from "react-icons/gr";
import { MdDelete } from "react-icons/md";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import {
  asyncDeleteUserById,
  asyncGetAllRolesAndProducts,
  getAllProductsOfUser,
  getAllRolesOfUser,
} from "../../store/user/user-async";
import { asyncShowError, setCommon } from "../../store/common/common-slice";
import UserModal from "./modals/UserModal";
import TableModal from "./modals/TableModal";
import classes from "./user.module.scss";
import { Loader } from "../../components";

function User({
  userData,
  callBackFunc,
  setPage,
  setPageSize,
  totalCount,
  page,
  pageSize,
}: IUsers): ReactNode {
  const [tableType, setTableType] = useState<string>("");
  const dispatch = useAppDispatch();
  const { loading } = useAppSelector((s) => s.common);
  const [userId, setUserId] = useState<string>("");
  const [open, setOpen] = useState<boolean>(false);
  const [tableShow, setTableShow] = useState<boolean>(false);
  const [tableNestedData, setTableNestedData] = useState([]);
  const [record, setRecord] = useState(null);

  const columns: ColumnsType<IUserDataType> = [
    {
      title: "User Id",
      dataIndex: "user_id",
      key: "user_id",
    },
    {
      title: "First Name",
      dataIndex: "first_name",
      key: "first_name",
    },
    {
      title: "Last Name",
      dataIndex: "last_name",
      key: "last_name",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Phones",
      dataIndex: "phone",
      key: "phone",
    },
    {
      title: "Roles",
      dataIndex: "role_name",
      key: "role_name",
      render: (_, record) => (
        <p
          style={{
            cursor: "pointer",
            color: "blue",
            textDecoration: "underline",
          }}
          onClick={() => {
            handleNestedRowsData("role", record);
          }}
        >
          View
        </p>
      ),
    },
    {
      title: "Products",
      dataIndex: "product",
      key: "product",
      render: (_, record) => (
        <p
          style={{
            cursor: "pointer",
            color: "blue",
            textDecoration: "underline",
          }}
          onClick={() => handleNestedRowsData("products", record)}
        >
          View
        </p>
      ),
    },

    {
      title: "Action",
      dataIndex: "Action",
      render: (_, record: any, index: number) => (
        <>
          <AiFillEdit
            size={20}
            style={{ marginRight: "10px", cursor: "pointer" }}
            onClick={() => handleEdit(record)}
          />

          <Popconfirm
            title="Sure to delete?"
            onConfirm={() => handleDelete(record)}
          >
            <MdDelete size={20} style={{ cursor: "pointer" }} />
          </Popconfirm>
        </>
      ),
    },
  ];

  const handleNestedRowsData = async (
    type: string,
    record: { user_id: string }
  ) => {
    try {
      setTableType(type);
      setTableShow(true);
      setUserId(record.user_id);
      if (type === "role") {
        dispatch(setCommon({ loading: true }));
        const { result } = await dispatch(
          getAllRolesOfUser(record.user_id)
        ).unwrap();
        dispatch(setCommon({ loading: false }));
        setTableNestedData(result);
      } else {
        dispatch(setCommon({ loading: true }));
        const { result } = await dispatch(
          getAllProductsOfUser(record.user_id)
        ).unwrap();
        dispatch(setCommon({ loading: false }));
        setTableNestedData(result);
      }
    } catch (err: any) {
      dispatch(asyncShowError(err.message));
      dispatch(setCommon({ loading: false }));
    }
  };

  const handleEdit = async (record: any) => {
    try {
      dispatch(setCommon({ loading: true }));
      const { result } = await dispatch(
        asyncGetAllRolesAndProducts({
          clientId: record.client_id,
          userId: record.user_id,
        })
      ).unwrap();
      dispatch(setCommon({ loading: false }));
      const prodcutsSelected = result.productsData.map(
        (item: { product_id: number }) => item.product_id
      );
      const obj = {
        ...record,
        clients: result.clientData,
        roles: result.rolesData,
        products: result.productsData,
        prodcutsSelected,
      };
      setRecord(obj);
      setOpen(true);
    } catch (err: any) {
      dispatch(asyncShowError(err.message));
      dispatch(setCommon({ loading: false }));
    }
  };

  const handleDelete = async (record: any) => {
    try {
      await dispatch(
        asyncDeleteUserById({ userId: record.user_id, objId: record.object_id })
      );
      callBackFunc();
    } catch (err: any) {
      dispatch(asyncShowError(err.message));
      dispatch(setCommon({ loading: false }));
    }
  };

  const tableLoading = {
    spinning: loading,
    indicator: <Loader />,
  };

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          marginBottom: "2rem",
        }}
      >
        <Button
          style={{ display: "flex", alignItems: "center", gap: "1rem" }}
          type="dashed"
          onClick={() => {
            setRecord(null);
            setOpen(true);
          }}
        >
          <GrAdd color="#fff" fill="#fff" stroke="#fff" /> Add New User
        </Button>
      </div>

      <div className={classes.container}>
        <Table
          columns={columns}
          rowKey={(recordd: any) => recordd.user_id}
          loading={tableLoading}
          dataSource={userData}
          scroll={{ x: 700 }}
          rowClassName={(_: any, index: number) =>
            index % 2 === 0 ? classes.tableLight : classes.tableDark
          }
          pagination={{
            total: totalCount,
            showSizeChanger: true,
            defaultCurrent: page,
            defaultPageSize: pageSize,
          }}
          onChange={(pagination) => {
            setPage(pagination.current);
            setPageSize(pagination.pageSize);
          }}
        />
      </div>

      {open && (
        <UserModal
          open={open}
          setOpen={setOpen}
          record={record}
          setRecord={setRecord}
          callBackFunc={callBackFunc}
        />
      )}
      {tableShow && (
        <TableModal
          open={tableShow}
          setOpen={setTableShow}
          type={tableType}
          uid={userId}
          data={tableNestedData}
          setData={setTableNestedData}
        />
      )}
    </div>
  );
}

export default User;
