import { createSlice } from '@reduxjs/toolkit';

import {
  getForecastData,
  getForecastData2,
  createGoals,
  getGoals,
  getGoalsData,
  getPropertyData1,
  getPropertyData2,
  getPropertyData3,
  getPropertyData4,
  getPropertyData5,
  updateGoals
} from './goals-async';
import {
  getApplicationsForecast,
  getMoveInsForecast,
  getMoveOutsForecast
} from './goals-utils';

import { emptyGoalsData } from '../../utils/data';

const goalsDefaultState = {
  propertyID: '',
  pk_id: '',
  goals: emptyGoalsData
};

const forecastDataDefaultState = {
  // financial metrics
  concessionLoss: {},
  effRentRev: {},
  gmr: {},
  gpr: {},
  lossToLease: {},
  vacancyLoss: {},
  // operational metrics
  applications: {},
  moveIns: {},
  moveOuts: {}
};

const propertyDataDefaultState = {
  applications: {},
  concessionLoss: {},
  expiringLeases: {},
  errBudget: {},
  effRentRev: {},
  exposure: {},
  gmr: {},
  gpr: {},
  lossToLease: {},
  moveIns: {},
  moveOuts: {},
  netLeasesPct: {},
  occupancyCurr: {},
  occupancyPct: {},
  vacancyLoss: {}
};

const initialState = {
  ...goalsDefaultState,
  forecastData: forecastDataDefaultState,
  isForecastDataLoading: false,
  isLoading: false,
  isPrptyDataLoading: false,
  isUpdating: false,
  propertyData: propertyDataDefaultState
};

export const goalsSlice = createSlice({
  name: 'goals',
  initialState,
  reducers: {
    setPrptyDataLoading: (state, action) => {
      state.isPrptyDataLoading = action.payload;
    },
    updateApplicationsForecast: (state, action) => {
      const { payload } = action;
      const applications = getApplicationsForecast(payload);
      state.forecastData.applications = applications;
    },
    updateMoveInsForecast: (state, action) => {
      const { payload } = action;
      const moveIns = getMoveInsForecast(payload);
      state.forecastData.moveIns = moveIns;
    },
    updateMoveOutsForecast: (state, action) => {
      const { payload } = action;
      const moveOuts = getMoveOutsForecast(payload);
      state.forecastData.moveOuts = moveOuts;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getGoals.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getGoals.fulfilled, (state, action) => {
        const { payload } = action;
        state.isLoading = false;
        if (payload) {
          state.propertyID = payload.PropertyID;
          state.pk_id = payload.pk_id;
          state.goals = payload.goals;
        } else {
          state.propertyID = goalsDefaultState.propertyID;
          state.pk_id = goalsDefaultState.pk_id;
          state.goals = goalsDefaultState.goals;
        }
      })
      .addCase(getGoals.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(getGoalsData.pending, (state) => {
        state.isPrptyDataLoading = true;
      })
      .addCase(getGoalsData.fulfilled, (state, action) => {
        const { payload } = action;
        state.isPrptyDataLoading = false;
        state.propertyData = payload;
      })
      .addCase(getGoalsData.rejected, (state) => {
        state.isPrptyDataLoading = false;
      })
      .addCase(createGoals.pending, (state) => {
        state.isUpdating = true;
      })
      .addCase(createGoals.fulfilled, (state, action) => {
        console.log('createGoals/action.payload: ', action.payload);
        state.isUpdating = false;
      })
      .addCase(createGoals.rejected, (state) => {
        state.isUpdating = false;
      })
      .addCase(updateGoals.pending, (state) => {
        state.isUpdating = true;
      })
      .addCase(updateGoals.fulfilled, (state) => {
        state.isUpdating = false;
      })
      .addCase(updateGoals.rejected, (state) => {
        state.isUpdating = false;
      })
      .addCase(getForecastData.pending, (state) => {
        state.isForecastDataLoading = true;
      })
      .addCase(getForecastData.fulfilled, (state, action) => {
        const { payload } = action;
        state.isForecastDataLoading = false;
        state.forecastData = {
          ...state.forecastData,
          ...payload
        };
      })
      .addCase(getForecastData.rejected, (state) => {
        state.isForecastDataLoading = false;
      })
      .addCase(getPropertyData1.fulfilled, (state, action) => {
        const { payload } = action;
        state.propertyData = {
          ...state.propertyData,
          ...payload
        };
      })
      .addCase(getPropertyData2.fulfilled, (state, action) => {
        const { payload } = action;
        state.propertyData = {
          ...state.propertyData,
          ...payload
        };
      })
      .addCase(getPropertyData3.fulfilled, (state, action) => {
        const { payload } = action;
        state.propertyData = {
          ...state.propertyData,
          ...payload
        };
      })
      .addCase(getPropertyData4.fulfilled, (state, action) => {
        const { payload } = action;
        state.propertyData = {
          ...state.propertyData,
          ...payload
        };
      })
      .addCase(getPropertyData5.fulfilled, (state, action) => {
        const { payload } = action;
        state.propertyData = {
          ...state.propertyData,
          ...payload
        };
      });
  }
});

export const {
  setPrptyDataLoading,
  updateApplicationsForecast,
  updateMoveInsForecast,
  updateMoveOutsForecast
} = goalsSlice.actions;

export {
  createGoals,
  getForecastData,
  getForecastData2,
  getGoals,
  getGoalsData,
  getPropertyData1,
  getPropertyData2,
  getPropertyData3,
  getPropertyData4,
  getPropertyData5,
  updateGoals
};
export default goalsSlice.reducer;
