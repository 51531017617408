import { Button } from 'antd'

interface IAppButton {
    value: string;
    btnType?: "link" | "text" | "default" | "primary" | "dashed" | undefined;
    btnStyle?: {
        [key: string]: string | number
    };
    isIcon?: boolean;
    IconComponent?: ReactNode;
    IconStyle?: { [key: string]: string | number }
    onClick?: () => void
    isDisabled?: boolean

}

const AppButton = ({ value, btnType, btnStyle, isIcon, IconComponent, IconStyle, onClick, isDisabled = false }: IAppButton) => {
    return (
        <Button type={btnType} style={btnStyle} onClick={onClick} disabled={isDisabled} >
            {isIcon && <IconComponent style={IconStyle} />} <span>{value}</span>
        </Button>

    )
}

export default AppButton