import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-hot-toast";

export const asyncShowSuccess = createAsyncThunk('common/success', async (msg: string, { dispatch }) => {
  dispatch(setCommon({ success: msg }))

  if (msg) {
   const myPromise = new Promise((resolve,reject) =>{
     toast.success(msg)
     resolve(msg)
   }) 

   myPromise.then(res => dispatch(setCommon({ success: null })) )
  }
})
export const asyncShowError = createAsyncThunk('common/error', async (msg: string, { dispatch }) => {
  dispatch(setCommon({ error: msg }))

  if (msg) {
    const myPromise = new Promise((resolve,reject) =>{
      toast.error(msg)
      resolve(msg)
    }) 
    myPromise.then(res => dispatch(setCommon({ error: null })) )

  }
})


const initialState = {
  loading: false,
  error: null,
  success: null,
};

export const common = createSlice({
  name: "Auth",
  initialState,
  reducers: {
    setCommon: (state, { payload }) => ({ ...state, ...payload }),
  },
});

export const { setCommon } = common.actions;
export default common.reducer;
