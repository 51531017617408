import { Outlet } from "react-router-dom";
import { Header, Sidebar } from "../../components";
import styles from "./top-header-page.module.scss";

const TopHeaderPage = (): ReactNode => {
  return (
    <div className={styles.container}>
      <Sidebar />
      <div className={styles.content}>
        <Header />
        <main className={styles.main}>
          <Outlet />
        </main>
      </div>
    </div>
  );
};

export default TopHeaderPage;
