import { useCallback, useEffect, useState } from 'react';

import DisplayRow from './DisplayRow';

import { useAppSelector } from '../../store/hooks';
import { initialMonthKeyValuePairs } from '../../store/stats/stats-utils';
import { getFormattedRoundedNearestK } from '../../utils/format';
import { monthKeys } from '../../utils/months';

const GMRRow = () => {
  const { gmr }: any = useAppSelector((state) => state.goals.propertyData);
  const gmrForecast: any = useAppSelector(
    (state) => state.goals.forecastData.gmr
  );

  const [data, setData] = useState(initialMonthKeyValuePairs);
  // console.log('GMRRow => propertyData.gmr:', gmr);
  // console.log('getDisplayData => data:', data);

  const getDisplayData = useCallback(() => {
    const displayData = monthKeys.reduce((acc: any, curr: any) => {
      const forecastVal = gmrForecast[curr];

      if (!gmr) {
        acc[curr] = forecastVal;
        return acc;
      }

      const actual = gmr[curr];
      if (actual) {
        acc[curr] = actual;
      } else if (forecastVal) {
        acc[curr] = forecastVal;
      } else {
        acc[curr] = 0;
      }
      return acc;
    }, {});

    // console.log('GMRRow => displayData:', displayData);

    setData(displayData);
  }, [gmr, gmrForecast]);

  useEffect(() => {
    getDisplayData();
  }, [getDisplayData, gmr, gmrForecast]);

  return (
    <DisplayRow
      evenOrOdd={'odd'}
      label='Gross Market Rent'
      data={data}
      formatter={getFormattedRoundedNearestK}
    />
  );
};

export default GMRRow;
