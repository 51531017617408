import { useCallback, useEffect, useState } from 'react';

import DisplayRow from './DisplayRow';

import { useAppSelector } from '../../store/hooks';
import { initialMonthKeyValuePairs } from '../../store/stats/stats-utils';
import { getFormattedPercentage } from '../../utils/format';
import { monthKeys } from '../../utils/months';

const NetLeasesPctRow = ({ netLeasesPctGoal }: any): ReactNode => {
  const { netLeasesPct }: any = useAppSelector(
    (state) => state.goals.propertyData
  );

  const [data, setData] = useState(initialMonthKeyValuePairs);
  // console.log('NetLeasesPctRow => netLeasesPctGoal:', netLeasesPctGoal);
  // console.log('NetLeasesPctRow => propertyData.netLeasesPct:', netLeasesPct);
  // console.log('getDisplayData => data:', data);

  const getDisplayData = useCallback(() => {
    const displayData = monthKeys.reduce((acc: any, curr: any) => {
      const goal = netLeasesPctGoal;
      if (!netLeasesPct) {
        acc[curr] = goal;
        return acc;
      }

      const actual = netLeasesPct[curr];
      if (actual) {
        acc[curr] = actual;
      } else if (goal) {
        acc[curr] = goal;
      } else {
        acc[curr] = 0;
      }
      return acc;
    }, {});

    setData(displayData);
  }, [netLeasesPctGoal, netLeasesPct]);

  useEffect(() => {
    getDisplayData();
  }, [getDisplayData, netLeasesPct]);

  return (
    <DisplayRow
      evenOrOdd={'odd'}
      label='Net Leases %'
      data={data}
      formatter={getFormattedPercentage}
    />
  );
};

export default NetLeasesPctRow;
