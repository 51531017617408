import { AxiosRequestConfig, AxiosResponse } from 'axios';
import { axiosApiInstance } from '../routes/private-route';
import { config as _config } from '../utils/config';

interface IParams {
  [key: string]: any;
}

export const httpRequest = async <T>(
  url: string,
  method: AxiosRequestConfig['method'],
  token?: string,
  body?: T,
  params?: boolean,
  paramsObj?: IParams
): Promise<AxiosResponse> => {
  const baseUrl = _config.REACT_APP_SERVER_URL;
  const headers: AxiosRequestConfig['headers'] = {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  };

  if (token) {
    headers.Authorization = `Bearer ${token}`;
  }

  const config: AxiosRequestConfig = {
    url: `${baseUrl}/${url}`,
    method,
    headers,
    data: body,
    ...(params && { params: paramsObj })
  };

  try {
    const response = await axiosApiInstance(config);
    return response;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const httpRequestExternal = async <T>(
  url: string,
  method: AxiosRequestConfig['method'],
  // token?: string,
  body?: T,
  params?: boolean,
  paramsObj?: IParams
): Promise<AxiosResponse> => {
  const headers: AxiosRequestConfig['headers'] = {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  };

  // if (token) {
  //   headers.Authorization = `Bearer ${token}`;
  // }

  const config: AxiosRequestConfig = {
    url,
    method,
    headers,
    data: body,
    ...(params && { params: paramsObj })
  };

  try {
    const response = await axiosApiInstance(config);
    return response;
  } catch (error) {
    console.error(error);
    throw error;
  }
};
