import { AxiosError, AxiosResponse } from "axios";
import { httpRequest } from "./request";


function getSubscriptionsService({ page, pageSize }: { page: number | undefined, pageSize: number | undefined }, token: string): Promise<AxiosResponse | AxiosError> {
    return new Promise((resolve, reject) => {
        httpRequest("subscriptions", "get", token, null, true, { page, pageSize })
            .then((res: AxiosResponse) => {
                resolve(res);
            })
            .catch((err: AxiosError) => {
                reject(err);
            });
    });
}

function getFilterSubscriptionsService({ clientIds, propIds, active, page, pageSize }: { clientIds: string, propIds: string, active: number | string, page: number | undefined, pageSize: number | undefined }, token: string): Promise<AxiosResponse | AxiosError> {
    return new Promise((resolve, reject) => {
        httpRequest("subscriptions/filter", "get", token, null, true, { clientIds, propIds, active, page, pageSize })
            .then((res: AxiosResponse) => {
                resolve(res);
            })
            .catch((err: AxiosError) => {
                reject(err);
            });
    });
}
function addClientNameService(body: { body: string }, token: string): Promise<AxiosResponse | AxiosError> {
    return new Promise((resolve, reject) => {
        httpRequest("subscriptions/client", "post", token, body)
            .then((res: AxiosResponse) => {
                resolve(res);
            })
            .catch((err: AxiosError) => {
                reject(err);
            });
    });
}

function getClientPropertiesService(clientName: string, token: string): Promise<AxiosResponse | AxiosError> {
    return new Promise((resolve, reject) => {
        httpRequest(`subscriptions/properties/${clientName}`, "get", token,)
            .then((res: AxiosResponse) => {
                resolve(res);
            })
            .catch((err: AxiosError) => {
                reject(err);
            });
    });
}

function getProductsService(token: string): Promise<AxiosResponse | AxiosError> {
    return new Promise((resolve, reject) => {
        httpRequest(`subscriptions/products`, "get", token,)
            .then((res: AxiosResponse) => {
                resolve(res);
            })
            .catch((err: AxiosError) => {
                reject(err);
            });
    });
}

function addActiveSubscription(body: any, token: string): Promise<AxiosResponse | AxiosError> {
    return new Promise((resolve, reject) => {
        httpRequest(`subscriptions/`, "post", token, body)
            .then((res: AxiosResponse) => {
                resolve(res);
            })
            .catch((err: AxiosError) => {
                reject(err);
            });
    });
}

export {
    getSubscriptionsService,
    getFilterSubscriptionsService,
    addClientNameService,
    getClientPropertiesService,
    getProductsService,
    addActiveSubscription
}
