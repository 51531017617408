import React from 'react'
interface selectInterface {
    options: { property_id: string; property_name: string; }[];
    handleChange: (e: React.ChangeEvent<HTMLSelectElement>) => void
}

const AppDropdown = ({ options, handleChange }: selectInterface) => {
    return (
        <select onChange={handleChange}>
            {options.length > 0 &&
                options.map(
                    (item: { property_id: string; property_name: string }, i: number) => (
                        <option key={i} value={item.property_name}>
                            {item.property_name}
                        </option>
                    )
                )}
        </select>
    )
}

export default AppDropdown