import { Form } from 'antd';

import GoalsFormHeader from './GoalsFormHeader';
import ApplicationsRow from './ApplicationsRow';
import ConversionPctRow from './ConversionPctRow';
import ExpiringLeasesRow from './ExpiringLeasesRow';
import MoveInsRow from './MoveInsRow';
import MoveOutsRow from './MoveOutsRow';
import NetLeasesPctRow from './NetLeasesPctRow';
import RenewalPctRow from './RenewalPctRow';
import ToursRow from './ToursRow';

import styles from './goalsForm.module.scss';
import './goalsForm.css';

const MetricsOp = ({ formInstance }: any): ReactNode => {
  const netLeasesPctGoal = Form.useWatch('netLeasesPct', formInstance);
  const renewalPctGoal = Form.useWatch('renewalPct', formInstance);
  const tourConversionPctGoal = Form.useWatch(
    'tourConversionPct',
    formInstance
  );
  const tourGoals = Form.useWatch('tours', formInstance);

  return (
    <>
      <div className={styles.goalsForm}>
        <GoalsFormHeader />

        <ExpiringLeasesRow />

        <RenewalPctRow renewalPctGoal={renewalPctGoal} />

        <MoveOutsRow renewalPctGoal={renewalPctGoal} />

        <ToursRow tourGoals={tourGoals} />

        <ConversionPctRow tourConversionPctGoal={tourConversionPctGoal} />

        <ApplicationsRow
          tourGoals={tourGoals}
          tourConversionPctGoal={tourConversionPctGoal}
        />

        <NetLeasesPctRow netLeasesPctGoal={netLeasesPctGoal} />

        <MoveInsRow
          netLeasesPctGoal={netLeasesPctGoal}
          tourGoals={tourGoals}
          tourConversionPctGoal={tourConversionPctGoal}
        />

        <div className={styles.row} data-row={'bottom'}>
          <div style={{ height: '2rem', width: '100%' }} />
        </div>
      </div>
    </>
  );
};

export default MetricsOp;
