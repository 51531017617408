import classes from "./stats-card.module.scss";

const SubICard = ({ index, color, name, icon, value, size }: ISubICard) => {
  return (
    <div
      key={index}
      className={classes.card}
      style={{
        backgroundColor: size === 33 ? "#FFFFFF" : "#f5f6f6",
        width: size === 33 ? "33%" : "25%",
      }}
    >
      {icon && (
        <div
          className={classes.imgContainer}
          style={{
            background: `${color}39`,
          }}
        >
          <img src={icon} alt="icon" />
        </div>
      )}
      <div
        className={classes.contentContainer}
        style={{ textTransform: "capitalize" }}
      >
        <p>{name}</p>
        <span
          className={classes.value}
          style={{ color: color, fontSize: size === 33 ? 20 : 28 }}
        >
          {name === "average rate" && <span>$</span>}
          {isNaN(value) ? value : Math.round(value).toLocaleString()}
        </span>
        {name === "average size" && <span className={classes.unit}>Sqft</span>}
      </div>
    </div>
  );
};

export default SubICard;
