import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  addActiveSubscription,
  addClientNameService,
  getClientPropertiesService,
  getFilterSubscriptionsService,
  getProductsService,
  getSubscriptionsService,
} from "../../services/subscriptions.services";

export const asyncGetSubscriptions = createAsyncThunk(
  "subscriptions",
  async (
    query: { page: number | undefined; pageSize: number | undefined },
    { _, getState }: any
  ) => {
    try {
      const result: any = await getSubscriptionsService(
        query,
        getState().user.token
      );
      return result.data;
    } catch (error) {
      throw error;
    }
  }
);

export const asyncGetFilterSubscriptions = createAsyncThunk(
  "subscriptions",
  async (
    query: {
      clientIds: string;
      propIds: string;
      active: number | string;
      page: number | undefined;
      pageSize: number | undefined;
    },
    { _, getState }: any
  ) => {
    try {
      const result: any = await getFilterSubscriptionsService(
        query,
        getState().user.token
      );
      return result.data;
    } catch (error) {
      throw error;
    }
  }
);
export const asyncAddClientName = createAsyncThunk(
  "subscriptions",
  async (body: { body: string }, { _, getState }: any) => {
    try {
      const result: any = await addClientNameService(
        body,
        getState().user.token
      );
      return result.data;
    } catch (error) {
      throw error;
    }
  }
);

export const asyncGetClientProperties = createAsyncThunk(
  "subscriptions",
  async (clientName: string, { _, getState }: any) => {
    try {
      const result: any = await getClientPropertiesService(
        clientName,
        getState().user.token
      );
      return result.data;
    } catch (error) {
      throw error;
    }
  }
);

export const asyncGetProducts = createAsyncThunk(
  "subscriptions",
  async (__, { _, getState }: any) => {
    try {
      const result: any = await getProductsService(getState().user.token);
      return result.data;
    } catch (error) {
      throw error;
    }
  }
);

export const asyncAddActiveSubscription = createAsyncThunk(
  "subscriptions",
  async (body: any, { _, getState }: any) => {
    try {
      const result: any = await addActiveSubscription(
        body,
        getState().user.token
      );
      return result.data;
    } catch (error) {
      throw error;
    }
  }
);
