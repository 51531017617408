import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { MsalProvider } from "@azure/msal-react";
import { Toaster } from "react-hot-toast";
import ReactGA from "react-ga4";

import Routes from "./routes";
import { store } from "./store";
import { config } from "./utils/config";

import "./App.scss";
import { ConfigProvider } from "antd";

const appKey: any = config.REACT_APP_GOOGLE_ANALYTICS_MEASUREMENT_ID;
ReactGA.initialize(appKey);

export default function App({ msalInstance }: any): JSX.Element {
  return (
    <MsalProvider instance={msalInstance}>
      <ConfigProvider
        theme={{
          token: {
            fontFamily: "Poppins",
          },
          components: {
            Tabs: {
              cardGutter: 1,
              cardPaddingSM: "4px 6px",
              fontFamily: "Poppins",
              fontSize: 12,
              itemActiveColor: "#8a8e93",
              itemColor: "#8a8e93",
              itemHoverColor: "#8a8e93",
              itemSelectedColor: "#8a8e93",
            },
            Slider: {
              dotActiveBorderColor: '#4361f9',
              dotSize: 6,
              handleActiveColor: '#4361f9',
              handleColor: 'rgba(67, 97, 249, 0.8)',
              handleSize: 8,
              railBg: '#212830',
              railSize: 10,
              trackBg: 'rgba(67, 97, 249, 0.8)',
              trackHoverBg: '#4361f9'
            },
            Table: {
              headerBg: "#CFD4DB",
              cellFontSize: 12,
              colorText: "#8390A2",
              headerSplitColor: "transparent",
            },
          },
        }}
      >
        <BrowserRouter>
          <Provider store={store}>
            <Routes />
            <Toaster toastOptions={{ duration: 2000 }} />
          </Provider>
        </BrowserRouter>
      </ConfigProvider>
    </MsalProvider>
  );
}
