import { useEffect } from "react";
import { Navigate, useLocation, useRoutes } from "react-router-dom";
import ReactGA from "react-ga4";

import {
  Administration,
  Dashboard,
  Error404,
  Login,
  Products,
  Property,
  RenewalPlus,
} from "../screens";
import GoalsPage from "../screens/goals/GoalsPage";

import DashboardPage from "../layouts/DashboardPage";
import GoalsLayout from "../layouts/GoalsLayout";
import TopHeaderPage from "../layouts/TopHeaderPage";
import PrivateRoute from "./private-route";

import { useAppSelector } from "../store/hooks";

const Routes = () => {
  const { pathname } = useLocation();
  const { user }: any = useAppSelector((s) => s.user);

  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: pathname,
      title: "onLoadLocationName",
    });

    ReactGA.event({
      category: user?.name ?? "user name",
      action: "onLoadUserName",
      label: user?.username ?? "user email",
    });
  }, [pathname, user]);

  return useRoutes([
    { path: "404", element: <Error404 /> },
    {
      path: "/",
      element: <Login />,
    },
    {
      path: "/home",
      element: <PrivateRoute component={DashboardPage} />,
      children: [
        {
          index: true,
          element: <Dashboard />,
        },
      ],
    },
    {
      path: "/goals",
      element: <PrivateRoute component={GoalsLayout} />,
      children: [
        {
          index: true,
          element: <GoalsPage />,
        },
      ],
    },
    {
      path: "/property",
      element: <PrivateRoute component={TopHeaderPage} />,
      children: [
        {
          index: true,
          element: <Property />,
        },
      ],
    },
    {
      path: "/products",
      element: <PrivateRoute component={TopHeaderPage} />,
      children: [
        {
          path: ":id",
          element: <Products />,
        },
      ],
    },
    {
      path: "/admin",
      element: <PrivateRoute component={TopHeaderPage} />,
      children: [
        {
          index: true,
          element: <Administration />,
        },
      ],
    },
    {
      path: "/renewal",
      element: <PrivateRoute component={TopHeaderPage} />,
      children: [
        {
          index: true,
          element: <RenewalPlus />,
        },
      ],
    },
    {
      path: "*",
      element: <Navigate to="/404" />,
    },
  ]);
};

export default Routes;
