import { useCallback, useEffect, useState } from 'react';

import DisplayRow from './DisplayRow';

import { useAppSelector } from '../../store/hooks';
import { initialMonthKeyValuePairs } from '../../store/stats/stats-utils';
import { getFormattedPercentage } from '../../utils/format';
import { monthKeys } from '../../utils/months';

const RenewalPctRow = ({ renewalPctGoal }: any) => {
  const { renewalPct }: any = useAppSelector(
    (state) => state.goals.propertyData
  );

  const [data, setData] = useState(initialMonthKeyValuePairs);
  // console.log('RenewalPctRow => renewalPctGoal:', renewalPctGoal);
  // console.log('RenewalPctRow => propertyData.renewalPct:', renewalPct);
  // console.log('getDisplayData => data:', data);

  const getDisplayData = useCallback(() => {
    const displayData = monthKeys.reduce((acc: any, curr: any) => {
      const goal = renewalPctGoal;
      if (!renewalPct) {
        acc[curr] = goal;
        return acc;
      }

      const actual = renewalPct[curr];
      if (actual) {
        acc[curr] = actual;
      } else if (goal) {
        acc[curr] = goal;
      } else {
        acc[curr] = 0;
      }
      return acc;
    }, {});

    setData(displayData);
  }, [renewalPctGoal, renewalPct]);

  useEffect(() => {
    getDisplayData();
  }, [getDisplayData, renewalPct]);

  return (
    <DisplayRow
      evenOrOdd={'even'}
      label='Renewal %'
      data={data}
      formatter={getFormattedPercentage}
    />
  );
};

export default RenewalPctRow;
