const rentTypeOptions = [
    {
      value: 'market',
      label: 'Market'
    },
    {
      value: 'affordable',
      label: 'Affordable'
    },
    {
      value: 'market/affordable',
      label: 'Market/Affordable'
    }
  ]
  const marketSegmentOptions = [
    {
      value: 'general',
      label: 'General'
    },
    {
      value: 'student',
      label: 'Student'
    },
    {
      value: 'senior',
      label: 'Senior'
    }
  ]
  const statusOptions = [
    {
      value: 'existing',
      label: 'Existing'
    },
    {
      value: 'lease_up',
      label: 'Lease Up'
    },
    {
      value: 'under construction',
      label: 'Under Construction'
    },
    {
      value: 'approved',
      label: 'Approved'
    },
    {
      value: 'proposed',
      label: 'Proposed'
    },
  ]
  const styleOptions = [
    {
      value: 'podium',
      label: 'Podium'
    },
    {
      value: 'tuck under',
      label: 'Tuck Under'
    },
    {
      value: 'wrap',
      label: 'Wrap'
    },
    {
      value: 'high rise',
      label: 'High Rise'
    },
    {
      value: 'town home',
      label: 'TownHome'
    },
  ]
  const corridorOptions = [
    {
      value: 'closed',
      label: 'Closed'
    },
    {
      value: 'open',
      label: 'Open'
    },
  ]
  const elevatorsOptions = [
    {
      value: 0,
      label: 'Yes'
    },
    {
      value: 1,
      label: 'No'
    },
  ]

  export {rentTypeOptions,marketSegmentOptions,statusOptions,styleOptions,corridorOptions,elevatorsOptions}