/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { setProperty } from "../../store/properties/property-slice";

import PricingTable from "./table-area";
import MarketSnapshot from "./submarketSnapshot"

const Pricing = () => {
  const dispatch = useAppDispatch();
  const {
    property: { activeTab },
  } = useAppSelector((s) => ({ property: s.property, user: s.user }));

  useEffect(() => {
    dispatch(setProperty({ tabs: ["Unit Pricing", "Submarket Snapshot"] }));
    return () => {
      dispatch(setProperty({ tabs: [], activeTab: 0 }));
    };
  }, []);

  return <>{activeTab === 0 ? <PricingTable />: <MarketSnapshot />}</>
};

export default Pricing;
