import { AxiosError, AxiosResponse } from "axios";
import { httpRequest } from "./request";

function getRenewalDataService({ page = 1, pageSize = 30,oid='',values='' }: { page: number | undefined, pageSize: number | undefined,oid:string,values?:string}, token: string): Promise<AxiosResponse | AxiosError> {
  return new Promise((resolve, reject) => {
    httpRequest(
      `renewal/`,
      "get",
      token,
      null,
      true,
      { page, pageSize,oid,values }
    )
      .then((res: AxiosResponse) => {
        resolve(res);
      })
      .catch((err: AxiosError) => {
        reject(err);
      });
  });
}
function getRenewalDataByLeaseTypeService({ page = 1, pageSize = 30, values }: IRenewalPlusFilterQuery, token: string): Promise<AxiosResponse | AxiosError> {
  return new Promise((resolve, reject) => {
    httpRequest(
      `renewal/filter`,
      "get",
      token,
      null,
      true,
      { page, pageSize, values }
    )
      .then((res: AxiosResponse) => {
        resolve(res);
      })
      .catch((err: AxiosError) => {
        reject(err);
      });
  });
}

function getUserPropertiesService(oid: string, token: string): Promise<AxiosResponse | AxiosError> {
  return new Promise((resolve, reject) => {
    httpRequest(
      `renewal/user/properties/${oid}`,
      "get",
      token,
      null,
    )
      .then((res: AxiosResponse) => {
        resolve(res);
      })
      .catch((err: AxiosError) => {
        reject(err);
      });
  });
}

function updateFeesesService(body: IObject, token: string): Promise<AxiosResponse | AxiosError> {
  return new Promise((resolve, reject) => {
    httpRequest(
      `renewal/feeses`,
      "put",
      token,
      body,
    )
      .then((res: AxiosResponse) => {
        resolve(res);
      })
      .catch((err: AxiosError) => {
        reject(err);
      });
  });
}

function updateRenewalDataService(body: IObject, token: string): Promise<AxiosResponse | AxiosError> {
  return new Promise((resolve, reject) => {
    httpRequest(
      `renewal/data`,
      "put",
      token,
      body,
    )
      .then((res: AxiosResponse) => {
        resolve(res);
      })
      .catch((err: AxiosError) => {
        reject(err);
      });
  });
}
function getUserRolesService(oid: string, token: string): Promise<AxiosResponse | AxiosError> {
  return new Promise((resolve, reject) => {
    httpRequest(
      `renewal/user/roles`,
      "get",
      token,
      null,
      true,
      { oid }
    )
      .then((res: AxiosResponse) => {
        resolve(res);
      })
      .catch((err: AxiosError) => {
        reject(err);
      });
  });
}

function sendRenewalDataService(body: IObject, token: string): Promise<AxiosResponse | AxiosError> {
  return new Promise((resolve, reject) => {
    httpRequest(
      `user/sendMail`,
      "post",
      token,
      body,
    )
      .then((res: AxiosResponse) => {
        resolve(res);
      })
      .catch((err: AxiosError) => {
        reject(err);
      });
  });
}



function postRenewalLetter(body: IObject, token: string): Promise<AxiosResponse | AxiosError> {
  return new Promise((resolve, reject) => {
    httpRequest(
      `renewal/user/roles`,
      "post",
      token,
      body
    )
      .then((res: AxiosResponse) => {
        resolve(res);
      })
      .catch((err: AxiosError) => {
        reject(err);
      });
  });
}


export {
  getRenewalDataService,
  getRenewalDataByLeaseTypeService,
  getUserPropertiesService,
  updateFeesesService,
  updateRenewalDataService,
  getUserRolesService,
  sendRenewalDataService,
  postRenewalLetter
}