/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Form, Modal, Select } from "antd";
import { useEffect, useState } from "react";
import { useAppDispatch } from "../../../store/hooks";
import {
  asyncCreateOrUpdateIntermediary,
  asyncGetRefrenceCompetitorsIds,
} from "../../../store/properties/property-async";
import {
  asyncShowError,
  asyncShowSuccess,
  setCommon,
} from "../../../store/common/common-slice";
import { MdDelete } from "react-icons/md";

const RefrenceModal = ({
  open,
  setOpen,
  record,
  setRecord,
  ids,
  setIds,
  callBackFunc,
  refCompData,
  setRefCompData,
  ms_property_id,
}: IRefCompetitorModal) => {
  const [form] = Form.useForm();
  const [clientName, setClientName] = useState("");
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (record) {
      form.setFieldsValue({
        ...record,
        final_property_id: record.final_property_id,
      });
      setClientName(record?.client_name);
    } else {
      setRecord(null);
    }
  }, [record]);

  const onSubmit = async (values: any) => {
    try {
      dispatch(setCommon({ loading: true }));

      const obj = {
        ...values,
        final_property_id: values.final_property_id.value,
        client_property_id: ms_property_id,
      };

      if (record) {
        await dispatch(asyncCreateOrUpdateIntermediary(obj));
        dispatch(
          asyncShowSuccess("Successfully updated the refrence competitor")
        );
      } else {
        await dispatch(asyncCreateOrUpdateIntermediary(obj));
        dispatch(
          asyncShowSuccess("Successfully created the refrence competitor")
        );
      }
      form.resetFields();
      dispatch(setCommon({ loading: false }));
      callBackFunc(ms_property_id);
    } catch (err: any) {
      dispatch(asyncShowError(err.message));
      dispatch(setCommon({ loading: false }));
    }
  };
  let debounceTimeoutId: NodeJS.Timeout | null = null;

  const handleSearch = async (keyword: string) => {
    const debounceDelay = 500; // Set the debounce delay (in milliseconds) as per your requirements

    // Clear the previous debounce timeout if it exists
    if (debounceTimeoutId) {
      clearTimeout(debounceTimeoutId);
    }

    debounceTimeoutId = setTimeout(async () => {
      try {
        dispatch(setCommon({ loading: true }));
        const result = await dispatch(asyncGetRefrenceCompetitorsIds(keyword));
        setIds(
          result.payload.map(
            (item: { final_property_id: string; property_name: string }) => ({
              value: item.final_property_id,
              label: item.property_name,
            })
          )
        );
        dispatch(setCommon({ loading: false }));
      } catch (err) {
        dispatch(setCommon({ loading: false }));
        console.log(err);
      }
    }, debounceDelay);
  };

  const handleSelectChange = (keyword: string) => {
    handleSearch(keyword);
  };

  return (
    <Modal
      open={open}
      destroyOnClose={true}
      title={clientName ?? "Refrence Competitor"}
      footer={null}
      onCancel={async () => {
        await form.resetFields();
        await setRecord(null);
        setOpen(false);
      }}
    >
      <Form
        form={form}
        layout="vertical"
        name="form_in_modal"
        preserve={false}
        onFinish={onSubmit}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginTop: "1rem",
          }}
        >
          <Form.Item
            name="final_property_id"
            rules={[
              {
                required: true,
                message: "Please select the refrence competitor property name",
              },
            ]}
          >
            <Select
              labelInValue
              showSearch
              placeholder="Select or search the refrence competitor property name"
              optionFilterProp="children"
              onSearch={handleSelectChange}
              style={{ width: "100%" }}
              filterOption={(input, option: any) =>
                (option?.label ?? "")
                  .trim()
                  .toLowerCase()
                  .includes(input.trim().toLowerCase())
              }
              options={ids}
            />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit" size="middle">
              Submit
            </Button>
          </Form.Item>
        </div>
      </Form>

      {refCompData?.map((item: any, index) => (
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            padding: "0 10px",
            marginBottom: "1rem",
            borderRadius: "5px",
            alignItems: "center",
            boxShadow: "0px 1px 6px 0px lightgray",
          }}
        >
          <p style={{ width: "50%" }}>{item.property_name}</p>
          <MdDelete size={20} />
        </div>
      ))}
    </Modal>
  );
};

export default RefrenceModal;
