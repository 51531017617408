/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react-hooks/rules-of-hooks */
import { useEffect } from "react";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../../utils/active-directory/config";
import { useNavigate } from "react-router-dom";

const Login = (): ReactNode => {
  const { instance, accounts } = useMsal();
  const navigate = useNavigate();

  useEffect(() => {
    if (accounts.length) {
      navigate("/home");
    } else {
      setTimeout(() => {
        instance.loginRedirect(loginRequest);
      }, 1000);
    }
  }, [accounts.length]);
  return <></>;
};
export default Login;
