import ReactApexChart from "react-apexcharts";
import { ApexOptions } from "apexcharts";
import { useEffect, useState } from "react";

interface SeriesData {
  data: number[];
  name: string;
}

interface ChartData {
  series: SeriesData[];
  options: ApexOptions;
}

const StackedBarChart = ({
  data,
  labels,
  XTitle,
  YTitle,
}: {
  data: any[];
  labels: any;
  XTitle?: string;
  YTitle?: string;
}) => {
  const [offsetX, setOffsetX] = useState(200);
  const [offsetY, setOffsetY] = useState(-41);
  const [dataLabels, setDataLabels] = useState(true);
  const [chartData, setChartData] = useState<ChartData>({
    series: [
      {
        name: "Market Rent",
        data: data?.map((obj: any) => obj?.MarketRent),
      },
      {
        name: "Monthly Fees",
        data: data?.map((obj: any) => obj?.MonthlyFee),
      },
      {
        name: "Move-in Fees",
        data: data?.map((obj: any) => obj?.MoveInFee / 12),
      },
    ],
    options: {
      chart: {
        type: "bar",
        stacked: true,
        stackType: "100%",
        toolbar: {
          show: false,
        },
      },
      states: {
        hover: {
          filter: {
            type: "dark",
            value: 0,
          },
        },
      },
      plotOptions: {
        bar: {
          horizontal: false,
        },
      },
      stroke: {
        width: 1,
        colors: ["#fff"],
      },
      colors: ["#ECEFFE", "#4361F9", "#FFB84C"],
      fill: {
        opacity: 1,
      },
      legend: {
        show: true,
        position: "top",
        horizontalAlign: "left",
        floating: false,
        offsetX: offsetX,
        offsetY: offsetY,
        labels: {
          colors: ["#8390A2", "#8390A2", "#8390A2"],
          useSeriesColors: false,
        },
        fontFamily: "Poppins",
        markers: {
          width: 12,
          height: 12,
          strokeWidth: 0,
          strokeColor: "#fff",
          fillColors: undefined,
          radius: 50,
          customHTML: undefined,
          onClick: undefined,
          offsetX: -8,
          offsetY: 2,
        },
        itemMargin: {
          horizontal: 15,
          vertical: 0,
        },
      },
      dataLabels: {
        enabled: true,
        style: {
          colors: ["black", "white", "white"],
          fontSize: "11px",
          fontWeight: "Regular",
          fontFamily: "Poppins",
        },
      },

      title: {
        text: "Monthly Lease Breakdown",
        style: {
          fontWeight: 600,
          fontSize: "15px",
          fontFamily: "Poppins",
        },
      },
      xaxis: {
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        categories: labels,
        labels: {
          show: true,
          rotate: -20,
          rotateAlways: false,
          style: {
            fontSize: "10px",
            colors: [
              "#8390A2",
              "#8390A2",
              "#8390A2",
              "#8390A2",
              "#8390A2",
              "#8390A2",
              "#8390A2",
              "#8390A2",
              "#8390A2",
            ],
            fontWeight: "Regular",
            fontFamily: "Poppins",
          },
        },
        title: {
          text: XTitle || undefined,
          offsetX: 0,
          offsetY: 6,
          style: {
            color: "#8390A2",
            fontSize: "18px",
            fontFamily: "Poppins",
            fontWeight: "normal",
          },
        },
      },
      yaxis: {
        stepSize: 50,
        forceNiceScale: true,
        labels: {
          style: {
            fontSize: "11px",
            colors: [
              "#8390A2",
              "#8390A2",
              "#8390A2",
              "#8390A2",
              "#8390A2",
              "#8390A2",
              "#8390A2",
              "#8390A2",
              "#8390A2",
            ],
            fontWeight: "Regular",
            fontFamily: "Poppins",
          },
          formatter: (value) => {
            return `${value}%`;
          },
        },
        title: {
          text: YTitle || undefined,
          offsetX: 0,
          offsetY: 0,
          style: {
            color: "#8390A2",
            fontSize: "18px",
            fontFamily: "Poppins",
            fontWeight: "normal",
          },
        },
      },
      tooltip: {
        enabled: false,
      },
      grid: {
        show: false,
      },
    },
  });

  useEffect(() => {
    const updateWidth = () => {
      const newWidth = window.innerWidth;
      if (newWidth < 455) {
        setOffsetX(0);
        setOffsetY(0);
        setDataLabels(false);
      } else {
        setOffsetX(200);
        setOffsetY(-41);
        setDataLabels(true);
      }
    };

    updateWidth();
    window.addEventListener("resize", updateWidth);

    // Cleanup the event listener on component unmount
    return () => window.removeEventListener("resize", updateWidth);
  }, []);

  useEffect(() => {
    setChartData((prev) => ({
      ...prev,
      options: {
        ...prev.options,
        dataLabels: {
          enabled: dataLabels,
        },
        legend: {
          offsetX: offsetX,
          offsetY: offsetY,
        },
      },
    }));
  }, [offsetX, offsetY, dataLabels]);

  console.log("offsetX=====>", offsetX);

  return (
    <div>
      <div id="chart">
        <ReactApexChart
          options={chartData.options}
          series={chartData.series}
          type="bar"
          height={400}
        />
      </div>
      <div id="html-dist"></div>
    </div>
  );
};

export default StackedBarChart;
