import { useEffect } from "react";
import { Form, Input, Modal, Select } from "antd";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import {
  asyncShowError,
  asyncShowSuccess,
  setCommon,
} from "../../../store/common/common-slice";
import {
  asyncInsertInPropProperties,
  asyncUpdateInPropProperties,
} from "../../../store/properties/property-async";
import {
  corridorOptions,
  elevatorsOptions,
  marketSegmentOptions,
  rentTypeOptions,
  statusOptions,
  styleOptions,
} from "../../../screens/property/data";

const PropertyModal = ({
  open,
  setOpen,
  callBackFunc,
  record,
  setRecord,
}: IPropertyModal) => {
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const { loading } = useAppSelector((s) => s.common);

  useEffect(() => {
    if (record) {
      form.setFieldsValue(record);
    } else {
      form.resetFields();
      setRecord(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [record]);

  const validatePhoneNumber = (
    _: any,
    value: string,
    callback: (value?: string) => void
  ) => {
    const phoneRegex = /^\+?\d{10,}$/;
    if (value && !phoneRegex.test(value)) {
      callback(
        "Phone number must contain at least 10 digits and may start with a '+' for the country code"
      );
    } else {
      callback();
    }
  };

  const onCreate = async (values: any) => {
    try {
      dispatch(setCommon({ loading: true }));
      if (values) {
        Object.keys(values).forEach((element: any) => {
          if (!values[element]) {
            if (
              element === "unit_count" ||
              element === "parking_count" ||
              element === "year_built" ||
              element === "acres" ||
              element === "floors" ||
              element === "elevators"
            )
              values[element] = null;
            else values[element] = "";
          }
        });
        if (record) {
          await dispatch(asyncUpdateInPropProperties(values));
          dispatch(asyncShowSuccess("Successfully updated the property"));
        } else {
          const result = await dispatch(asyncInsertInPropProperties(values));
          if (result?.payload?.error) {
            dispatch(setCommon({ loading: false }));
            dispatch(
              asyncShowError(
                "Duplicate Dv Property ID or Ms Property ID key issue"
              )
            );
            return;
          }
          dispatch(asyncShowSuccess("Successfully created the property"));
        }

        dispatch(setCommon({ loading: false }));
        form.resetFields();
        setOpen(false);
        callBackFunc();
      }
    } catch (err: any) {
      dispatch(asyncShowError(err.message));
      dispatch(setCommon({ loading: false }));
    }
  };

  return (
    <Modal
      open={open}
      destroyOnClose={true}
      title={record ? "Update property" : "Create property"}
      okText={record ? "Update" : "Create"}
      okButtonProps={{ disabled: loading }}
      cancelButtonProps={{ disabled: loading }}
      cancelText="Cancel"
      onCancel={async () => {
        await form.resetFields();
        await setRecord(null);
        setOpen(false);
      }}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            onCreate(values);
          })
          .catch((info) => {
            console.log("Validate Failed:", info);
          });
      }}
    >
      <Form form={form} layout="vertical" name="form_in_modal">
        <Form.Item name="refrence_competitors" label="Refrence Competitors">
          <Input />
        </Form.Item>
        <Form.Item
          name="dv_property_id"
          label="Dv Property ID"
          rules={[
            {
              required: true,
              message: "Please input the dv property id",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item name="ms_property_id" label="Ms Property ID ">
          <Input />
        </Form.Item>
        <Form.Item name="costar_id" label="Costar ID">
          <Input />
        </Form.Item>
        <Form.Item name="aptscom_id" label="Apartments.com ID">
          <Input />
        </Form.Item>
        <Form.Item name="dv_client_id" label="Dev Client ID">
          <Input />
        </Form.Item>

        <Form.Item name="property_name" label="Property Name">
          <Input />
        </Form.Item>
        <Form.Item name="client_name" label="Client Name">
          <Input />
        </Form.Item>
        <Form.Item name="website_url" label="Website URL">
          <Input />
        </Form.Item>
        <Form.Item name="abbreviation" label="Abbreviation">
          <Input />
        </Form.Item>

        <Form.Item name="unit_count" label="Unit Count">
          <Input type="number" />
        </Form.Item>

        <Form.Item name="parking_count" label="Parking Count">
          <Input type="number" />
        </Form.Item>
        <Form.Item name="photo_url" label="Photo URL">
          <Input />
        </Form.Item>
        <Form.Item name="rent_type" label="Rent Type">
          <Select
            labelInValue
            style={{ width: "100%" }}
            options={rentTypeOptions?.map((item) => ({
              value: item?.value,
              label: item?.label,
            }))}
          />
        </Form.Item>
        <Form.Item name="market_segment" label="Market Segment">
          <Select
            labelInValue
            style={{ width: "100%" }}
            options={marketSegmentOptions?.map((item) => ({
              value: item?.value,
              label: item?.label,
            }))}
          />
        </Form.Item>
        <Form.Item name="year_built" label="Year Built">
          <Input type="number" />
        </Form.Item>
        <Form.Item name="status" label="Status">
          <Select
            labelInValue
            style={{ width: "100%" }}
            options={statusOptions?.map((item) => ({
              value: item?.value,
              label: item?.label,
            }))}
          />
        </Form.Item>
        <Form.Item name="corridor_type" label="Corridor Type ">
          <Select
            labelInValue
            style={{ width: "100%" }}
            options={corridorOptions?.map((item) => ({
              value: item?.value,
              label: item?.label,
            }))}
          />
        </Form.Item>
        <Form.Item name="elevators" label="Elevators">
          <Select
            labelInValue
            style={{ width: "100%" }}
            options={elevatorsOptions?.map((item) => ({
              value: item?.value,
              label: item?.label,
            }))}
          />
        </Form.Item>
        <Form.Item name="acres" label="Acres">
          <Input type="number" />
        </Form.Item>
        <Form.Item name="style" label="Style">
          <Select
            labelInValue
            style={{ width: "100%" }}
            // onChange={handleClientChange}
            options={styleOptions?.map((item) => ({
              value: item?.value,
              label: item?.label,
            }))}
          />
        </Form.Item>
        <Form.Item name="floors" label="Floors">
          <Input type="number" />
        </Form.Item>
        <Form.Item name="manager_email" label="Manager Email">
          <Input />
        </Form.Item>
        <Form.Item
          name="phone_number"
          label="Phone Number"
          rules={[{ validator: validatePhoneNumber }]}
        >
          <Input />
        </Form.Item>
        <Form.Item name="manager_name" label="Manager Name">
          <Input />
        </Form.Item>
        <Form.Item name="street_address" label="Street Address">
          <Input />
        </Form.Item>
        <Form.Item name="city" label="City">
          <Input />
        </Form.Item>
        <Form.Item name="state" label="State">
          <Input />
        </Form.Item>
        <Form.Item name="zip" label="ZIP">
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default PropertyModal;
