// Production Config
export const config = {
  REACT_APP_CLIENT_ID: 'db0865e1-9d41-4e5d-9452-df9e410b6d6f',
  REACT_APP_DOMAIN_NAME: 'liveluxb2c',
  REACT_APP_POLICY_NAME: 'B2C_1_signinpolicy',
  REACT_APP_REDIRECT_URL: "https://app.livelux.com",
  REACT_APP_LOGOUT_REDIRECT_URL: "https://app.livelux.com",
  REACT_APP_SERVER_URL: 'https://liveluxbackend.azurewebsites.net',
  REACT_APP_TENANT_ID: '674d8353-44d4-49cb-bc53-c8ac1e8b01fe',
  REACT_APP_GOOGLE_ANALYTICS_MEASUREMENT_ID: 'G-518Q4MGSTT'
 };

// Staging Config
// export const config = {
//   REACT_APP_CLIENT_ID: 'db0865e1-9d41-4e5d-9452-df9e410b6d6f',
//   REACT_APP_DOMAIN_NAME: 'liveluxb2c',
//   REACT_APP_LOGOUT_REDIRECT_URL:
//     'https://zealous-flower-0278b5e1e.4.azurestaticapps.net',
//   REACT_APP_POLICY_NAME: 'B2C_1_signinpolicy',
//   REACT_APP_REDIRECT_URL:
//     'https://zealous-flower-0278b5e1e.4.azurestaticapps.net',
//   REACT_APP_SERVER_URL: 'https://liveluxbackend.azurewebsites.net',
//   REACT_APP_TENANT_ID: '674d8353-44d4-49cb-bc53-c8ac1e8b01fe',
//   REACT_APP_GOOGLE_ANALYTICS_MEASUREMENT_ID: 'G-518Q4MGSTT'
// };

// Config for local development
// export const config = {
//   REACT_APP_CLIENT_ID: 'db0865e1-9d41-4e5d-9452-df9e410b6d6f',
//   REACT_APP_DOMAIN_NAME: 'liveluxb2c',
//   REACT_APP_LOGOUT_REDIRECT_URL: 'http://localhost:3000',
//   REACT_APP_POLICY_NAME: 'B2C_1_signinpolicy',
//   REACT_APP_REDIRECT_URL: 'http://localhost:3000',
//   REACT_APP_SERVER_URL: 'http://localhost:8080',
//   REACT_APP_TENANT_ID: '674d8353-44d4-49cb-bc53-c8ac1e8b01fe',
//   REACT_APP_GOOGLE_ANALYTICS_MEASUREMENT_ID: 'G-518Q4MGSTT'
// };
