import { getFormattedKAmount } from './format';
import { monthLabels } from './months';

const emptyERRCardData = () =>
  monthLabels.map((month, index) => {
    return {
      key: index + 1,
      month,
      budget: '-',
      forecast: '-'
    };
  });

const getActualForecastSum = (actual, forecast) => {
  const initialValue = 0;
  const sum = actual.reduce(
    (acc, curr, idx) => (curr !== 0 ? acc + curr : acc + forecast[idx]),
    initialValue
  );
  // console.log('getActualForecastSum => sum: ', sum);

  return sum;
};

export const parseERRCardData = (data) => {
  if (!data || !data.budget || !data.forecast) {
    return emptyERRCardData();
  }
  // console.log('parseERRCardData => data', data);

  const budgetSum = data.budget.reduce((acc, curr) => acc + curr, 0);
  const budgetTotal = getFormattedKAmount(budgetSum);

  const forecastSum = getActualForecastSum(data.actual, data.forecast);
  const forecastTotal = getFormattedKAmount(forecastSum);

  const tableData = monthLabels.map((monthLabel, idx) => {
    const budgetValue = data.budget[idx];
    const budgetDisplayValue = getFormattedKAmount(budgetValue);
    const forecastValue = data.forecast[idx];
    const forecastDisplayValue = getFormattedKAmount(forecastValue);

    return {
      key: idx + 1,
      month: monthLabel,
      budget: budgetDisplayValue,
      forecast: forecastDisplayValue
    };
  });

  // add total row
  const totalRowData = {
    key: 13,
    month: 'Total',
    budget: budgetTotal,
    forecast: forecastTotal
  };

  tableData.push(totalRowData);
  // console.log('tableData with total: ', tableData);

  return tableData;
};
