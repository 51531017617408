import { Form, Input, Modal } from 'antd'
import { useForm } from 'antd/es/form/Form'
import { useAppDispatch, useAppSelector } from '../../../store/hooks'
import { asyncAddClientName } from '../../../store/subscriptions/subscriptions-async'
import { setCommon } from '../../../store/common/common-slice'
import { asyncGetAllClients } from '../../../store/user/user-async'
import { setSubscription } from '../../../store/subscriptions/subscription-slice'

const ClientModal = ({ open, setOpen }: { open: boolean, setOpen: (value: boolean) => void }) => {
    const [form] = useForm()
    const { loading } = useAppSelector(s => s.common)
    const dispatch = useAppDispatch()
    const onCreate = async (values: any) => {
        try {
            dispatch(setCommon({ loading: true }))
            await dispatch(asyncAddClientName(values))
            const { result } = await dispatch(asyncGetAllClients()).unwrap()
            dispatch(setSubscription({ clients: result }))
            dispatch(setCommon({ loading: false }))
            form.resetFields()
            setOpen(false)
        }
        catch (err) {
            dispatch(setCommon({ loading: false }))
        }
    }
    return (
        <Modal
            open={open}
            destroyOnClose={true}
            title={"Create Client"}
            okText={"Create"}
            okButtonProps={{ disabled: loading }}
            cancelText="Cancel"
            cancelButtonProps={{ disabled: loading }}
            onCancel={() => {
                form.resetFields()
                setOpen(false);
            }}
            onOk={() => {
                form
                    .validateFields()
                    .then((values) => {
                        onCreate(values);
                    })
                    .catch((info) => { });
            }}
        >
            <Form
                form={form}
                layout="vertical"
                preserve={false}
            >
                <Form.Item
                    name="client_name"
                    label="Client Name"
                    rules={[
                        {
                            required: true,
                            message: "Please input the client name",
                        },
                    ]}
                >
                    <Input />
                </Form.Item>

            </Form>
        </Modal>

    )
}

export default ClientModal