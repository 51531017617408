import { ConfigProvider, Table } from 'antd';

import { parseERRCardData } from '../../utils/goals';

import styles from './errCard.module.scss';
import './errCard.css';

const columns = [
  {
    title: 'Month',
    dataIndex: 'month',
    key: 'month'
  },
  {
    title: 'Budgeted',
    dataIndex: 'budget',
    key: 'budget'
  },
  {
    title: 'Forecast',
    dataIndex: 'forecast',
    key: 'forecast'
  }
];

const ERRCard = ({ data }: any): ReactNode => {
  // console.log('ERRCard => data:', data);
  const parsedData = parseERRCardData(data);
  // console.log('ERRCard => parsedData:', parsedData);

  return (
    <ConfigProvider
      theme={{
        token: {
          fontFamily: 'Poppins'
        },
        components: {
          Table: {
            cellPaddingBlockSM: 6.95,
            headerColor: '#616b79'
          }
        }
      }}
    >
      <div id='errCard' className={styles.errCard}>
        <Table
          columns={columns}
          dataSource={parsedData}
          pagination={false}
          size='small'
        />
        <div className={styles.errCardFooter} />
      </div>
    </ConfigProvider>
  );
};

export default ERRCard;
