/* eslint-disable react-hooks/exhaustive-deps */
import { Col, Grid, Row, Select, Typography } from "antd";
import classes from "./styles.module.scss";
import { BarChart, Loader } from "../../../../components";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import { useEffect, useState } from "react";
import { asyncGetUserProperties } from "../../../../store/renewal/renewal-async";
import { getMovInFees } from "../../../../services/income.services";
import { sumOfArrays } from "../../../../utils/common";
import toast from "react-hot-toast";

interface ISummary {
  selectedProperty: string;
  setSelectedProperty: (val: string) => void;
  propertyOptions: Item[];
  activeLabel: string;
  setActiveLabel: (val: string) => void;
}

const { useBreakpoint } = Grid;
const MoveInFees = ({
  selectedProperty,
  setSelectedProperty,
  propertyOptions,
  activeLabel,
  setActiveLabel,
}: ISummary) => {
  const [chartData, setChartData] = useState({
    labels: [],
    adminFee: [],
    apartmentDeposit: [],
    petDeposit: [],
    applicationFee: [],
    sums: [],
    sumsLabels: [],
  });
  // const [propertyOptions, setPropertyOptions] = useState<Item[] | []>([]);
  // const [activeLabel, setActiveLabel] = useState<string>("");
  const [loader, setLoader] = useState<boolean>(true);
  const { lg } = useBreakpoint();

  const { token }: { [key: string]: any } = useAppSelector((s) => s.user);
  const barHeight = 90;
  const barHeightGroup = 60;
  const dispatch = useAppDispatch();

  // const getUserProperties = async () => {
  //   try {
  //     const userProperies = await dispatch(
  //       asyncGetUserProperties(`${user?.idTokenClaims?.oid}`)
  //     ).unwrap();
  //     setPropertyOptions(
  //       userProperies.data.map((obj: any) => ({
  //         label: obj.property_name,
  //         value: obj.ms_property_id,
  //       }))
  //     );

  //     setSelectedProperty(userProperies.data[0].ms_property_id);
  //     setActiveLabel(userProperies.data[0].property_name);
  //   } catch (error) {}
  // };

  const fetchMoveInFee = async (selectedPropertyId: string) => {
    setLoader(true);
    try {
      const res: any = await getMovInFees(
        {
          propertyId: selectedPropertyId ?? "",
        },
        token
      );
      setLoader(false);

      const data = res.data.map((item: any) => ({
        propertyName: item.property_name || "",
        adminFee: item.AdminFee || 0,
        apartmentDeposit: item.ApartmentDeposit || 0,
        petDeposit: item.PetDepositFee || 0,
        applicationFee: item.ApplicationFee || 0,
        totalFees: item.TotalFees || 0,
      }));

      data.sort(
        (a: { totalFees: number }, b: { totalFees: number }) =>
          b.totalFees - a.totalFees
      );

      const sortedLabels = data.map((item: any) => item.propertyName);
      const sortedAdminFee = data.map((item: any) => item.adminFee);
      const sortedApartmentDeposit = data.map(
        (item: any) => item.apartmentDeposit
      );
      const sortedPetDeposit = data.map((item: any) => item.petDeposit);
      const sortedApplicationFee = data.map((item: any) => item.applicationFee);
      const sortedSums = data.map((item: any) => item.totalFees);

      setChartData({
        labels: sortedLabels,
        adminFee: sortedAdminFee,
        apartmentDeposit: sortedApartmentDeposit,
        petDeposit: sortedPetDeposit,
        applicationFee: sortedApplicationFee,
        sums: sortedSums,
        sumsLabels: sortedLabels,
      });
    } catch (error) {
      setLoader(false);
      toast.error("Something went wrong during fetching data");
    }
  };

  // useEffect(() => {
  //   if (user) {
  //     getUserProperties();
  //   }
  // }, [user]);

  useEffect(() => {
    if (selectedProperty) {
      fetchMoveInFee(selectedProperty);
    }
  }, [selectedProperty]);

  if (loader) {
    return <Loader />;
  }

  console.log("selectedProperty", selectedProperty);

  return (
    <div style={{ maxWidth: "2000px" }}>
      {/* <Typography.Title level={2} style={{ margin: 0, marginBottom: 8 }}>
        Move-in Fees
      </Typography.Title> */}
      <Row gutter={[20, 20]}>
        <Col xs={24}>
          <div className={classes.container}>
            <div className={classes.flex}>
              <Typography.Text style={{ margin: 0 }}>Property</Typography.Text>
              <Select
                placeholder="Property"
                options={propertyOptions}
                value={selectedProperty}
                onChange={(val: string, option: any) => {
                  setActiveLabel(option.label);
                  setSelectedProperty(val);
                }}
                style={{ width: "200px" }}
              />
            </div>
          </div>
        </Col>
        <Col xs={24}>
          <div className={classes.container}>
            <BarChart
              data={chartData.sums}
              labels={chartData.sumsLabels}
              title="Total Move-In Fees"
              height={barHeight * chartData.sums.length}
              tooltip={false}
              activeLabel={activeLabel}
              YTitle="Property"
              XTitle="Amount"
              group={false}
            />
          </div>
        </Col>
        <Col xs={24}>
          <div className={classes.container}>
            <span className={classes.yLabel}>Property</span>
            <span className={classes.xLabel}>Amount</span>
            <Typography.Title level={5} style={{ margin: 0, marginBottom: 4 }}>
              Move-In Fees by Fee Type
            </Typography.Title>

            <div style={{ padding: "50px 0" }}>
              <div className={classes.chartRowContainer}>
                <div className={`${classes.chartColumn} `}>
                  <BarChart
                    data={chartData.adminFee}
                    labels={chartData.labels}
                    height={barHeightGroup * chartData.sums.length}
                    title="Admin Fee"
                    xAxisLabels={false}
                    tooltip={false}
                    activeLabel={activeLabel}
                    group={true}
                  />
                </div>
                <div className={`${classes.chartColumn} ${classes.borderLeft}`}>
                  <BarChart
                    data={chartData.apartmentDeposit}
                    labels={chartData.labels}
                    height={barHeightGroup * chartData.sums.length}
                    title="Apartment Deposit"
                    yAxisLabels={!lg}
                    xAxisLabels={false}
                    tooltip={false}
                    activeLabel={activeLabel}
                    group={true}
                  />
                </div>
              </div>
              <div className={classes.borderBottom} />
              <div className={classes.chartRowContainer}>
                <div className={`${classes.chartColumn} ${classes.lastRow}`}>
                  <BarChart
                    data={chartData.petDeposit}
                    labels={chartData.labels}
                    height={barHeightGroup * chartData.sums.length}
                    title="Pet Deposit"
                    tooltip={false}
                    activeLabel={activeLabel}
                    xAxisLabels={false}
                    group={true}
                  />
                </div>
                <div
                  className={`${classes.chartColumn} ${classes.borderLeft} ${classes.lastRow}`}
                >
                  <BarChart
                    data={chartData.applicationFee}
                    labels={chartData.labels}
                    height={barHeightGroup * chartData.sums.length}
                    yAxisLabels={!lg}
                    title="Application Fee"
                    tooltip={false}
                    activeLabel={activeLabel}
                    xAxisLabels={false}
                    group={true}
                  />
                </div>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default MoveInFees;
