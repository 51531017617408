/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Form, Input, Modal, Select } from "antd";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import {
  asyncGetAllClients,
  asyncGetClientRoles,
  asyncGetClientRolesProperties,
  asyncGetClientRolesProducts,
  asyncUpdateUser,
  createUser,
  asyncGetUserProperties,
  asyncGetAllProducts,
  asyncGetMyRoles,
  asyncGetUserProperty,
} from "../../../store/user/user-async";
import {
  asyncShowError,
  asyncShowSuccess,
  setCommon,
} from "../../../store/common/common-slice";
import { setUser } from "../../../store/user/user-slice";

const UserModal = ({
  open,
  setOpen,
  record,
  setRecord,
  callBackFunc,
}: IUserModal) => {
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const {
    common: { loading },
    user,
  } = useAppSelector((s) => ({ common: s.common, user: s.user as any }));
  const [clients, setClients] = useState<
    { client_id: number; client_name: string }[]
  >([]);
  const [clientRoles, setClientRoles] = useState<
    { role_id: number; role_name: string }[]
  >([]);
  const [clientRolesProd, setClientRolesProd] = useState<
    { product_id: number; product: string }[]
  >([]);
  const [clientProperties, setClientProperties] = useState<
    { ms_property_id: string; property_name: string }[]
  >([]);
  const [clientSelectedProperties, setClientSelectedProperties] = useState<
    string[]
  >([]);
  const [selectedProducts, setSelectedProducts] = useState<number[]>([]);
  const [prodError, setProdError] = useState(false);

  useEffect(() => {
    if (record) {
      (async function () {
        const [
          {
            value: { result: allClientsResult },
          },
          {
            value: { result: clientRoles },
          },
          { value: allProducts },
          {
            value: { result: properties },
          },
          {
            value: {
              payload: { result: currentClientSelectedProperties },
            },
          },
        ] = (await Promise.allSettled([
          dispatch(asyncGetAllClients()).unwrap(),
          dispatch(asyncGetClientRoles(record.clients.client_id)).unwrap(),
          dispatch(asyncGetAllProducts()).unwrap(),
          dispatch(
            asyncGetClientRolesProperties(record?.client_id?.toString())
          ).unwrap(),
          dispatch(
            asyncGetUserProperties({
              clientId: record?.client_id,
              userId: record?.user_id,
            })
          ),
        ])) as any;

        const filteredValues = currentClientSelectedProperties.map(
          ({ ms_property_id }: { ms_property_id: string }) =>
            `${ms_property_id}`
        );
        setClients(allClientsResult);
        setClientRoles(clientRoles);
        setClientRolesProd(allProducts);
        setClientProperties([
          { ms_property_id: "all", property_name: "Select All" },
          ...properties,
        ] as any[]);
        setSelectedProducts(filteredValues);

        form.setFieldsValue({
          properties: filteredValues,
          ...record,
          client: record.clients?.client_id,
          roles: record.roles.map((item: { role_id: number }) => item.role_id),
          products: record?.prodcutsSelected,
        });
      })();
    } else {
      form.resetFields();
      setClients([]);
      setClientRoles([]);
      setClientRolesProd([]);
      setSelectedProducts([]);
      setRecord(null);
      (async function () {
        const [{ result }, data] = await Promise.all([
          dispatch(asyncGetAllClients()).unwrap(),
          dispatch(asyncGetAllProducts()).unwrap(),
        ]);
        setClients(result);
        setClientRolesProd(data);
      })();
    }
  }, [record]);

  useEffect(() => {
    return () =>
      form.setFieldsValue({
        roles: [],
        properties: [],
        products: [],
        firstName: "",
        lastName: "",
        phone: "",
        email: "",
        client: "",
        password: "",
      });
  }, []);

  const onCreate = async (values: IUserDataType) => {
    try {
      const obj = {
        ...values,
        client: values?.client?.value ?? record?.client_id,
        ...(record && { userId: record.user_id, object_id: record.object_id }),
      };

      if (obj.password !== obj.confirmPassword) {
        dispatch(asyncShowError("Passwords do not match"));
        return;
      }

      delete obj.confirmPassword;

      dispatch(setCommon({ loading: true }));

      if (record) {
        await dispatch(asyncUpdateUser(obj));
        if (user?.user?.localAccountId === record?.object_id) {
          await Promise.all([
            dispatch(
              setUser({
                user: {
                  ...user?.user,
                  name: `${values?.first_name} ${values?.last_name}`,
                },
              })
            ),
            dispatch(asyncGetMyRoles(user?.token)),
            dispatch(asyncGetUserProperty()),
          ]);
        }
        dispatch(asyncShowSuccess("Successfully updated the user"));
      } else {
        await dispatch(createUser(obj));
        dispatch(asyncShowSuccess("Successfully created the user"));
      }
      form.resetFields();
      setSelectedProducts([]);
      dispatch(setCommon({ loading: false }));
      setOpen(false);
      callBackFunc();
    } catch (err: any) {
      console.log("update error");
      dispatch(asyncShowError(err.message));
      dispatch(setCommon({ loading: false }));
    }
  };

  const validatePassword = (
    _: any,
    value: string,
    callback: (value?: string) => void
  ) => {
    const passwordRegex =
      /^(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z]).{10,}$/;
    if (value && !passwordRegex.test(value)) {
      callback(
        "Password must have at least one uppercase letter, one lowercase letter, one number, one special character, and be 10 characters long"
      );
    } else {
      callback();
    }
  };

  const validatePhoneNumber = (
    _: any,
    value: string,
    callback: (value?: string) => void
  ) => {
    const phoneRegex = /^\+?\d{10,}$/;
    if (value && !phoneRegex.test(value)) {
      callback(
        "Phone number must contain at least 10 digits and may start with a '+' for the country code"
      );
    } else {
      callback();
    }
  };

  const handleClientChange = async (value: { value: number }) => {
    const [clientRolesResult, clientPropertiesResult]: any[] =
      await Promise.allSettled([
        dispatch(asyncGetClientRoles(value.value)).unwrap(),
        dispatch(
          asyncGetClientRolesProperties(value?.value?.toString())
        ).unwrap(),
      ]);
    form.setFieldsValue({ roles: [], properties: [] });
    setClientProperties([]);
    setSelectedProducts([]);
    setClientRoles([]);
    setClientRoles(clientRolesResult?.value?.result as any[]);
    setClientProperties([
      { ms_property_id: "all", property_name: "Select All" },
      ...clientPropertiesResult?.value?.result,
    ] as any[]);
  };

  const handleClientRoles = async (value: any) => {
    const id = JSON.stringify(value);
    if (value.length) {
      const { result } = await dispatch(
        asyncGetClientRolesProducts(id)
      ).unwrap();
      const selected = clientRolesProd.filter((record1) =>
        result.some((record2: any) => record1.product_id === record2.product_id)
      ) as any[];
      if (selected?.length) {
        const selectedItems = selected?.map((record) => record?.product_id);
        form.setFieldsValue({ products: selectedItems });
        setSelectedProducts(selectedItems);
      }
    } else {
      setSelectedProducts([]);
      // setClientRolesProd([])
    }
  };

  const handleClientPropeties = async (values: string[]) => {
    if (values.indexOf("all") > -1) {
      const filteredValues: string[] = [];
      clientProperties.forEach(({ ms_property_id }) => {
        if (ms_property_id !== "all") filteredValues.push(ms_property_id);
      });
      form.setFieldsValue({ properties: filteredValues });
      setClientSelectedProperties(filteredValues);
    } else {
      setClientSelectedProperties(values);
    }
  };

  const handleRolesProducts = (value: number[]) => {
    setSelectedProducts(value);
    if (!value.length) setProdError(true);
    else setProdError(false);
  };

  return (
    <Modal
      open={open}
      title={record ? "Edit User" : "Create User"}
      okText={record ? "Update" : "Create"}
      destroyOnClose
      okButtonProps={{ disabled: loading }}
      cancelText="Cancel"
      cancelButtonProps={{ disabled: loading }}
      onCancel={async () => {
        await form.resetFields();
        await setClients([]);
        await setClientRoles([]);
        await setClientRolesProd([]);
        await setSelectedProducts([]);
        await setRecord(null);
        setOpen(false);
      }}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            onCreate(values);
          })
          .catch((info: any) => {
            console.log("ERROR =====> ", info);
            // if (!selectedProducts.length) {
            //     setProdError(true)
            //     return
            // }
            // if (prodError) setProdError(false)
          });
      }}
    >
      <Form
        form={form}
        {...(record && {
          initialValues: {
            client: clients
              ?.map((item) => ({
                value: item?.client_id,
                label: item?.client_name,
              }))
              ?.find((elem) => elem.value === record?.clients?.client_id),
            roles: record?.roles.map(
              (item: { role_id: number }) => item.role_id
            ),
          },
        })}
        layout="vertical"
        name="form_in_modal"
        preserve={false}
      >
        <Form.Item
          name="first_name"
          label="First Name"
          rules={[
            {
              required: true,
              message: "Please input the first name",
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="last_name"
          label="Last Name"
          rules={[
            {
              required: true,
              message: "Please input the last name",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="email"
          label="Email"
          rules={[
            {
              type: "email",
              message: "The input is not valid email",
            },
            {
              required: true,
              message: "Please input your email",
            },
          ]}
        >
          <Input disabled={Boolean(record)} />
        </Form.Item>
        {!record && (
          <>
            <Form.Item
              name="password"
              label="Password"
              rules={[
                {
                  required: true,
                  message: "Please input the password",
                },
                { validator: validatePassword },
              ]}
            >
              <Input.Password type="password" />
            </Form.Item>
            <Form.Item
              name="confirmPassword"
              label="ConfirmPassword"
              rules={[
                {
                  required: true,
                  message: "Please input the password again",
                },
                { validator: validatePassword },
              ]}
            >
              <Input.Password type="password" />
            </Form.Item>
          </>
        )}
        <Form.Item
          name="phone"
          label="Phone"
          rules={[
            {
              required: true,
              message: "Please input the phone",
            },
            { validator: validatePhoneNumber },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="client"
          label="Clients"
          rules={[
            {
              required: true,
              message: "Please select the client",
            },
          ]}
        >
          <Select
            labelInValue
            style={{ width: "100%" }}
            onChange={handleClientChange}
            options={clients?.map((item) => ({
              value: item?.client_id,
              label: item?.client_name,
            }))}
          />
        </Form.Item>

        <Form.Item
          name="roles"
          label="Roles"
          rules={[
            {
              required: true,
              message: "Please select the roles",
            },
          ]}
        >
          <Select
            mode="multiple"
            allowClear
            style={{ width: "100%" }}
            disabled={form.getFieldValue("client") ? false : true}
            onChange={handleClientRoles}
            options={clientRoles.map((item) => ({
              value: item.role_id,
              label: item.role_name,
            }))}
          />
        </Form.Item>
        <Form.Item
          name="products"
          label="Products"
          rules={[
            {
              required: true,
              message: "Please select products",
            },
          ]}
        >
          <Select
            mode="multiple"
            allowClear
            style={{ width: "100%" }}
            value={selectedProducts}
            disabled={clientRolesProd?.length ? false : true}
            defaultValue={selectedProducts}
            onChange={handleRolesProducts}
          >
            {clientRolesProd.map((option) => (
              <Select.Option key={option.product_id} value={option.product_id}>
                {option.product}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          name="properties"
          label="Propeties"
          style={{ marginTop: 24 }}
          rules={[
            {
              required: true,
              message: "Please select the properties",
            },
          ]}
        >
          <Select
            mode="multiple"
            allowClear
            style={{ width: "100%" }}
            value={clientSelectedProperties}
            disabled={
              form.getFieldValue("client") && clientProperties.length > 1
                ? false
                : true
            }
            onChange={handleClientPropeties}
            options={clientProperties?.map((item) => ({
              value: item.ms_property_id,
              label: item.property_name,
            }))}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default UserModal;
