import { useCallback, useEffect, useState } from 'react';

import DisplayRow from './DisplayRow';

import { useAppSelector } from '../../store/hooks';
import { initialMonthKeyValuePairs } from '../../store/stats/stats-utils';
import { emptyMonthlyData } from '../../utils/data';
import { defaultFormatter } from '../../utils/format';
import { monthKeys } from '../../utils/months';

const ToursRow = ({ tourGoals = emptyMonthlyData }: any) => {
  const { tours }: any = useAppSelector((state) => state.goals.propertyData);

  const [data, setData] = useState(initialMonthKeyValuePairs);
  // console.log('ToursRow ==> tourGoals:', tourGoals);
  // console.log('ToursRow => propertyData.tours:', tours);
  // console.log('getDisplayData => data:', data);

  const getDisplayData = useCallback(() => {
    const displayData = monthKeys.reduce((acc: any, curr: any) => {
      const tourCt = tours[curr];
      const tourGoal = tourGoals[curr];
      if (tourCt) {
        acc[curr] = tourCt;
      } else if (tourGoal) {
        acc[curr] = tourGoal;
      } else {
        acc[curr] = 0;
      }
      return acc;
    }, {});

    setData(displayData);
  }, [tourGoals, tours]);

  useEffect(() => {
    if (!tours) {
      return;
    }
    getDisplayData();
  }, [getDisplayData, tours]);

  return (
    <DisplayRow
      evenOrOdd={'even'}
      label='Tours'
      data={data}
      formatter={defaultFormatter}
    />
  );
};

export default ToursRow;
