import { AxiosError, AxiosResponse } from 'axios';
import { httpRequest } from './request';

function getGPRDataService(
  propertyID: string,
  token: string
): Promise<AxiosResponse | AxiosError> {
  return new Promise((resolve, reject) => {
    httpRequest(`stats/gpr-data/${propertyID}`, 'get', token, null, false)
      .then((res: AxiosResponse) => {
        resolve(res);
      })
      .catch((err: AxiosError) => {
        reject(err);
      });
  });
}
function getGPRGraphDataService(
  propertyID: string,
  token: string
): Promise<AxiosResponse | AxiosError> {
  return new Promise((resolve, reject) => {
    httpRequest(`stats/gpr-graph/${propertyID}`, 'get', token, null, false)
      .then((res: AxiosResponse) => {
        resolve(res);
      })
      .catch((err: AxiosError) => {
        reject(err);
      });
  });
}

function getLostLeasesDataService(
  propertyID: string,
  token: string
): Promise<AxiosResponse | AxiosError> {
  return new Promise((resolve, reject) => {
    httpRequest(`stats/lost-leases/${propertyID}`, 'get', token, null, false)
      .then((res: AxiosResponse) => {
        resolve(res);
      })
      .catch((err: AxiosError) => {
        reject(err);
      });
  });
}

function getOccupancyDataService(
  propertyID: string,
  token: string
): Promise<AxiosResponse | AxiosError> {
  return new Promise((resolve, reject) => {
    httpRequest(`stats/occupancy/${propertyID}`, 'get', token, null, false)
      .then((res: AxiosResponse) => {
        resolve(res);
      })
      .catch((err: AxiosError) => {
        reject(err);
      });
  });
}

function getRenewalDataService(
  propertyID: string,
  token: string
): Promise<AxiosResponse | AxiosError> {
  return new Promise((resolve, reject) => {
    httpRequest(`stats/renewals/${propertyID}`, 'get', token, null, false)
      .then((res: AxiosResponse) => {
        resolve(res);
      })
      .catch((err: AxiosError) => {
        reject(err);
      });
  });
}

function getTourConversionDataService(
  propertyID: string,
  token: string
): Promise<AxiosResponse | AxiosError> {
  return new Promise((resolve, reject) => {
    httpRequest(
      `stats/tour-conversion/${propertyID}`,
      'get',
      token,
      null,
      false
    )
      .then((res: AxiosResponse) => {
        resolve(res);
      })
      .catch((err: AxiosError) => {
        reject(err);
      });
  });
}

function getTourDataService(
  propertyID: string,
  token: string
): Promise<AxiosResponse | AxiosError> {
  return new Promise((resolve, reject) => {
    httpRequest(`stats/tours/${propertyID}`, 'get', token, null, false)
      .then((res: AxiosResponse) => {
        resolve(res);
      })
      .catch((err: AxiosError) => {
        reject(err);
      });
  });
}

function getSubMarketChartData(
  { propertyId, bedrooms }: { propertyId: string; bedrooms: string },
  token: string
): Promise<AxiosResponse | AxiosError> {
  return new Promise((resolve, reject) => {
    httpRequest('stats/sub-market-chart', 'get', token, null, true, {
      propertyId,
      bedrooms
    })
      .then((res: AxiosResponse) => {
        resolve(res);
      })
      .catch((err: AxiosError) => {
        reject(err);
      });
  });
}

function getSubMarketMetricsData(
  { propertyId, bedrooms }: { propertyId: string; bedrooms: string },
  token: string
): Promise<AxiosResponse | AxiosError> {
  return new Promise((resolve, reject) => {
    httpRequest('stats/market-metrics', 'get', token, null, true, {
      propertyId,
      bedrooms
    })
      .then((res: AxiosResponse) => {
        resolve(res);
      })
      .catch((err: AxiosError) => {
        reject(err);
      });
  });
}

function getConcessionsTableData(
  { propertyId, bedrooms }: { propertyId: string; bedrooms: string },
  token: string
): Promise<AxiosResponse | AxiosError> {
  return new Promise((resolve, reject) => {
    httpRequest('stats/market-concessions', 'get', token, null, true, {
      propertyId,
      bedrooms
    })
      .then((res: AxiosResponse) => {
        resolve(res);
      })
      .catch((err: AxiosError) => {
        reject(err);
      });
  });
}

function getCompIntensityProfileData(
  { propertyId, bedrooms }: { propertyId: string; bedrooms: string },
  token: string
): Promise<AxiosResponse | AxiosError> {
  return new Promise((resolve, reject) => {
    httpRequest('stats/comp-intensity-profile', 'get', token, null, true, {
      propertyId,
      bedrooms
    })
      .then((res: AxiosResponse) => {
        resolve(res);
      })
      .catch((err: AxiosError) => {
        reject(err);
      });
  });
}
function getRecentLeaseData(
  { propertyId, bedrooms }: { propertyId: string; bedrooms: string },
  token: string
): Promise<AxiosResponse | AxiosError> {
  return new Promise((resolve, reject) => {
    httpRequest('stats/recent-leases', 'get', token, null, true, {
      propertyId,
      bedrooms
    })
      .then((res: AxiosResponse) => {
        resolve(res);
      })
      .catch((err: AxiosError) => {
        reject(err);
      });
  });
}

function getPropertyRooms(
  { propertyId }: { propertyId: string },
  token: string
): Promise<AxiosResponse | AxiosError> {
  return new Promise((resolve, reject) => {
    httpRequest('stats/bedrooms-by-property', 'get', token, null, true, {
      propertyId
    })
      .then((res: AxiosResponse) => {
        resolve(res);
      })
      .catch((err: AxiosError) => {
        reject(err);
      });
  });
}

function getGoalsDataService(
  token: string
): Promise<AxiosResponse | AxiosError> {
  return new Promise((resolve, reject) => {
    httpRequest(`stats/goals`, 'get', token, null, false)
      .then((res: AxiosResponse) => {
        resolve(res);
      })
      .catch((err: AxiosError) => {
        reject(err);
      });
  });
}

function getGoalsDisplayDataService(
  token: string
): Promise<AxiosResponse | AxiosError> {
  return new Promise((resolve, reject) => {
    httpRequest(`stats/goals/display`, 'get', token, null, false)
      .then((res: AxiosResponse) => {
        resolve(res);
      })
      .catch((err: AxiosError) => {
        reject(err);
      });
  });
}

function updateGoalsDataService(
  body: any,
  token: string
): Promise<AxiosResponse | AxiosError> {
  return new Promise((resolve, reject) => {
    httpRequest(`stats/goals`, 'put', token, body)
      .then((res: AxiosResponse) => {
        resolve(res);
      })
      .catch((err: AxiosError) => {
        reject(err);
      });
  });
}

// get3mosAveDataService
function get3mosAveDataService(
  propertyID: string,
  token: string
): Promise<AxiosResponse | AxiosError> {
  return new Promise((resolve, reject) => {
    httpRequest(`stats/3mos-ave/${propertyID}`, 'get', token, null, false)
      .then((res: AxiosResponse) => {
        resolve(res);
      })
      .catch((err: AxiosError) => {
        reject(err);
      });
  });
}

// getForecastDataService (ShortfallEndpoint)
function getForecastDataService(
  body: any,
  token: string
): Promise<AxiosResponse | AxiosError> {
  return new Promise((resolve, reject) => {
    httpRequest(`stats/forecast`, 'post', token, body)
      .then((res: AxiosResponse) => {
        resolve(res);
      })
      .catch((err: AxiosError) => {
        reject(err);
      });
  });
}

export {
  getGPRDataService,
  getGPRGraphDataService,
  getLostLeasesDataService,
  getOccupancyDataService,
  getRenewalDataService,
  getTourConversionDataService,
  getTourDataService,
  getSubMarketChartData,
  getSubMarketMetricsData,
  getConcessionsTableData,
  getCompIntensityProfileData,
  getRecentLeaseData,
  getPropertyRooms,
  getGoalsDataService,
  getGoalsDisplayDataService,
  updateGoalsDataService,
  get3mosAveDataService,
  getForecastDataService
};
