import { useCallback, useEffect, useState } from 'react';

import DisplayRow from './DisplayRow';

import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { updateApplicationsForecast } from '../../store/goals/goals-slice';
import { initialMonthKeyValuePairs } from '../../store/stats/stats-utils';
import { defaultFormatter } from '../../utils/format';
import { monthKeys } from '../../utils/months';

interface componentProps {
  tourGoals: object;
  tourConversionPctGoal: number;
}

const ApplicationsRow = ({
  tourGoals,
  tourConversionPctGoal
}: componentProps): ReactNode => {
  const dispatch = useAppDispatch();

  const { applications }: any = useAppSelector(
    (state) => state.goals.propertyData
  );
  const applicationsForecast: any = useAppSelector(
    (state) => state.goals.forecastData.applications
  );

  const [data, setData] = useState(initialMonthKeyValuePairs);

  // console.log('ApplicationsRow => propertyData.applications:', applications);
  // console.log('ApplicationsRow => applicationsForecast:', applicationsForecast);
  // console.log('getDisplayData => tourGoals:', tourGoals);
  // console.log(
  //   'Applications => getDisplayData => tourConversionPctGoal:',
  //   tourConversionPctGoal
  // );

  useEffect(() => {
    dispatch(updateApplicationsForecast({ tourGoals, tourConversionPctGoal }));
  }, [dispatch, tourGoals, tourConversionPctGoal]);

  const getDisplayData = useCallback(() => {
    const displayData = monthKeys.reduce((acc: any, curr: any) => {
      const forecastVal = applicationsForecast[curr];

      if (!applications) {
        acc[curr] = forecastVal;
        return acc;
      }

      const actual = applications[curr];
      if (actual) {
        acc[curr] = actual;
      } else if (forecastVal) {
        acc[curr] = forecastVal;
      } else {
        acc[curr] = 0;
      }
      return acc;
    }, {});

    setData(displayData);
  }, [applications, applicationsForecast]);

  useEffect(() => {
    getDisplayData();
  }, [getDisplayData, applications, applicationsForecast]);

  return (
    <DisplayRow
      evenOrOdd={'even'}
      label='Applications'
      data={data}
      formatter={defaultFormatter}
    />
  );
};

export default ApplicationsRow;
