import { useCallback, useEffect, useState } from 'react';

import DisplayRow from './DisplayRow';

import { useAppSelector } from '../../store/hooks';
import { initialMonthKeyValuePairs } from '../../store/stats/stats-utils';
import { getFormattedRoundedNearestK } from '../../utils/format';
import { monthKeys } from '../../utils/months';

const GPRRow = () => {
  const { gpr }: any = useAppSelector((state) => state.goals.propertyData);
  const gprForecast: any = useAppSelector(
    (state) => state.goals.forecastData.gpr
  );

  const [data, setData] = useState(initialMonthKeyValuePairs);
  // console.log('GPRRow => propertyData.gpr:', gpr);
  // console.log('getDisplayData => data:', data);

  const getDisplayData = useCallback(() => {
    const displayData = monthKeys.reduce((acc: any, curr: any) => {
      const forecastVal = gprForecast[curr];

      if (!gpr) {
        acc[curr] = forecastVal;
        return acc;
      }

      const actual = gpr[curr];
      if (actual) {
        acc[curr] = actual;
      } else if (forecastVal) {
        acc[curr] = forecastVal;
      } else {
        acc[curr] = 0;
      }
      return acc;
    }, {});

    // console.log('GPRRow => displayData:', displayData);

    setData(displayData);
  }, [gpr, gprForecast]);

  useEffect(() => {
    getDisplayData();
  }, [getDisplayData, gpr, gprForecast]);

  return (
    <DisplayRow
      evenOrOdd={'odd'}
      label='Gross Potential Rent'
      data={data}
      formatter={getFormattedRoundedNearestK}
    />
  );
};

export default GPRRow;
