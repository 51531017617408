import { initialMonthKeyValuePairs } from '../../store/stats/stats-utils';
import { monthKeys } from '../../utils/months';

import styles from './goalsForm.module.scss';

const getFontSizeAttr = (label: string) => {
  switch (label) {
    case 'Gross Potential Rent':
      return 'small';
    case 'Effective Rent Revenue':
      return 'smaller';
    default:
      return 'base';
  }
};

const DisplayRow = ({
  evenOrOdd,
  label,
  data = initialMonthKeyValuePairs,
  formatter = (value: any) => value
}: {
  evenOrOdd: string;
  label: string;
  data: any;
  formatter: (value: any) => any;
}) => {
  const labelFontSize = getFontSizeAttr(label);

  return (
    <div className={styles.row} data-row={evenOrOdd} data-type={'display'}>
      <div className={styles.field} data-field={'wide'}>
        <div className={styles.rowLabel} data-fontsize={labelFontSize}>
          {label}
        </div>
      </div>

      {monthKeys.map((month) => (
        <div className={styles.field} key={month}>
          <div className={styles.displayLabel}>{formatter(data[month])}</div>
        </div>
      ))}
    </div>
  );
};

export default DisplayRow;
