/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import { useAppDispatch } from "../../store/hooks";
import { setProperty } from "../../store/properties/property-slice";
import Renewal from "./renewal";

const RenewalPlus = (): JSX.Element => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    callBackFunc();
    return () => {
      dispatch(setProperty({ tabs: [], activeTab: 0 }));
    };
  }, []);

  const callBackFunc = async () => {
    dispatch(setProperty({ tabs: ["Renewal+"] }));
  };

  return <Renewal />;
};

export default RenewalPlus;
