import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    renewals: [],
    renewalUnsavedData: {},
    renewalUnsavedModal: false
};

export const renewal = createSlice({
    name: "Auth",
    initialState,
    reducers: {
        setRenewal: (state, { payload }) => ({ ...state, ...payload }),
    },
});

export const { setRenewal } = renewal.actions;
export default renewal.reducer;
