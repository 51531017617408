import { useCallback, useEffect, useMemo } from 'react';
import { Spin } from 'antd';

import ERRCard from './ERRCard';
import ForecastChart from './ForecastChart';

import { useAppDispatch, useAppSelector } from '../../store/hooks';

import {
  getGPRGraphData,
  getOccupancyStats
} from '../../store/stats/stats-slice';
import { getChartData } from '../../store/goals/goals-utils';

import styles from './forecastChart.module.scss';

interface componentProps {
  propertyID: string;
}

const Forecast = ({ propertyID }: componentProps): ReactNode => {
  const dispatch = useAppDispatch();

  const isForecastDataLoading = useAppSelector(
    (state) => state.goals.isForecastDataLoading
  );
  const isPrptyDataLoading = useAppSelector(
    (state) => state.goals.isPrptyDataLoading
  );

  const {
    effRentRev,
    errBudget,
    exposure,
    moveIns,
    moveOuts,
    occupancyCurr,
    occupancyPct
  }: any = useAppSelector((state) => state.goals.propertyData);
  const {
    effRentRev: errForecast,
    moveIns: moveInsForecast,
    moveOuts: moveOutsForecast
  }: any = useAppSelector((state) => state.goals.forecastData);

  const isLoading = isForecastDataLoading || isPrptyDataLoading;

  const chartData = useMemo(() => {
    const data = getChartData({
      effRentRev,
      errBudget,
      errForecast,
      exposure,
      moveIns,
      moveInsForecast,
      moveOuts,
      moveOutsForecast,
      occupancyCurr,
      occupancyPct
    });

    return data;
  }, [
    effRentRev,
    errBudget,
    errForecast,
    exposure,
    moveIns,
    moveInsForecast,
    moveOuts,
    moveOutsForecast,
    occupancyCurr,
    occupancyPct
  ]);
  // console.log('Forecast => chartData:', chartData);

  const fetchData = useCallback(async () => {
    try {
      if (!propertyID) {
        return;
      }

      await dispatch(getOccupancyStats(propertyID));
      await dispatch(getGPRGraphData(propertyID));
    } catch (error) {
      console.log('error:', error);
    }
  }, [dispatch, propertyID]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <>
      <div className={styles.forecastWrapper}>
        <div className={styles.forecastContainer}>
          <div className={styles.titleWrapper}>
            <span className={styles.title}>Forecast</span>
            {isLoading && <Spin />}
          </div>
          <ForecastChart data={chartData} />
        </div>

        <ERRCard data={chartData} />
      </div>
    </>
  );
};

export default Forecast;
