import { createSlice } from '@reduxjs/toolkit';

import {
  getGPRGraphData,
  getGPRStats,
  getLostLeasesStats,
  getOccupancyStats,
  getRenewalStats,
  getTourConversionStats,
  getTourStats,
  getGoalsData,
  getGoalsDisplayData,
  updateGoalsData,
  get3mosAveData,
  getForecastData,
  getForecastDataWith3mosAve
} from './stats-async';

import { getMoveInsForecast, getMoveOutsForecast } from './stats-utils';

interface dataItem {
  propertyID: string;
  data: object[] | null;
}

const initialDataArray: dataItem[] = [];

const initialState = {
  forecastData: {
    isLoading: false,
    data: {}
  },
  goals: {
    isLoading: false,
    isUpdating: false,
    data: initialDataArray
  },
  goalsDisplayData: {
    isLoading: false,
    data: initialDataArray
  },
  gpr: {
    isLoading: false,
    data: []
  },
  gprGraph: {
    isLoading: false,
    data: []
  },
  lostLeases: {
    isLoading: false,
    data: []
  },
  occupancy: {
    isLoading: false,
    data: initialDataArray
  },
  renewals: {
    isLoading: false,
    data: []
  },
  tourConversion: {
    isLoading: false,
    data: []
  },
  tours: {
    isLoading: false,
    data: []
  }
};

export const statsSlice = createSlice({
  name: 'stats',
  initialState,
  reducers: {
    updateMoveInsForecast: (state, action) => {
      const { payload } = action;
      const moveIns = getMoveInsForecast(payload);

      state.forecastData.data = {
        ...state.forecastData.data,
        moveIns
      };
    },
    updateMoveOutsForecast: (state, action) => {
      const { payload } = action;
      const moveOuts = getMoveOutsForecast(payload);
      state.forecastData.data = {
        ...state.forecastData.data,
        moveOuts
      };
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getGoalsData.pending, (state) => {
        state.goals.isLoading = true;
      })
      .addCase(getGoalsData.fulfilled, (state, action) => {
        const { payload } = action;
        state.goals.isLoading = false;
        state.goals.data = payload;
      })
      .addCase(getGoalsData.rejected, (state) => {
        state.goals.isLoading = false;
      })
      .addCase(getGoalsDisplayData.pending, (state) => {
        state.goalsDisplayData.isLoading = true;
      })
      .addCase(getGoalsDisplayData.fulfilled, (state, action) => {
        const { payload } = action;
        state.goalsDisplayData.isLoading = false;
        state.goalsDisplayData.data = payload;
      })
      .addCase(getGoalsDisplayData.rejected, (state) => {
        state.goalsDisplayData.isLoading = false;
      })
      .addCase(updateGoalsData.pending, (state) => {
        state.goals.isUpdating = true;
      })
      .addCase(updateGoalsData.fulfilled, (state) => {
        state.goals.isUpdating = false;
      })
      .addCase(updateGoalsData.rejected, (state) => {
        state.goals.isUpdating = false;
      })
      .addCase(getGPRStats.pending, (state) => {
        state.gpr.isLoading = true;
      })
      .addCase(getGPRStats.fulfilled, (state, action) => {
        const { payload } = action;
        state.gpr.isLoading = false;
        state.gpr.data = payload.gpr;
      })
      .addCase(getGPRStats.rejected, (state) => {
        state.gpr.isLoading = false;
      })
      .addCase(getGPRGraphData.pending, (state) => {
        state.gprGraph.isLoading = true;
      })
      .addCase(getGPRGraphData.fulfilled, (state, action) => {
        const { payload } = action;
        state.gprGraph.isLoading = false;
        state.gprGraph.data = payload;
      })
      .addCase(getGPRGraphData.rejected, (state) => {
        state.gprGraph.isLoading = false;
      })
      .addCase(getLostLeasesStats.pending, (state) => {
        state.lostLeases.isLoading = true;
      })
      .addCase(getLostLeasesStats.fulfilled, (state, action) => {
        const { payload } = action;
        state.lostLeases.isLoading = false;
        state.lostLeases.data = payload.lostLeases;
      })
      .addCase(getLostLeasesStats.rejected, (state) => {
        state.occupancy.isLoading = false;
      })
      .addCase(getOccupancyStats.pending, (state) => {
        state.occupancy.isLoading = true;
      })
      .addCase(getOccupancyStats.fulfilled, (state, action) => {
        const { payload } = action;
        state.occupancy.isLoading = false;
        state.occupancy.data = payload;
      })
      .addCase(getOccupancyStats.rejected, (state) => {
        state.occupancy.isLoading = false;
      })
      .addCase(getRenewalStats.pending, (state) => {
        state.renewals.isLoading = true;
      })
      .addCase(getRenewalStats.fulfilled, (state, action) => {
        const { payload } = action;
        state.renewals.isLoading = false;
        state.renewals.data = payload.renewals;
      })
      .addCase(getRenewalStats.rejected, (state) => {
        state.renewals.isLoading = false;
      })
      .addCase(getTourConversionStats.pending, (state) => {
        state.tourConversion.isLoading = true;
      })
      .addCase(getTourConversionStats.fulfilled, (state, action) => {
        const { payload } = action;
        state.tourConversion.isLoading = false;
        state.tourConversion.data = payload.tourConversion;
      })
      .addCase(getTourConversionStats.rejected, (state) => {
        state.tourConversion.isLoading = false;
      })
      .addCase(getTourStats.pending, (state) => {
        state.tours.isLoading = true;
      })
      .addCase(getTourStats.fulfilled, (state, action) => {
        const { payload } = action;
        state.tours.isLoading = false;
        state.tours.data = payload.tours;
      })
      .addCase(getTourStats.rejected, (state) => {
        state.tours.isLoading = false;
      })
      .addCase(getForecastData.pending, (state) => {
        state.forecastData.isLoading = true;
      })
      .addCase(getForecastData.fulfilled, (state, action) => {
        const { payload } = action;
        state.forecastData.isLoading = false;
        state.forecastData.data = {
          ...state.forecastData.data,
          ...payload
        };
      })
      .addCase(getForecastData.rejected, (state) => {
        state.forecastData.isLoading = false;
      });
  }
});

export const { updateMoveInsForecast, updateMoveOutsForecast } =
  statsSlice.actions;

export {
  getGoalsData,
  getGoalsDisplayData,
  updateGoalsData,
  getGPRGraphData,
  getGPRStats,
  getLostLeasesStats,
  getOccupancyStats,
  getRenewalStats,
  getTourConversionStats,
  getTourStats,
  get3mosAveData,
  getForecastData,
  getForecastDataWith3mosAve
};
export default statsSlice.reducer;
