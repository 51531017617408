export const transformPricingData = (data: IPricingData[] = []) => {
  const transformedData: any = {};

  const vacantUnits = data.filter((unit) => unit.DaysUntilAvailable <= 0);
  const ntvUnits = data.filter((unit) => unit.DaysUntilAvailable > 0);

  transformedData["Vacant Units"] = vacantUnits;
  transformedData["Notice-to-Vacate Units"] = ntvUnits;  

  return transformedData;

};  

export const extractOptions = (data: IPricingData[] = []) => {
  const uniqueArray: Item[] = [];
  if (Array.isArray(data) && data.length) {
    const array: any = data?.map((obj) => ({
      label: `${obj.Bedrooms}`,
      value: `${obj.Bedrooms}`,
    }));

    const map = new Map<string, Item>();
    for (const item of array) {
      if (!map.has(item.value)) {
        map.set(item.value, item);
        uniqueArray.push(item);
      }
    }
    uniqueArray.sort((a, b) => parseInt(a.value) - parseInt(b.value));
  }

  return uniqueArray;
};
