import { useCallback, useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';

import { Loader, Sidebar } from '../../components';

import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { asyncGetUserProducts } from '../../store/user/user-async';

import styles from './goals-layout.module.scss';

const GoalsLayout = (): ReactNode => {
  const dispatch = useAppDispatch();
  const { token } = useAppSelector((state) => state.user);

  const [loading, setLoading] = useState(true);

  const getProducts = useCallback(async (): Promise<void> => {
    const newToken = token;
    if (newToken) {
      try {
        await dispatch(asyncGetUserProducts(newToken)).unwrap();
        setLoading(false);
      } catch (err) {
        console.log(err);
        setLoading(false);
      }
    }
  }, [dispatch, token]);

  useEffect(() => {
    getProducts();
  }, [getProducts]);

  if (loading) {
    return <Loader />;
  }

  return (
    <div className={styles.container}>
      <Sidebar />
      <div className={styles.content}>
        <main className={styles.main}>
          <Outlet />
        </main>
      </div>
    </div>
  );
};

export default GoalsLayout;
