/* eslint-disable react-hooks/exhaustive-deps */
import { Form, Input, Modal } from "antd";
import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import { setCommon } from "../../../../store/common/common-slice";
import { asyncUpdateFeeses } from "../../../../store/renewal/renewal-async";

interface IMtmModal {
  open: boolean;
  setOpen: (value: boolean) => void;
  record: IObject | null;
  fetchData: () => void;
}

const MtmModal = ({ open, setOpen, record, fetchData }: IMtmModal) => {
  const [form] = Form.useForm();
  const { loading } = useAppSelector((s) => s.common);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (record) {
      form.setFieldsValue({ ...record });
    }
  }, [record]);

  const onCreate = async (values: IObject) => {
    try {
      dispatch(setCommon({ loading: true }));

      const obj = { ...values, pk_id: record!.pk_id, type: "mtm" };
      await dispatch(asyncUpdateFeeses(obj));
      await fetchData();
      dispatch(setCommon({ loading: false }));
      form.resetFields();
      setOpen(false);
    } catch (err: any) {
      console.log(err, " error");
      dispatch(setCommon({ loading: false }));
    }
  };

  return (
    <Modal
      open={open}
      okText={"Update"}
      // destroyOnClose
      okButtonProps={{ disabled: loading }}
      cancelText="Cancel"
      cancelButtonProps={{ disabled: loading }}
      onCancel={() => {
        // form.resetFields()
        setOpen(false);
      }}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            onCreate(values);
          })
          .catch((info) => console.log(info));
      }}
    >
      <Form
        form={form}
        layout="vertical"
        name="form_in_modal"
        preserve={false}
        disabled={loading}
      >
        <Form.Item
          name="mtm_media_package"
          label="MTM Media Package"
          rules={[
            {
              required: true,
              message: "Please input the MTM Media Package",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="mtm_pet_fee"
          label="MTM Pet Fee"
          rules={[
            {
              required: true,
              message: "Please input the MTM Pet Fee",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="mtm_rubs_fee"
          label="MTM Rubs Fee"
          rules={[
            {
              required: true,
              message: "Please input the MTM Rubs Fee",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="mtm_garage_income"
          label="MTM Garage Income"
          rules={[
            {
              required: true,
              message: "Please input the MTM Garage Income",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="mtm_income"
          label="MTM Income"
          rules={[
            {
              required: true,
              message: "Please input the MTM Income",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="mtm_other_fees"
          label="MTM Other Fees"
          rules={[
            {
              required: true,
              message: "Please input the MTM Other Fees",
            },
          ]}
        >
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default MtmModal;
