import React from "react";
import styles from "./home.module.scss";
import { useAppSelector } from "../../store/hooks";
import { useNavigate } from "react-router-dom";

function Home(): ReactNode {
  const { userProducts } = useAppSelector((state) => state.user);
  const navigate = useNavigate();
  return (
    <div className={styles.container}>
      <div className={styles.section}>
        {!!userProducts.length
          ? userProducts?.map(
              (item: { iconURL: string; product: string }, index:number) => (
                <div
                  className={styles.card}
                  key={index}
                  onClick={() => item?.product==="Renewal+"?navigate(`/renewal?type=standard`):item?.product==="OptiPricer"?navigate(`/property`):navigate(`/products/${item.product}`)}
                >
                  <img src={item.iconURL} alt="" />
                  <h3>{item.product}</h3>
                </div>
              )
            )
          : [1, 2, 3, 4, 5, 6].map((item, index) => (
              <div className={styles.card} key={index}></div>
            ))}
      </div>
    </div>
  );
}

export default Home;
