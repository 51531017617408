import { Tooltip } from 'antd';

import styles from './sidebar.module.scss';

interface componentProps {
  handleSidebarLinksClick: (param: string) => void;
  isActiveItem: boolean;
  iconImg: string;
  isSidebarOpen: boolean;
  label: string;
  target: string;
}

const MenuItem = ({
  handleSidebarLinksClick,
  isActiveItem,
  iconImg,
  isSidebarOpen,
  label,
  target
}: componentProps) => {
  return isSidebarOpen ? (
    <li
      onClick={() => handleSidebarLinksClick(target)}
      className={`${styles.menuItem} ${isActiveItem && styles.activeItem}`}
    >
      <img className={styles.menuItemImg} src={iconImg} alt='' />
      <p style={isSidebarOpen ? { display: 'block' } : { display: 'none' }}>
        {label}
      </p>
      <div className={styles.menuHover} />
    </li>
  ) : (
    <Tooltip title={label} placement='right'>
      <li
        onClick={() => handleSidebarLinksClick(target)}
        className={`${styles.menuItem} ${isActiveItem && styles.activeItem}`}
      >
        <img className={styles.menuItemImg} src={iconImg} alt='' />
        <p style={isSidebarOpen ? { display: 'block' } : { display: 'none' }}>
          {label}
        </p>
        <div className={styles.menuHover} />
      </li>
    </Tooltip>
  );
};

export default MenuItem;
