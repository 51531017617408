import { useCallback, useEffect } from 'react';
import { Button, Form, InputNumber, Slider } from 'antd';

import GoalsFormHeader from './GoalsFormHeader';

import { getFieldWithMonths } from '../../utils/months';

import { getGoalsInitialData } from '../../store/goals/goals-utils';
import { useAppSelector } from '../../store/hooks';

import styles from './goalsForm.module.scss';
import './goalsForm.css';

const numberInputWidth = '4rem';
const initialValues = getGoalsInitialData();
const goalsFormInitialValues = {
  concessions: initialValues.goals.concessions,
  netLeasesPct: initialValues.goals.netLeasesPct,
  tours: initialValues.goals.tours,
  tourConversionPct: initialValues.goals.tourConversionPct,
  renewalPct: initialValues.goals.renewalPct
};

const GoalsForm = ({
  formInstance,
  handleFormChange,
  isUpdate,
  onSubmit
}: any): ReactNode => {
  const { goals, isLoading, isUpdating }: any = useAppSelector(
    (state) => state.goals
  );
  // console.log('GoalsForm ==> goals: ', goals);
  // console.log('GoalsForm ==> goalsFormInitialValues: ', goalsFormInitialValues);
  const buttonLabel = isUpdate ? 'Update Goals' : 'Create Goals';

  const setFormValues = useCallback(
    (data: any) => {
      if (data) {
        const propGoals = {
          concessions: data.concessions
            ? data.concessions
            : initialValues.goals.concessions,
          netLeasesPct: data.net_leases_percentage?.jan
            ? data.net_leases_percentage.jan
            : initialValues.goals.netLeasesPct,
          renewalPct: data.ren_ntv_goal.jan,
          tours: data.tours,
          tourConversionPct: data.tour_conversion_percentage.jan
        };

        formInstance.setFieldsValue(propGoals);
      } else {
        formInstance.setFieldsValue(goalsFormInitialValues);
      }
    },
    [formInstance]
  );

  useEffect(() => {
    if (isLoading || !goals) {
      console.log('no data - returning....');
      return;
    }

    setFormValues(goals);
  }, [goals, isLoading, setFormValues]);

  const handleFormReset = () => {
    setFormValues(goals);
  };

  // handleFormSubmit
  const handleFormSubmit = (values: any) => {
    // console.log('handleFormSubmit:', values);

    const net_leases_percentage = getFieldWithMonths(values.netLeasesPct);
    const tour_conversion_percentage = getFieldWithMonths(
      values.tourConversionPct
    );
    const ren_ntv_goal = getFieldWithMonths(values.renewalPct);

    const data = {
      concessions: values.concessions,
      net_leases_percentage: net_leases_percentage,
      ren_ntv_goal: ren_ntv_goal,
      tours: values.tours,
      tour_conversion_percentage: tour_conversion_percentage
    };

    // console.log('handleFormSubmit/data: ', data);

    onSubmit(data);
  };

  return (
    <>
      <Form
        id='goals-form'
        name='goals-form'
        form={formInstance}
        onFieldsChange={handleFormChange}
        onFinish={handleFormSubmit}
        initialValues={goalsFormInitialValues}
        className={styles.goalsForm}
        labelAlign='right'
      >
        <GoalsFormHeader />

        <div className={styles.row} data-row={'odd'} data-type={'input'}>
          <div className={styles.field} data-field={'wide'}>
            <div className={styles.rowLabel} title='Number of Tours per month'>
              Tours
            </div>
          </div>
          <div className={styles.field}>
            <Form.Item name={['tours', 'jan']} className={styles.formItem}>
              <InputNumber
                style={{ width: numberInputWidth }}
                min={0}
                max={100}
              />
            </Form.Item>
          </div>
          <div className={styles.field}>
            <Form.Item name={['tours', 'feb']} className={styles.formItem}>
              <InputNumber
                style={{ width: numberInputWidth }}
                min={0}
                max={100}
              />
            </Form.Item>
          </div>
          <div className={styles.field}>
            <Form.Item name={['tours', 'mar']} className={styles.formItem}>
              <InputNumber
                style={{ width: numberInputWidth }}
                min={0}
                max={100}
              />
            </Form.Item>
          </div>
          <div className={styles.field}>
            <Form.Item name={['tours', 'apr']} className={styles.formItem}>
              <InputNumber
                style={{ width: numberInputWidth }}
                min={0}
                max={100}
              />
            </Form.Item>
          </div>
          <div className={styles.field}>
            <Form.Item name={['tours', 'may']} className={styles.formItem}>
              <InputNumber
                style={{ width: numberInputWidth }}
                min={0}
                max={100}
              />
            </Form.Item>
          </div>
          <div className={styles.field}>
            <Form.Item name={['tours', 'jun']} className={styles.formItem}>
              <InputNumber
                style={{ width: numberInputWidth }}
                min={0}
                max={100}
              />
            </Form.Item>
          </div>
          <div className={styles.field}>
            <Form.Item name={['tours', 'jul']} className={styles.formItem}>
              <InputNumber
                style={{ width: numberInputWidth }}
                min={0}
                max={100}
              />
            </Form.Item>
          </div>
          <div className={styles.field}>
            <Form.Item name={['tours', 'aug']} className={styles.formItem}>
              <InputNumber
                style={{ width: numberInputWidth }}
                min={0}
                max={100}
              />
            </Form.Item>
          </div>
          <div className={styles.field}>
            <Form.Item name={['tours', 'sep']} className={styles.formItem}>
              <InputNumber
                style={{ width: numberInputWidth }}
                min={0}
                max={100}
              />
            </Form.Item>
          </div>
          <div className={styles.field}>
            <Form.Item name={['tours', 'oct']} className={styles.formItem}>
              <InputNumber
                style={{ width: numberInputWidth }}
                min={0}
                max={100}
              />
            </Form.Item>
          </div>
          <div className={styles.field}>
            <Form.Item name={['tours', 'nov']} className={styles.formItem}>
              <InputNumber
                style={{ width: numberInputWidth }}
                min={0}
                max={100}
              />
            </Form.Item>
          </div>
          <div className={styles.field}>
            <Form.Item name={['tours', 'dec']} className={styles.formItem}>
              <InputNumber
                style={{ width: numberInputWidth }}
                min={0}
                max={100}
              />
            </Form.Item>
          </div>
        </div>

        <div className={styles.row} data-row={'even'} data-type={'input'}>
          <div className={styles.field} data-field={'wide'}>
            <div
              className={styles.rowLabel}
              title='Expected Average Weeks Free for New Leases'
              data-fontsize='smaller'
            >
              Concession Weeks Free
            </div>
          </div>
          <div className={styles.field}>
            <Form.Item
              name={['concessions', 'jan']}
              className={styles.formItem}
            >
              <InputNumber
                style={{ width: numberInputWidth }}
                min={0}
                max={100}
              />
            </Form.Item>
          </div>
          <div className={styles.field}>
            <Form.Item
              name={['concessions', 'feb']}
              className={styles.formItem}
            >
              <InputNumber
                style={{ width: numberInputWidth }}
                min={0}
                max={100}
              />
            </Form.Item>
          </div>
          <div className={styles.field}>
            <Form.Item
              name={['concessions', 'mar']}
              className={styles.formItem}
            >
              <InputNumber
                style={{ width: numberInputWidth }}
                min={0}
                max={100}
              />
            </Form.Item>
          </div>
          <div className={styles.field}>
            <Form.Item
              name={['concessions', 'apr']}
              className={styles.formItem}
            >
              <InputNumber
                style={{ width: numberInputWidth }}
                min={0}
                max={100}
              />
            </Form.Item>
          </div>
          <div className={styles.field}>
            <Form.Item
              name={['concessions', 'may']}
              className={styles.formItem}
            >
              <InputNumber
                style={{ width: numberInputWidth }}
                min={0}
                max={100}
              />
            </Form.Item>
          </div>
          <div className={styles.field}>
            <Form.Item
              name={['concessions', 'jun']}
              className={styles.formItem}
            >
              <InputNumber
                style={{ width: numberInputWidth }}
                min={0}
                max={100}
              />
            </Form.Item>
          </div>
          <div className={styles.field}>
            <Form.Item
              name={['concessions', 'jul']}
              className={styles.formItem}
            >
              <InputNumber
                style={{ width: numberInputWidth }}
                min={0}
                max={100}
              />
            </Form.Item>
          </div>
          <div className={styles.field}>
            <Form.Item
              name={['concessions', 'aug']}
              className={styles.formItem}
            >
              <InputNumber
                style={{ width: numberInputWidth }}
                min={0}
                max={100}
              />
            </Form.Item>
          </div>
          <div className={styles.field}>
            <Form.Item
              name={['concessions', 'sep']}
              className={styles.formItem}
            >
              <InputNumber
                style={{ width: numberInputWidth }}
                min={0}
                max={100}
              />
            </Form.Item>
          </div>
          <div className={styles.field}>
            <Form.Item
              name={['concessions', 'oct']}
              className={styles.formItem}
            >
              <InputNumber
                style={{ width: numberInputWidth }}
                min={0}
                max={100}
              />
            </Form.Item>
          </div>
          <div className={styles.field}>
            <Form.Item
              name={['concessions', 'nov']}
              className={styles.formItem}
            >
              <InputNumber
                style={{ width: numberInputWidth }}
                min={0}
                max={100}
              />
            </Form.Item>
          </div>
          <div className={styles.field}>
            <Form.Item
              name={['concessions', 'dec']}
              className={styles.formItem}
            >
              <InputNumber
                style={{ width: numberInputWidth }}
                min={0}
                max={100}
              />
            </Form.Item>
          </div>
        </div>

        <div
          id='tourConversionPct'
          className={styles.row}
          data-row={'odd'}
          data-type={'input'}
        >
          <div className={styles.field} data-field={'slider'}>
            <div className={styles.rowLabel} title='Tour Conversion Percentage'>
              Conversion %
            </div>
          </div>
          <div className={styles.sliderWrapper}>
            <Form.Item name='tourConversionPct' noStyle>
              <Slider min={0} max={100} />
            </Form.Item>
          </div>
          <Form.Item name='tourConversionPct' noStyle>
            <InputNumber min={0} max={100} formatter={(value) => `${value}%`} />
          </Form.Item>
        </div>

        <div
          id='renewalPct'
          className={styles.row}
          data-row={'even'}
          data-type={'input'}
        >
          <div className={styles.field} data-field={'slider'}>
            <div className={styles.rowLabel} title='Lease Renewal Percentage'>
              Renewal %
            </div>
          </div>
          <div className={styles.sliderWrapper}>
            <Form.Item name='renewalPct' noStyle>
              <Slider min={0} max={100} />
            </Form.Item>
          </div>
          <Form.Item name='renewalPct' noStyle>
            <InputNumber min={0} max={100} formatter={(value) => `${value}%`} />
          </Form.Item>
        </div>

        <div
          id='netLeasesPct'
          className={styles.row}
          data-row={'odd'}
          data-type={'input'}
        >
          <div className={styles.field} data-field={'slider'}>
            <div
              className={styles.rowLabel}
              title='Net Leases  Conversion Percentage'
            >
              Net Leases %
            </div>
          </div>
          <div className={styles.sliderWrapper}>
            <Form.Item name='netLeasesPct' noStyle>
              <Slider min={0} max={100} />
            </Form.Item>
          </div>
          <Form.Item name='netLeasesPct' noStyle>
            <InputNumber min={0} max={100} formatter={(value) => `${value}%`} />
          </Form.Item>
        </div>

        <div className={styles.row} data-row={'bottom'}>
          <Form.Item className={styles.btnWrapper}>
            <Button
              id='cancelBtn'
              type='default'
              htmlType='button'
              className={styles.cancelBtn}
              onClick={handleFormReset}
            >
              Cancel
            </Button>
            <Button
              id='submitBtn'
              type='primary'
              htmlType='submit'
              loading={isUpdating}
              className={styles.submitBtn}
              disabled={isLoading}
            >
              {buttonLabel}
            </Button>
          </Form.Item>
        </div>
      </Form>
    </>
  );
};

export default GoalsForm;
