import React from "react";
import { useParams } from "react-router-dom";
import IncomePlus from "./incomePlus";
import Prospect360 from "./prospect360";
import WebEdge from "./webEdge";
import Pricing from "../pricing";

export default function Products(): ReactNode {
  const params = useParams();

  const renderProduct = () => {
    switch (params.id) {
      case "Prospect360":
        return <Prospect360 />;
      case "WebEdge":
        return <WebEdge />;
      case "Income+":
        return <IncomePlus />;
      case "OptiPricer":
        return <Pricing />;

      default:
        return <div>{params.id ?? "No data"}</div>;
    }
  };

  return renderProduct();
}
