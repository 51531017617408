/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { setProperty } from "../../store/properties/property-slice";
import User from "../user";
import Subscription from "../subscriptions";
import { Table as Properties } from "../../components";
import { asyncGetAllUsers } from "../../store/user/user-async";
import {
  asyncShowError,
  asyncShowSuccess,
  setCommon,
} from "../../store/common/common-slice";

const Administration = () => {
  const dispatch = useAppDispatch();
  const [userData, setUserData] = useState([]);
  const [page, setPage] = useState<number | undefined | any>(1);
  const [pageSize, setPageSize] = useState<number | undefined | any>(10);
  const [totalCount, setTotalCount] = useState<number>(0);
  const {
    property: { activeTab },
  } = useAppSelector((s) => ({ property: s.property, user: s.user }));

  useEffect(() => {
    callBackFunc();
    return () => {
      dispatch(setProperty({ tabs: [], activeTab: 0 }));
    };
  }, [page]);

  // useEffect(() => {
  //   callBackFunc();
  // }, [page]);

  const callBackFunc = async () => {
    try {
      dispatch(setProperty({ tabs: ["User", "Properties", "Subscriptions"] }));
      dispatch(setCommon({ loading: true }));
      const data: any = await dispatch(
        asyncGetAllUsers({ page, pageSize })
      ).unwrap();
      dispatch(asyncShowSuccess("Successfully get the users"));
      dispatch(setCommon({ loading: false }));
      setUserData(data.result);
      setTotalCount(data?.result?.length ? data?.result[0]!.totalCount : 0);
    } catch (err: any) {
      dispatch(asyncShowError(err.message));
      dispatch(setCommon({ loading: false }));
    }
  };

  return (
    <>
      {activeTab === 0 && (
        <User
          userData={userData}
          callBackFunc={callBackFunc}
          setPage={setPage}
          setPageSize={setPageSize}
          totalCount={totalCount}
          page={page}
          pageSize={pageSize}
        />
      )}
      {activeTab === 1 && <Properties />}
      {activeTab === 2 && <Subscription />}
    </>
  );
};

export default Administration;
