import { Outlet } from "react-router-dom";
import { Sidebar } from "../../components";
import styles from "./dashboard-page.module.scss";

const DashboardPage = (): ReactNode => {
  return (
    <div className={styles.container}>
      <Sidebar />
      <div className={styles.content}>
        <main className={styles.main}>
          <Outlet />
        </main>
      </div>
    </div>
  );
};

export default DashboardPage;
