import { AxiosError, AxiosResponse } from "axios";
import { httpRequest } from "./request";

function PropertyApi(
  type: string,
  token: string,
  method: string,
  propertyId?: string,
  body?: string
): Promise<AxiosResponse | AxiosError> {
  return new Promise((resolve, reject) => {
    httpRequest(
      `properties${method} `,
      type,
      token,
      body
    )
      .then((res: AxiosResponse) => {
        resolve(res);
      })
      .catch((err: AxiosError) => {

        reject(err);
      });
  });
}
function getAllpropPropertiesService({ page = 1, pageSize = 30 }: { page: number | undefined, pageSize: number | undefined }, token: string): Promise<AxiosResponse | AxiosError> {
  return new Promise((resolve, reject) => {
    httpRequest(
      `properties/prop/properties/all`,
      "get",
      token,
      null,
      true,
      { page, pageSize }
    )
      .then((res: AxiosResponse) => {
        resolve(res);
      })
      .catch((err: AxiosError) => {
        reject(err);
      });
  });
}
function insertInPropPropertiesService(body: { [key: string]: number | string; }, token: string): Promise<AxiosResponse | AxiosError> {
  return new Promise((resolve, reject) => {
    httpRequest(
      `properties/prop/properties`,
      "post",
      token,
      body,
    )
      .then((res: AxiosResponse) => {
        resolve(res);
      })
      .catch((err: AxiosError) => {
        reject(err);
      });
  });
}
function updateInPropPropertiesService(body: { [key: string]: number | string; }, token: string): Promise<AxiosResponse | AxiosError> {
  return new Promise((resolve, reject) => {
    httpRequest(
      `properties/prop/properties`,
      "patch",
      token,
      body,
    )
      .then((res: AxiosResponse) => {
        resolve(res);
      })
      .catch((err: AxiosError) => {
        reject(err);
      });
  });
}
function deletePropPropertiesService(id: string, token: string): Promise<AxiosResponse | AxiosError> {
  return new Promise((resolve, reject) => {
    httpRequest(
      `properties/prop/properties/${id}`,
      "delete",
      token,
    )
      .then((res: AxiosResponse) => {
        resolve(res);
      })
      .catch((err: AxiosError) => {
        reject(err);
      });
  });
}
function getRefrenceCompetitorsService(id: string, token: string): Promise<AxiosResponse | AxiosError> {
  return new Promise((resolve, reject) => {
    httpRequest(
      `properties/ref/${id}`,
      "get",
      token,
    )
      .then((res: AxiosResponse) => {
        resolve(res);
      })
      .catch((err: AxiosError) => {
        reject(err);
      });
  });
}

function getRefrenceCompetitorsIdsService(keyword: string, token: string): Promise<AxiosResponse | AxiosError> {
  return new Promise((resolve, reject) => {
    httpRequest(
      `properties/ref/ids`,
      "get",
      token,
      null,
      true,
      { keyword }
    )
      .then((res: AxiosResponse) => {
        resolve(res);
      })
      .catch((err: AxiosError) => {
        reject(err);
      });
  });
}
function createOrUpdateIntermediaryService(obj: any, token: string): Promise<AxiosResponse | AxiosError> {
  return new Promise((resolve, reject) => {
    httpRequest(
      `properties/ref`,
      "post",
      token,
      obj
    )
      .then((res: AxiosResponse) => {
        resolve(res);
      })
      .catch((err: AxiosError) => {
        reject(err);
      });
  });
}
function deleteIntermediaryService(id: string, token: string): Promise<AxiosResponse | AxiosError> {
  return new Promise((resolve, reject) => {
    httpRequest(
      `properties/ref/${id}`,
      "delete",
      token,
    )
      .then((res: AxiosResponse) => {
        resolve(res);
      })
      .catch((err: AxiosError) => {
        reject(err);
      });
  });
}

export { PropertyApi, getAllpropPropertiesService, insertInPropPropertiesService, updateInPropPropertiesService, deletePropPropertiesService, getRefrenceCompetitorsService, createOrUpdateIntermediaryService, deleteIntermediaryService, getRefrenceCompetitorsIdsService };
