import { AxiosError, AxiosResponse } from 'axios';
import { httpRequest } from './request';

function getGoalsService(
  propertyID: string,
  token: string
): Promise<AxiosResponse | AxiosError> {
  return new Promise((resolve, reject) => {
    httpRequest(`goals/${propertyID}`, 'get', token, null, false)
      .then((res: AxiosResponse) => {
        resolve(res);
      })
      .catch((err: AxiosError) => {
        reject(err);
      });
  });
}

function createGoalsService(
  body: any,
  token: string
): Promise<AxiosResponse | AxiosError> {
  return new Promise((resolve, reject) => {
    httpRequest(`goals`, 'post', token, body)
      .then((res: AxiosResponse) => {
        resolve(res);
      })
      .catch((err: AxiosError) => {
        reject(err);
      });
  });
}

function updateGoalsService(
  body: any,
  token: string
): Promise<AxiosResponse | AxiosError> {
  return new Promise((resolve, reject) => {
    httpRequest(`goals`, 'put', token, body)
      .then((res: AxiosResponse) => {
        resolve(res);
      })
      .catch((err: AxiosError) => {
        reject(err);
      });
  });
}

function getGoalsDataService(
  propertyID: string,
  token: string
): Promise<AxiosResponse | AxiosError> {
  return new Promise((resolve, reject) => {
    httpRequest(`goals/data/${propertyID}`, 'get', token, null, false)
      .then((res: AxiosResponse) => {
        resolve(res);
      })
      .catch((err: AxiosError) => {
        reject(err);
      });
  });
}

function getPropertyDataService(
  propertyID: string,
  batch: string,
  token: string
): Promise<AxiosResponse | AxiosError> {
  return new Promise((resolve, reject) => {
    httpRequest(`goals/data/${propertyID}/${batch}`, 'get', token, null, false)
      .then((res: AxiosResponse) => {
        resolve(res);
      })
      .catch((err: AxiosError) => {
        reject(err);
      });
  });
}

function getForecastDataService(
  body: any,
  token: string
): Promise<AxiosResponse | AxiosError> {
  return new Promise((resolve, reject) => {
    httpRequest(`goals/data/forecast`, 'post', token, body)
      .then((res: AxiosResponse) => {
        resolve(res);
      })
      .catch((err: AxiosError) => {
        reject(err);
      });
  });
}

export {
  createGoalsService,
  getGoalsService,
  updateGoalsService,
  getGoalsDataService,
  getPropertyDataService,
  getForecastDataService
};
