import React from 'react';

import { getFormattedDollarAmount } from './format';
import styles from './stats.module.scss';

const GPRCard = ({ data }: any): ReactNode => {
  //   console.log('GPRCard/data:', data);
  const { actual, budget, variance } = data;

  const varianceColorClass =
    variance > 0 ? 'positiveVariance' : 'negativeVariance';

  return (
    <>
      <div className={styles.gprBudget}>
        <div className={styles.statItemWrapper}>
          <span className={styles.statItemName}>Budget</span>
          <span className={styles.statItemValue}>
            {getFormattedDollarAmount(budget)}
          </span>
        </div>
      </div>
      <div className={styles.gprActual}>
        <div className={styles.statItemWrapper}>
          <span className={styles.statItemName}>Actual</span>
          <span className={styles.statItemValue}>
            {getFormattedDollarAmount(actual)}
          </span>
        </div>
      </div>
      <div className={styles[varianceColorClass]}>
        <div className={styles.statItemWrapper}>
          <span className={styles.statItemName}>Variance</span>
          <span className={styles.statItemValue}>
            {getFormattedDollarAmount(variance)}
          </span>
        </div>
      </div>
    </>
  );
};

export default GPRCard;
