export const getFormattedDollarAmount = (amount) => {
  if (!amount || amount === 0) {
    return '$0';
  }

  if (amount > 1000000) {
    return `$${(amount / 1000000).toFixed(1)}M`;
  }

  if (amount < -1000000) {
    return `-$${(Math.abs(amount) / 1000000).toFixed(1)}M`;
  }

  if (amount > 1000) {
    return `$${(amount / 1000).toFixed(1)}K`;
  }

  if (amount < -1000) {
    return `-$${(Math.abs(amount) / 1000).toFixed(1)}K`;
  }

  if (amount < 0) {
    return `-$${Math.abs(amount)}`;
  }

  return `$${amount}`;
};
