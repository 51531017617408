import { useCallback, useEffect, useState } from 'react';

import DisplayRow from './DisplayRow';

import { useAppSelector } from '../../store/hooks';
import { initialMonthKeyValuePairs } from '../../store/stats/stats-utils';
import { getFormattedRoundedNearestK } from '../../utils/format';
import { monthKeys } from '../../utils/months';

const ConcessionLossRow = () => {
  const { concessionLoss }: any = useAppSelector(
    (state) => state.goals.propertyData
  );
  const concessionLossForecast: any = useAppSelector(
    (state) => state.goals.forecastData.concessionLoss
  );

  const [data, setData] = useState(initialMonthKeyValuePairs);
  // console.log(
  //   'ConcessionLossRow => propertyData.concessionLoss:',
  //   concessionLoss
  // );
  // console.log('getDisplayData => data:', data);

  const getDisplayData = useCallback(() => {
    const displayData = monthKeys.reduce((acc: any, curr: any) => {
      const forecastVal = concessionLossForecast[curr];

      if (!concessionLoss) {
        acc[curr] = forecastVal;
        return acc;
      }

      const actual = concessionLoss[curr];
      if (actual) {
        acc[curr] = actual;
      } else if (forecastVal) {
        acc[curr] = forecastVal;
      } else {
        acc[curr] = 0;
      }
      return acc;
    }, {});

    setData(displayData);
  }, [concessionLoss, concessionLossForecast]);

  useEffect(() => {
    getDisplayData();
  }, [getDisplayData, concessionLoss, concessionLossForecast]);

  return (
    <DisplayRow
      evenOrOdd={'odd'}
      label='Concession Loss'
      data={data}
      formatter={getFormattedRoundedNearestK}
    />
  );
};

export default ConcessionLossRow;
